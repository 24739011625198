<template>
  <div>
    <div style="margin-left: 10px; margin-right: 10px;">
      <!--
      <span v-if="componentname ==='Station'">
        <Button v-bind:class="getButtonListClass()" @click="changeLayout('list')">
          <i class="pi pi-list"></i>
        </Button>
        <Button v-bind:class="getButtonGridClass()" @click="changeLayout('grid')">
          <i class="pi pi-th-large"></i>
        </Button>
      </span>
      -->

      <span>
        <Button style="visibility: hidden">

        </Button>
      </span>

      <span style="float: right">
        <span style="margin-right: 5px;">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <!--  style="width: 700px ; border-radius: 5px"  v-bind:class="getSearchbarClass()" -->
            <InputText
              type="text"
              v-model="filter"
              placeholder="Suchen"
              style="width: 400px; border-radius: 5px;"
            />
          </span>
        </span>
        <Button label="Suchen" class="p-ml-2 button" />
        <Button class="p-ml-2 button" :label="componentname+' hinzufügen +'" @click="addStation()" />
      </span>
    </div>
  </div>
</template>

<script>
import ResizingMixins from "@/mixins/ResizingMixins";

export default {
  mixins: [ResizingMixins],
  name: "DataViewLayoutOptions",
  emits: ["update:modelValue"],
  data() {
    return {
      filter: ""
    };
  },
  props: {
    modelValue: String,
    componentname: {
      type: String,
      default: ""
    },
    layout: {
      type: String,
      default: ""
    },
    bigBar: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    filter(val) {
      this.$emit("filterEmit", val);
    }
  },
  computed: {},
  methods: {
    changeLayout(layout) {
      this.$emit("changeLayoutEmit", layout);
    },
    addStation() {
      this.$emit("addStationEmit", true);
    },
    getButtonListClass() {
      if (this.layout === "list") {
        return "list-layout-button list-layout-set";
      }
      if (this.layout === "grid") {
        return "list-layout-button list-layout-unset";
      }
    },
    getButtonGridClass() {
      if (this.layout === "grid") {
        return "grid-layout-button grid-layout-set";
      }
      if (this.layout === "list") {
        return "grid-layout-button grid-layout-unset";
      }
    },
    getSearchbarClass() {
      if (!this.bigBar && this.screenWidthComputed === "xl") {
        return "searchbar-xl";
      }

      if (this.bigBar && this.screenWidthComputed === "xl") {
        return "searchbar-xl-small";
      }

      if (!this.bigBar && this.screenWidthComputed === "xlg") {
        return "searchbar-xlg";
      }

      if (this.bigBar && this.screenWidthComputed === "xlg") {
        return "searchbar-xlg-small";
      }
    }
  }
};
</script>
<style scoped>
.button {
  background-color: #003349;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.grid-layout-button {
  border-radius: 0 5px 5px 0;
}

.list-layout-button {
  border-radius: 5px 0 0 5px;
}

.grid-layout-set {
  background-color: #003349;
  color: #ffffff;
}

.grid-layout-unset {
  background-color: #ffffff;
  color: #003349;
}

.list-layout-set {
  background-color: #003349;
  color: #ffffff;
}

.list-layout-unset {
  background-color: #ffffff;
  color: #003349;
}

.grid-layout-set:hover,
.grid-layout-unset:hover {
  fill: none;
}

.searchbar-xl {
  width: 1220px;
  border-radius: 5px;
}
.searchbar-xl-small {
  width: 1050px;
  border-radius: 5px;
}

.searchbar-xlg {
  width: 600px;
  border-radius: 5px;
}

.searchbar-xlg-small {
  width: 430px;
  border-radius: 5px;
}
</style>