<template>
  <div>
    <Button icon="pi pi-arrow-left" class="p-ml-4 p-mt-3" @click="actionBack()" />
    <div class="p-mt-4 p-mb-2 p-ml-4">
      <div>
        <span class="abagange">Abgänge </span>
        <span class="abagange-name">{{selectedStation.stationname}}</span>
        <div
          class="abgange-address"
        >{{selectedStation.stationaddress + ", "+selectedStation.stationlocation}}</div>
      </div>
    </div>

    <DataView
      class="p-mt-2"
      :value="deviceListComputed"
      layout="grid"
      :paginator="false"
      :rows="9"
      :sortOrder="null"
      :sortField="null"
      style="margin-left: 10px;margin-right: 10px;"
    >
      <template #header>
        <div class="grid grid-nogutter">
          <div class="col-6" style="text-align: left">
            <DataViewLayoutSmallDevicesOptions
              componentname="Abgang"
              @addStationEmit="addDeviceEmit"
              @changeLayoutEmit="changeLayoutEmit"
              @filterEmit="filterEmit"
            />
          </div>
        </div>
      </template>

      <template #grid="slotProps">
        <div @click="actionSelectDevice(slotProps.data)" style="background-color: #ffffff;">
          <div v-bind:class="getCardClass()">
            <div class="device-grid-item-top p-mb-2">
              <div class="device-name">
                {{slotProps.data.settingLabel}}
                <Button
                  icon="pi pi-ellipsis-v"
                  class="p-button-rounded p-button-text"
                  @click="toggleMenu($event, slotProps.data)"
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                  style="float:right"
                />
                <Menu id="overlay_menu" ref="menuDevice" :model="menuItems" :popup="true" />
              </div>
            </div>

            <div class="p-grid p-mr-2">
              <div class="p-col-1" style="text-align: right">P</div>
              <div class="p-col-10" style="text-align: right">{{slotProps.data.apparentpower}}</div>
              <div class="p-col-1">kVA</div>

              <div class="p-col-1" style="text-align: right">Q</div>
              <div class="p-col-10" style="text-align: right">{{slotProps.data.reactivepower}}</div>
              <div class="p-col-1">kW</div>

              <div class="p-col-1" style="text-align: right">S</div>
              <div class="p-col-10" style="text-align: right">{{slotProps.data.effectivepower}}</div>
              <div class="p-col-1">kVAr</div>
            </div>

            <div class="status">
              <div class="status-padding ok" v-if="slotProps.data.statusCode === 1">
                <div class="icon-centre">
                  <i class="pi pi-check"></i>
                </div>
              </div>

              <div class="status-padding offline" v-else-if="slotProps.data.statusCode === 0">
                <div class="icon-centre">
                  <i class="pi pi-exclamation-triangle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>

    <Dialog
      header="Gerät bearbeiten"
      :modal="true"
      :visible.sync="showUpdateConditionMonitor"
      :closable="false"
    >
      <SettingsComponent
        :projectnumber="selectedDevice.projectnumber"
        :ordinalnumber="selectedDevice.ordinalnumber"
        :settingLabel="selectedDevice.settingLabel"
        :settingLocation="selectedDevice.settingLocation"
        :settingStation="selectedDevice.settingStation"
        :settingAddress="selectedDevice.settingAddress"
        :showProjectOrdinalMessage="showProjectOrdinalMessage"
        :disableSettingStation="autofillDisable.station"
        :disableProjectNumber="true"
        :disableOrdinalNumber="true"
        :disableSettingLocation="autofillDisable.location"
        :disableSettingAddress="autofillDisable.address"
        @projectnumberEmit="projectNumberEmitUOp"
        @ordinalnumberEmit="ordinalNumberEmitUOp"
        @settingLabelEmit="settingLabelEmitUOp"
        @settingLocationEmit="settingLocationEmitUOp"
        @settingStationEmit="settingStationEmitUOp"
        @settingAddressEmit="settingAddressEmitUOp"
        @saveSettingEmit="updateDeviceEmit"
        @closeAddUpdateConditionMonitorEmit="closeUpdateConditionMonitorEmit"
      />
    </Dialog>

    <Dialog
      header="Gerät hinzufügen"
      :visible="showAddConditionMonitor"
      :modal="true"
      :closable="false"
      class="headerClass"
    >
      <SettingsComponent
        :showRemoveBtn="false"
        :projectnumber="newDevice.projectnumber"
        :ordinalnumber="newDevice.ordinalnumber"
        :settingLabel="newDevice.settingLabel"
        :settingLocation="newDevice.settingLocation"
        :settingStation="newDevice.settingStation"
        :settingAddress="newDevice.settingAddress"
        :showProjectOrdinalMessage="showProjectOrdinalMessage"
        :disableSettingStation="autofillDisable.station"
        :disableProjectNumber="autofillDisable.projectnumber"
        :disableSettingLocation="autofillDisable.location"
        :disableSettingAddress="autofillDisable.address"
        @projectnumberEmit="projectNumberEmitCOp"
        @ordinalnumberEmit="ordinalNumberEmitCOp"
        @settingLabelEmit="settingLabelEmitCOp"
        @settingLocationEmit="settingLocationEmitCOp"
        @settingStationEmit="settingStationEmitCOp"
        @settingAddressEmit="settingAddressEmitCOp"
        @saveSettingEmit="createDeviceEmit"
        @closeAddUpdateConditionMonitorEmit="closeAddConditionMonitorEmit"
      />
    </Dialog>

    <Dialog
      header="Löschen bestätigen"
      :visible="showConfirmDeleteDevice"
      :style="{width: '50vw'}"
      :closable="false"
    >
      <p>Sind Sie sicher, dass Sie diesen Abgang löschen wollen?</p>
      <p>Mit dieser Aktion wird der Abganag einschließlich der historischen Daten gelöscht.</p>
      <p>Bitte geben Sie das Wort "Löschen!" in das Textfeld ein, um zu bestätigen, dass Sie den Abgang und alle historischen Daten löschen wollen.</p>

      <InputText v-model="deleteConfirmationText" />
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          @click="showConfirmDeleteDevice = false"
          class="p-button-text"
        />
        <Button
          :disabled="deleteDisableComputed"
          label="Ja"
          icon="pi pi-check"
          @click="deleteEmit"
          autofocus
        />
      </template>
    </Dialog>

    <ExportDataComponent
      :showExportData="showExportData"
      :historyList="getHistoryExportList"
      :fileName="selectedDevice.projectnumber+'_'+selectedDevice.ordinalnumber"
      @showExportDataEmit="showExportDataEmit"
      @exportParameterEmit="exportParameterEmit"
      @cancelEmit="cancelExportEmit"
    />

    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import DeviceOperationsMixins from "../mixins/DeviceOperationsMixins";

import "../style/divicesmalldevices.css";
import ResizingMixins from "@/mixins/ResizingMixins";

import DataViewLayoutSmallDevicesOptions from "@/components/DataViewLayoutSmallDevicesOptions";

export default {
  mixins: [ResizingMixins, DeviceOperationsMixins],
  components: {
    DataViewLayoutSmallDevicesOptions
  },
  data() {
    return {};
  },
  props: {
    bigBar: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  computed: {
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    }
  },
  methods: {
    getCardClass() {
      if (this.bigBar) {
        return "small-card-small-device";
      } else {
        return "card-small-device";
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
</style>