const chartColor = {
    apparentpower: {
        color: "#30D5C8",
        tranparent: "rgba(48,213,200,0.1)"
    },
    reactivepower: {
        color: "#71C562",
        tranparent: "rgba(113,197,98,0.1)"
    },
    effectivepower: {
        color: "#0000FF",
        tranparent: "rgba(0,0,255,0.1)"
    },
    L1: {
        color: "#A52A2A",
        tranparent: "rgba(165,42,42,0.1)"
    },
    L2: {
        color: "#000000",
        tranparent: "rgba(0,0,0,0.1)"
    },
    L3: {
        color: "#8B8784",
        tranparent: "rgba(139,135,132,0.1)"
    },
    Ln: {
        color: "#0000FF",
        tranparent: "rgba(0,0,255,0.1)"
    }
};


export default {
    chartColor
}
