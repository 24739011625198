<template>
  <div>
    <div class="p-grid p-ai-center vertical-container p-ml-2 p-mb-2">
      <div>Grenzwertanzeige</div>
      <div class="p-ml-2">
        <InputSwitch v-model="monitoringModeComputed" />
      </div>
    </div>
    <div v-if="screenWidthComputed !== 'xl'">
      <TabView class="tabview-custom">
        <TabPanel >
          <template #header>
            <span>Scheinleistung (kVA)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                :edit="false"
                id="apparentPowerMaxLimit"
                type="number"
                min="1"
                v-model="local.apparentPowerMaxLimit"
              />
              <label for="apparentPowerMaxLimit">Oberer Grenzwert</label>
            </span>

            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"               
                id="apparentPowerTolerance"
                type="number"
                min="1"
                v-model="local.apparentPowerMaxTolerance"
              />

              <label for="apparentPowerTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
        <TabPanel >
          <template #header>
            <span>Blindleistung (kvar)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"                
                id="reactivePowerMaxLimit"
                type="number"
                min="1"
                v-model="local.reactivePowerMaxLimit"
              />

              <label for="reactivePowerMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"                
                id="reactivePowerMaxTolerance"
                type="number"
                min="1"
                v-model="local.reactivePowerMaxTolerance"
              />

              <label for="reactivePowerMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
        <TabPanel >
          <template #header>
            <span>Wirkleistung (kW)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"                
                id="effectivePowerMaxLimit"
                type="number"
                min="1"
                v-model="local.effectivePowerMaxLimit"
              />

              <label for="effectivePowerMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"               
                id="effectivePowerMaxTolerance"
                type="number"
                min="1"
                v-model="local.effectivePowerMaxTolerance"
              />
              <label for="effectivePowerMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
      </TabView>
    </div>

    <div class="p-grid" v-if="screenWidthComputed == 'xl'">
      <div class="p-col p-ml-3">
        <h3>Scheinleistung (kVA)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"            
            :edit="false"
            id="apparentPowerMaxLimit"
            type="number"
            min="1"
            v-model="local.apparentPowerMaxLimit"
          />
          <label for="apparentPowerMaxLimit">Oberer Grenzwert</label>
        </span>

        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"           
            id="apparentPowerTolerance"
            type="number"
            min="1"
            v-model="local.apparentPowerMaxTolerance"
          />

          <label for="apparentPowerTolerance">Schwellwert</label>
        </span>
      </div>
      <div class="p-col p-ml-3">
        <h3>Blindleistung (kvar)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"            
            id="reactivePowerMaxLimit"
            type="number"
            min="1"
            v-model="local.reactivePowerMaxLimit"
          />

          <label for="reactivePowerMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"            
            id="reactivePowerMaxTolerance"
            type="number"
            min="1"
            v-model="local.reactivePowerMaxTolerance"
          />

          <label for="reactivePowerMaxTolerance">Schwellwert</label>
        </span>
      </div>
      <div class="p-col p-ml-3">
        <h3>Wirkleistung (kW)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"            
            id="effectivePowerMaxLimit"
            type="number"
            min="1"
            v-model="local.effectivePowerMaxLimit"
          />

          <label for="effectivePowerMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"            
            id="effectivePowerMaxTolerance"
            type="number"
            min="1"
            v-model="local.effectivePowerMaxTolerance"
          />
          <label for="effectivePowerMaxTolerance">Schwellwert</label>
        </span>
      </div>
    </div>
    <div class="p-grid p-flex p-mt-1 p-ml-2 p-mr-2">
      <Button label="Anwenden" @click="actionApply()" style="width:100%" />
      <Button
        label="Abbrechen"
        @click="applyToLocal()"
        style="width:100%"
        class="p-button-outlined p-mt-1"
      />
    </div>
  </div>
</template>

<script>
import ResizingMixins from "../../mixins/ResizingMixins";
export default {
  mixins: [ResizingMixins],
  data: () => ({
    local: {
      apparentPowerMaxLimit: 0,
      apparentPowerMaxTolerance: 0,
      reactivePowerMaxLimit: 0,
      reactivePowerMaxTolerance: 0,
      effectivePowerMaxLimit: 0,
      effectivePowerMaxTolerance: 0
    }
  }),
  watch: {},
  props: {
    monitoringMode: {
      type: Boolean,
      default: true
    },
    apparentPowerMaxLimit: {
      type: Number,
      default: 0
    },
    apparentPowerMaxTolerance: {
      type: Number,
      default: 0
    },
    reactivePowerMaxLimit: {
      type: Number,
      default: 0
    },
    reactivePowerMaxTolerance: {
      type: Number,
      default: 0
    },
    effectivePowerMaxLimit: {
      type: Number,
      default: 0
    },
    effectivePowerMaxTolerance: {
      type: Number,
      default: 0
    }
  },
  computed: {
    monitoringModeComputed: {
      set(val) {
        this.$emit("monitoringModeEmit", val);
      },
      get() {
        return this.monitoringMode;
      }
    }
  },
  methods: {
    actionApply() {
      this.$emit("actionApplyPowerEmit", this.local);
    },
    applyToLocal() {
      this.local.apparentPowerMaxLimit = this.apparentPowerMaxLimit;
      this.local.apparentPowerMaxTolerance = this.apparentPowerMaxTolerance;
      this.local.reactivePowerMaxLimit = this.reactivePowerMaxLimit;
      this.local.reactivePowerMaxTolerance = this.reactivePowerMaxTolerance;
      this.local.effectivePowerMaxLimit = this.effectivePowerMaxLimit;
      this.local.effectivePowerMaxTolerance = this.effectivePowerMaxTolerance;
    }
  },
  mounted() {
    this.applyToLocal();
  }
};
</script>