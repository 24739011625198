import Vue from 'vue';
import Vuex from 'vuex';

import ConditionMonitorStore from "./ConditionMonitor/ConditionMonitorStore";
import WorkGroupStore from "./ConditionMonitor/WorkGroupStore";
import StationStore from "./ConditionMonitor/StationStore"

Vue.use(Vuex);

function dateTimeFormator2NoSecond(date) {
    let ddTemp = date.getDate();
    var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
    let mmTemp = date.getMonth() + 1;
    var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
    var yyyy = date.getFullYear();
    return yyyy + "-" + mm + "-" + dd + "T" + timeFormatorNoSecond(date);
}

function timeFormatorNoSecond(date) {
    // date.toLocaleTimeString()
    let hhTemp = date.getHours();
    var hh = hhTemp <= 9 ? "0" + hhTemp : hhTemp;
    let mmTemp = date.getMinutes();
    var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
    return hh + ":" + mm;
}

function browserChecker() {
    let userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        return "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        return "firefox";
    } else if (userAgent.match(/safari/i)) {
        return "safari";
    } else if (userAgent.match(/opr\//i)) {
        return "opera";
    } else if (userAgent.match(/edg/i)) {
        return "edge";
    } else {
        return "No browser detection";
    }
}


export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        data: [],
        dataWarning: [],
        dataTotalApparentPower: []
    },
    modules: {
        ConditionMonitorStore,
        WorkGroupStore,
        StationStore
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            // Check if Object or message status
            if (message.data[0] === "{") {
                let dataStream = JSON.parse(message.data)
                if (dataStream.type === "initialData") {
                    // console.log("initialData")
                    dataStream.value.forEach(val => {
                        val.ordinalnumber = parseInt(val.ordinalnumber)
                    })
                    state.data = dataStream.value

                    // console.log("====INITIAL DATA===")
                    // console.log(dataStream.value)
                } else if (dataStream.type === "data") {
                    let keyArray = dataStream.value.key.split(".")
                    let parentKey = keyArray[0]
                    let childKey = keyArray[1]

                    let conditionMonitorTemp = {
                        id: 1,
                        projectnumber: "",
                        ordinalnumber: "",
                        station_id: -1,
                        L1: {
                            current: "",
                            voltage: "",
                            direction: ""
                        },
                        L2: {
                            current: "",
                            voltage: "",
                            direction: ""
                        },
                        L3: {
                            current: "",
                            voltage: "",
                            direction: ""
                        },
                        Ln: {
                            current: ""
                        },
                        timestamp: new Date(),
                        devicestatus: "",
                        effectivepower: "",
                        reactivepower: "",
                        apparentpower: "",
                        totalapparentpower: 0,
                        totalpowerfactor: "",
                    }

                    dataStream.value.ordinalnumber = parseInt(dataStream.value.ordinalnumber)
                    let matchedIdx = -1;
                    let filteredCM = state.data.filter((conditionMonitor, idx) => {
                        if ((conditionMonitor.projectnumber === dataStream.value.projectnumber) && (conditionMonitor.ordinalnumber === dataStream.value.ordinalnumber)) {
                            matchedIdx = idx
                            return true
                        } else {
                            return false
                        }
                    })

                    if (filteredCM.length === 0) {
                        // INSERT NEW CONDITION MONITOR
                        conditionMonitorTemp.projectnumber = dataStream.value.projectnumber
                        conditionMonitorTemp.ordinalnumber = dataStream.value.ordinalnumber
                        conditionMonitorTemp.station_id = parseInt(dataStream.value.station_id)

                        if (keyArray.length > 1) {
                            let childData = {}
                            let childKey = keyArray[1]
                            childData[childKey] = dataStream.value.value
                            conditionMonitorTemp[parentKey] = {}
                            conditionMonitorTemp[parentKey] = childData
                        } else {
                            conditionMonitorTemp[parentKey] = dataStream.value.value
                        }
                        // CALCULATE TOTALAPPARENTPOWER INJECT IT TO NEW CM THEN PUSH IT TO ARRAY
                        let totalapparentpowerTemp = 0
                        state.data.forEach(cm => {
                            if (cm.projectnumber == dataStream.value.projectnumber)
                                totalapparentpowerTemp = parseFloat(cm.apparentpower) + parseFloat(totalapparentpowerTemp)
                        })
                        conditionMonitorTemp["totalapparentpower"] = totalapparentpowerTemp
                        state.data.push(conditionMonitorTemp)
                    }
                    else {
                        // UPDATING EXISTING CONDITION MONITOR
                        let matchedCM = filteredCM[0]
                        if (keyArray.length > 1) { //IF HAS CHILD LAYER
                            //UPDATE THE CHILD VALUE AND LIVE
                            matchedCM[parentKey][childKey] = dataStream.value.value
                            state.data[matchedIdx][parentKey] = matchedCM[parentKey]

                        } else { // IF IN PARENT LAYER
                            //UPDATE THE PARENT VALUE
                            state.data[matchedIdx][parentKey] = dataStream.value.value

                            // CALCULATE EXISTING TOTALAPPARENTPOWER
                            let totalapparentpowerTemp = 0
                            state.data.forEach(cm => {
                                totalapparentpowerTemp = parseFloat(cm.apparentpower) + parseFloat(totalapparentpowerTemp)
                                state.data.forEach(cm2 => {
                                    if (cm2.projectnumber == cm.projectnumber) {
                                        cm2["totalapparentpower"] = totalapparentpowerTemp.toFixed(2)
                                    }
                                })
                            })

                            if (parentKey === "devicestatus") {
                                if (dataStream.value.value === "ok") {
                                    state.data[matchedIdx]["devicestatus"] = "ok"
                                }
                                if (dataStream.value.value === "offline") {
                                    state.data[matchedIdx]["devicestatus"] = "offline"
                                }
                                if (dataStream.value.value === "unreachable") {
                                    state.data[matchedIdx]["devicestatus"] = "unreachable"
                                }
                            }
                        }
                    }

                    // ADDING DATA TO LIVE HISTORY
                    let liveSize = ConditionMonitorStore.state.conditionMonitorDataHistoriesLive.length;
                    let timeZoneOffset = new Date().getTimezoneOffset()
                    if (liveSize !== 0) {
                        let lastTimeStamp = ConditionMonitorStore.state.conditionMonitorDataHistoriesLive[liveSize - 1].timestamp
                        let lastProjectNumber = ConditionMonitorStore.state.conditionMonitorDataHistoriesLive[liveSize - 1].projectnumber
                        var lastDate = new Date(lastTimeStamp)

                        let dateTemp = ""
                        if (browserChecker() == "safari") {
                            dateTemp = dataStream.value.timestamp.slice(0, 10) + "T" + dataStream.value.timestamp.slice(11, 21)
                        } else {
                            dateTemp = dataStream.value.timestamp
                        }
                        var streamDate = new Date(Date.parse(dateTemp))
                        streamDate.setMinutes(streamDate.getMinutes() - timeZoneOffset)

                        if ((dateTimeFormator2NoSecond(lastDate) !== dateTimeFormator2NoSecond(streamDate)) && (dataStream.value.projectnumber == lastProjectNumber)) {
                            conditionMonitorTemp.timestamp = dateTimeFormator2NoSecond(streamDate)
                            conditionMonitorTemp.projectnumber = dataStream.value.projectnumber
                            conditionMonitorTemp.ordinalnumber = dataStream.value.ordinalnumber
                            conditionMonitorTemp.station_id = parseInt(dataStream.value.station_id)
                            if (keyArray.length > 1) {
                                conditionMonitorTemp[parentKey][childKey] = dataStream.value.value
                            } else
                                conditionMonitorTemp[parentKey] = dataStream.value.value
                            let historyLiveTemp = [...ConditionMonitorStore.state.conditionMonitorDataHistoriesLive]
                            historyLiveTemp.push(conditionMonitorTemp)
                            historyLiveTemp.shift()
                            ConditionMonitorStore.state.conditionMonitorDataHistoriesLive = historyLiveTemp
                            // ConditionMonitorStore.state.conditionMonitorDataHistoriesLive.push(conditionMonitorTemp)
                            // ConditionMonitorStore.state.conditionMonitorDataHistoriesLive.shift();
                        } else if (dateTimeFormator2NoSecond(lastDate) == dateTimeFormator2NoSecond(streamDate)) {
                            let matchedLiveHistoryIdx = -1

                            let filteredHistory = ConditionMonitorStore.state.conditionMonitorDataHistoriesLive.filter((conditionMonitor, idx) => {
                                if ((dateTimeFormator2NoSecond(new Date(conditionMonitor.timestamp)) === dateTimeFormator2NoSecond(streamDate))) {
                                    matchedLiveHistoryIdx = idx
                                    return true
                                }
                                else return false
                            })

                            let matchedHistory = filteredHistory[0]
                            if (dataStream.value.projectnumber == matchedHistory.projectnumber && dataStream.value.ordinalnumber == matchedHistory.ordinalnumber) {
                                if (keyArray.length > 1) {
                                    matchedHistory[parentKey][childKey] = dataStream.value.value
                                    ConditionMonitorStore.state.conditionMonitorDataHistoriesLive[matchedLiveHistoryIdx][parentKey] = matchedHistory[parentKey]
                                    ConditionMonitorStore.state.conditionMonitorDataHistoriesLive[matchedLiveHistoryIdx][parentKey]["direction"] = filteredCM[0][parentKey]["direction"]
                                } else {
                                    ConditionMonitorStore.state.conditionMonitorDataHistoriesLive[matchedLiveHistoryIdx][parentKey] = dataStream.value.value
                                }
                            }
                        }
                    }
                }
                else if (dataStream.type === "initialWarning") {
                    state.dataWarning = dataStream.value
                } else if (dataStream.type === "warning") {
                    let timeZoneOffset = new Date().getTimezoneOffset()
                    var dateStart = new Date(dataStream.timestampstart)
                    var dateEnd = new Date(dataStream.timestampstart)
                    dateStart.setMinutes(dateStart.getMinutes() - timeZoneOffset)
                    dateEnd.setMinutes(dateEnd.getMinutes() - timeZoneOffset)

                    dataStream.warning_id = dataStream.value.warning_id
                    dataStream.timestampstart = dateTimeFormator2NoSecond(dateStart)
                    dataStream.timestampend = dateTimeFormator2NoSecond(dateEnd)
                    dataStream.station_id = dataStream.value.station_id
                    dataStream.stationname = dataStream.value.stationname
                    dataStream.key = dataStream.value.key
                    dataStream.settingLabel = dataStream.value.settingLabel
                    dataStream.duration = dataStream.value.duration
                    dataStream.maximalvalue = dataStream.value.maximalvalue
                    dataStream.maxlimit = dataStream.value.maxlimit
                    dataStream.maxtolerancelimit = dataStream.value.maxtolerancelimit

                    state.dataWarning.push(dataStream)
                }
                else if (dataStream.type === "totalApparentPower") {
                    state.dataTotalApparentPower = dataStream.value;
                }
            }
        },


        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        }
    },
    getters: {
        dataStream: state => state.data,
        dataWarning: state => state.dataWarning,
        dataTotalApparentPower: state => state.dataTotalApparentPower
    }
})