<template>
  <div>
    <div v-show="menu.warning">
      <Warning @warningAmountEmit="warningAmountEmit" :reset="resetWarning" />
    </div>

    <div v-if="menu.station">
      <StationGrid
        v-if="layout ==='grid' && (screenWidthComputed === 'xlg' || screenWidthComputed === 'xl')"
        :bigBar="bigBar"
        @selectedStationEmit="selectedStationEmit"
        @menuEmit="menuEmit"
        @changeLayoutEmit="changeLayoutEmit"
      />
      <StationTable
        v-if="layout ==='list' && (screenWidthComputed === 'xlg' || screenWidthComputed === 'xl')"
        :bigBar="bigBar"
        @selectedStationEmit="selectedStationEmit"
        @menuEmit="menuEmit"
        @changeLayoutEmit="changeLayoutEmit"
      />

      <!-- small devices component-->
      <StationSmallDevices
        v-if="screenWidthComputed === 'lg' || screenWidthComputed === 'md' || screenWidthComputed === 'sm'"
        :bigBar="bigBar"
        @selectedStationEmit="selectedStationEmit"
        @menuEmit="menuEmit"
        @changeLayoutEmit="changeLayoutEmit"
      />
    </div>

    <div
      v-if="((menu.conditionmonitor)  && (screenWidthComputed === 'xlg' || screenWidthComputed === 'xl')) && renderComponent"
    >
      <ConditionMonitorGrid
        :selectedStation="selectedStation"
        :bigBar="bigBar"
        @menuEmit="menuEmit"
        @selectedDeviceEmit="selectedDeviceEmit"
        @askRerenderEmit="askRerenderEmit"
      />
    </div>

    <div
      v-if="((menu.conditionmonitor)  && (screenWidthComputed === 'lg' || screenWidthComputed === 'md' || screenWidthComputed === 'sm' )) && renderComponent"
    >
      <ConditionMonitorSmallDevice
        :selectedStation="selectedStation"
        :selectedDevice="selectedDeviceComputed"
        :bigBar="bigBar"
        @menuEmit="menuEmit"
        @selectedDeviceEmit="selectedDeviceEmit"
        @askRerenderEmit="askRerenderEmit"
      />
    </div>

    <div v-if="menu.conditionmonitordetail">
      <ConditionMonitorDetail
        :selectedDevice="selectedDeviceComputed"
        :bigBar="bigBar"
        :selectedStation="selectedStation"
        :askRedraw="askRedraw"
        @askRedrawEmit="askRedrawEmit"
        @selectedDeviceEmit="selectedDeviceEmit"
        @menuEmit="menuEmit"
      />
    </div>

    <div v-if="menu.group">
      <EmailGroup />
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex"; //MANDATORY TO IMPORT TO USE VUEX

import StationGrid from "../components/StationGridComponent";
import StationTable from "../components/StationTableComponent";
import StationSmallDevices from "../components/StationSmallDevicesComponent";
import ConditionMonitorGrid from "../components/ConditionMonitorGridComponent";
import ConditionMonitorDetail from "../components/ConditionMonitorDetails";
import ResizingMixins from "../mixins/ResizingMixins";
import EmailGroup from "../components/EmailGroupComponent";
import Warning from "../components/WarningsComponent";
import MenuMixins from "../mixins/MenuMixins";
import ConditionMonitorSmallDevice from "@/components/ConditionMonitorSmallDeviceComponent";

export default {
  mixins: [ResizingMixins, MenuMixins],
  components: {
    Warning,
    StationGrid,
    StationTable,
    ConditionMonitorGrid,
    ConditionMonitorDetail,
    StationSmallDevices,
    ConditionMonitorSmallDevice,
    EmailGroup
  },
  data() {
    return {
      renderComponent: true,
      resetWarning: false,
      selectedStation: {},
      selectedDevice: {},
      editingRows: [],
      filters: {},
      tableLoading: false,
      showSearchBar: false,
      layout: "grid",
      badge: {
        warning: "",
        station: "",
        device: ""
      },
      expandedRowsParent: [],
      prevExpandedRowsParent: [],
      expandedRows: [],
      interval: null
    };
  },
  watch: {
    screenWidthComputed(val) {
      if (val == "xl" || val == "xlg") this.landscape = true;
      else if (val == "lg" || val == "sm") this.landscape = false;
    },
    menu: {
      deep: true,
      handler() {
        new Promise(resolve => {
          this.resetWarning = true;
          resolve(false);
        }).then(data => {
          this.resetWarning = data;
        });
      }
    },
    expandedRows() {
      if (this.expandedRows.length > 1) this.expandedRows.shift();
    },
    expandedRowsParent(val) {
      if (val.length > 0) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(() => {
          this.onParentRowCollapse();
        }, 1000);
      }
      if (this.expandedRowsParent.length > 1) this.expandedRowsParent.shift();
    }
  },
  props: {
    bigBar: {
      type: Boolean,
      default: false
    },
    askRedraw: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    },
    editModeComputed() {
      return (
        this.selectedConditionMonitor.projectnumber !== "" &&
        this.selectedConditionMonitor.ordinalnumber !== ""
      );
    },
    editStationModeComputed() {
      return (
        this.selectedConditionMonitor.projectnumber !== "" &&
        this.selectedConditionMonitor.ordinalnumber !== ""
      );
    },
    //MAP GETTERS ALSO MANDATORY TO RETRIEVE THE DATA
    ...mapGetters("ConditionMonitorStore", [
      "getConditionMonitorSettingList",
      "getStationList",
      "getConditionMonitorDataHistories",
      "getHistoryExportList"
    ]),
    selectedDeviceComputed() {
      let dataStream = this.$store.getters.dataStream;

      // console.log(dataStream);
      let data = dataStream.filter(deviceStream => {
        return (
          this.selectedDevice.projectnumber == deviceStream.projectnumber &&
          this.selectedDevice.ordinalnumber == deviceStream.ordinalnumber
        );
      });

      return { ...this.selectedDevice, ...data[0] };
    }
  },

  methods: {
    //MANDATORY TO IMPORT TO DO OPERATION IN VUEX
    ...mapActions("ConditionMonitorStore", [
      "GET_UNACKNOWLEDGE_WARNING",
      "GET_CONDITION_MONITOR_INIT_DATA"
    ]),
    askRedrawEmit(val) {
      this.$emit("askRedrawEmit", val);
    },
    askRerenderEmit() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    menuEmit(val) {
      this.$emit("menuEmit", val);
    },
    warningAmountEmit(val) {
      this.badge.warning = val.toString();
      this.$emit("badgeEmit", this.badge);
    },
    onParentRowCollapse() {
      this.$store.state.data = [...this.$store.state.data];
    },
    editStation(data) {
      this.selectedStation = { ...data };
      this.showUpdateStation = true;
    },
    ///////////////
    filtersEmit(val) {
      this.filters = val;
    },
    directionIconGenerator(val) {
      if (val === "B") {
        return "pi pi-arrow-left";
      } else if (val === "A") {
        return "pi pi-arrow-right";
      }
    },
    actionRowSelected() {
      if (
        this.selectedConditionMonitor.ordinalnumber !== "" &&
        this.selectedConditionMonitor.projectnumber !== ""
      ) {
        this.emptySelectedConditionMonitor();
      }
    },
    dotStyleGenerator(data) {
      if (data.devicestatus === "ok")
        return "height: 25px;width: 25px;margin-top: 5px;background-color: green; border-radius: 50%;display: inline-block;padding: 10px;";
      else if (data.devicestatus === "unreachable")
        return "height: 25px;width: 25px;margin-top: 5px;background-color: red; border-radius: 50%;display: inline-block;padding: 10px;";
      else if (
        data.devicestatus === undefined ||
        data.devicestatus === "offline" ||
        data.devicestatus === ""
      )
        return "height: 25px;width: 25px;margin-top: 5px;background-color: grey; border-radius: 50%;display: inline-block;padding: 10px;";
    },
    stationDotStyleGenerator(data) {
      if (data.devicestatus === "ok")
        return "height: 25px;width: 25px;margin-top: 5px;background-color: green; border-radius: 50%;display: inline-block;padding: 10px;";
      else if (data.devicestatus === "unreachable")
        return "height: 25px;width: 25px;margin-top: 5px;background-color: grey; border-radius: 50%;display: inline-block;padding: 10px;";
      else if (data.devicestatus === "one-ok")
        return "height: 25px;width: 25px;margin-top: 5px;background-color: orange; border-radius: 50%;display: inline-block;padding: 10px;";
      else if (
        data.devicestatus === undefined ||
        data.devicestatus === "offline" ||
        data.devicestatus === ""
      )
        return "height: 25px;width: 25px;margin-top: 5px;background-color: orange; border-radius: 50%;display: inline-block;padding: 10px;";
    },
    filteredStreamConditionMonitorList(conditionMonitorSetting) {
      let filteredProjectNumberList = this.streamDataComputed.filter(
        conditionMonitor =>
          conditionMonitor.projectnumber ===
          conditionMonitorSetting.projectnumber
      );
      let filteredOrdinalNumber = filteredProjectNumberList.filter(
        filteredProjectNumber =>
          filteredProjectNumber.ordinalnumber ===
          conditionMonitorSetting.ordinalnumber
      );
      if (filteredOrdinalNumber[0] === undefined) filteredOrdinalNumber[0] = {};
      Object.keys(conditionMonitorSetting).forEach(keySetting => {
        if (keySetting !== "devicestatus")
          filteredOrdinalNumber[0][keySetting] =
            conditionMonitorSetting[keySetting];
      });

      return filteredOrdinalNumber[0];
    },

    closeBasic() {
      this.displayBasic = false;
    },
    selectedStationEmit(val) {
      this.selectedStation = val;
    },
    selectedDeviceEmit(val) {
      this.selectedDevice = val;
    },
    changeLayoutEmit(val) {
      this.layout = val;
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.onParentRowCollapse();
    }, 1000);

    this.tableLoading = true;
    this.GET_UNACKNOWLEDGE_WARNING();
  }
};
</script>







