<template>
  <div class="p-dataview-layout-options p-selectbutton p-buttonset" >

    <span class="p-input-icon-left" style="margin-left: 10px;" v-if="bigBarComputed">
      <i class="pi pi-search" />
      <InputText type="text" v-model="value1" placeholder="Suchen" style="width: 300px; border-radius: 5px;"/>
    </span>
    <span class="p-input-icon-left" style="margin-left: 10px;" v-else>
      <i class="pi pi-search" />
      <InputText type="text" v-model="value1" placeholder="Suchen" style="width: 460px; border-radius: 5px;"/>
    </span>

    <div style="float: right">
      <button class="p-ml-2 buttons" type="button">Suchen</button>

      <button class="p-ml-2 buttons" type="button" @click="addStation()">{{componentname}} hinzufügen +</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataViewLayoutSmallDevicesOptions",
  emits: ["update:modelValue"],
  data() {
    return {
      value1: ""
    };
  },
  props: {
    modelValue: String,
    componentname: {
      type: String,
      default: ""
    },
    bigBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonListClass() {
      return [
        "p-button p-button-icon-only",
        { "p-highlight": this.modelValue === "list" }
      ];
    },
    buttonGridClass() {
      return [
        "p-button p-button-icon-only",
        { "p-highlight": this.modelValue === "grid" }
      ];
    },
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    }
  },
  methods: {
    changeLayout(layout) {
      this.$emit("changeLayoutEmit", layout);
    },
    addStation() {
      this.$emit("addStationEmit", true);
    }
  }
};
</script>
<style>
.buttons {
  font-family: Barlow;
  font-style: normal;
  background-color: #003349;
  color: #ffffff;
  padding: 5px;
  margin: 5px;
}
</style>