<template>
  <div>
    <div class="baseArrows p-col-1">
      <div
        v-if="direction === 'B'"
        class="arrow-left"
        :style="direction ==='B' ? arrowLeftStyle[0] : arrowLeftStyle[1]"
      >
        <span>B</span>
      </div>
      <div
        v-if="direction === 'A'"
        class="arrow-right p-ml-2"
        :style="direction ==='A' ? arrowRightStyle[0] : arrowRightStyle[1]"
      >
        <span>A</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arrowLeftStyle: [
        "display: inline-block; margin-top: 5px; margin-bottom: 45px; width: 0; height: 0; border-top: 20px solid transparent; border-bottom: 20px solid transparent; border-right: 40px solid rgb(43, 136, 0)",
        "display: inline-block; margin-top: 5px; margin-bottom: 45px; width: 0; height: 0; border-top: 20px solid transparent; border-bottom: 20px solid transparent; border-right: 40px solid grey"
      ],
      arrowRightStyle: [
        "display: inline-block; margin-top: 5px; margin-bottom: 45px; width: 0; height: 0; border-top: 20px solid transparent; border-bottom: 20px solid transparent; border-left: 40px solid rgb(43, 136, 0);",
        "display: inline-block; margin-top: 5px; margin-bottom: 45px; width: 0; height: 0; border-top: 20px solid transparent; border-bottom: 20px solid transparent; border-left: 40px solid grey;"
      ]
    };
  },
  props: {
    direction: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.baseArrows {
  width: 50px;
  height: 50px;
  padding: 0;
  position: relative;
}

.arrow-left span {
  position: absolute;
  margin-left: 15px;
  top: 12px;
  width: 0;
  padding-left: 10px;
  padding-top: 3px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.arrow-right span {
  position: absolute;
  margin-left: -35px;
  top: 12px;
  width: 10px;
  padding-right: 0;
  padding-top: 3px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
</style>