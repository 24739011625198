<template>
  <div>
    <h1 class="group-header">Benachrichtigungsgruppe</h1>

    <div class="p-grid">
      <div class="p-col-12">
        <DataTable
          v-if="rerenderTable"
          :value="workgroups"
          editMode="row"
          data-key="id"
          :editingRows.sync="editingRowsComputed"
          @row-edit-init="onRowEditInit"
          @row-edit-save="onRowEditSave"
          @row-edit-cancel="onRowEditCancel"
          responsiveLayout="scroll"
          showGridlines
          style="margin: 20px;"
        >
          <Column sortable :styles="{width:'10%;', 'min-width':'8rem'}" field="workgroupname">
            <template #header>
              <span>Gruppen</span>
            </template>
            <template #editor="slotProps">
              <InputText type="text" v-model="slotProps.data[slotProps.column.field]" autofocus />
            </template>
            <template #body="slotProps">
              <span>{{slotProps.data[slotProps.column.field]}}</span>
            </template>
          </Column>

          <Column :styles="{width:'75%', 'min-width':'8rem'}" header="Adressen">
            <template #editor="slotProps">
              <Textarea
                rows="5"
                :cols="(screenWidthComputed == 'lg' || screenWidthComputed == 'md' || screenWidthComputed == 'sm') ? '30' : '50' "
                v-model="slotProps.data.workgroupemaillist"
              />
            </template>

            <template #body="slotProps">
              <span v-for="(wgel,idx) in slotProps.data.workgroupemaillist" v-bind:key="wgel">
                {{wgel}}
                <span v-if="idx < slotProps.data.workgroupemaillist.length-1">,</span>
              </span>
            </template>
          </Column>

          <Column :styles="{width:'5%'}" field="active" header="Teilnahme">
            <template #editor="slotProps">
              <InputSwitch
                :disabled="slotProps.data.ruleWarningType === ''"
                v-model="slotProps.data[slotProps.column.field]"
              />
            </template>
            <template #body="slotProps">
              <InputSwitch disabled v-model="slotProps.data[slotProps.column.field]" />
            </template>
          </Column>

          <Column :styles="{width:'5%'}" header="Mitglieder">
            <template #editor="slotProps">{{countEmail(slotProps.data["workgroupemaillist"])}}</template>
            <template #body="slotProps">{{slotProps.data["workgroupemaillist"].length}}</template>
          </Column>

          <Column
            :rowEditor="true"
            :styles="{width:'5%', 'min-width':'8rem'}"
            :bodyStyle="{'text-align':'center'}"
          ></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "echarts";
import ResizingMixins from "../mixins/ResizingMixins";
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";

export default {
  mixins: [ResizingMixins, DateTimeFormatorMixins],
  components: {},
  data: () => ({
    rerenderTable: true,
    workgroups: [],
    editingRows: [],
    prevEditingRow: []
  }),
  watch: {},
  props: {
    askRedraw: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("WorkGroupStore", [
      "getWorkGroupList",
      "getWorkGroupEmailList"
    ]),
    editingRowsComputed: {
      set(val) {
        if (val.length > 0) {
          this.workgroups
            .map(warning => warning.id)
            .forEach((id, idx) => {
              if (id == val[0].id) this.editingIdx = idx;
            });
        } else {
          this.editingIdx = 0;
        }

        if (val.length > 1) {
          this.loadInitialData();
          val.shift();
        }

        this.editingRows = val;
      },
      get() {
        return this.editingRows;
      }
    }
  },
  methods: {
    ...mapActions("WorkGroupStore", [
      "GET_ALL_WORK_GROUPS",
      "ADD_WORKGROUP",
      "UPDATE_WORKGROUP",
      "UPDATE_WORKGROUP_EMAIL_LIST",
      "SELECT_WORKGROUP",
      "GET_EMAIL_LIST_BY_WORK_GROUP_ID"
    ]),
    countEmail(emailList) {
      if (typeof emailList == "object") {
        emailList = JSON.stringify(emailList);
        emailList.replace("[", "");
        emailList.replace("]", "");
      }

      return emailList.split(",").length;
    },
    actionRedrawChart() {
      new Promise(resolve => {
        this.chartRedraw = true;
        resolve(false);
      }).then(data => {
        this.chartRedraw = data;
      });
    },
    onRowEditInit() {
      this.prevEditingRow = { ...this.editingRowsComputed[0] };
    },
    onRowEditCancel() {
      let idxOfMatch = this.workgroups
        .map(wg => wg.id)
        .indexOf(this.prevEditingRow.id);

      this.workgroups[idxOfMatch] = this.prevEditingRow;
      this.loadInitialData();
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      // REFORM ARRAY TO STRING
      if (typeof newData.workgroupemaillist == "object") {
        let temp = "";
        newData.workgroupemaillist.forEach((a, idx) => {
          if (idx < newData.workgroupemaillist.length - 1)
            temp = temp + a + ",";
          else temp = temp + a;
        });
        newData.workgroupemaillist = temp;
      } else {
        newData.workgroupemaillist = newData.workgroupemaillist.replace(
          /;/g,
          ""
        );
      }

      // REMOVING DUPLICATE EMAIL
      newData.workgroupemaillist = [
        ...new Set(newData.workgroupemaillist.split(","))
      ];

      // VALIDATE EMAIL FORMAT
      let regex = new RegExp("^[^s@]+@(?:[a-z]+.)*(?:[a-z]+.)[a-z]{1,2}$");

      let emailTemp = [];
      newData.workgroupemaillist.forEach(address => {
        if (regex.test(address)) {
          emailTemp.push(address);
        }
      });
      newData.workgroupemaillist = emailTemp;

      this.workgroups[index] = newData;
      let data = { ...this.workgroups[index] };
      let tempWorkGroupEmail = data.workgroupemaillist;

      data.workgroupemaillist = [];
      tempWorkGroupEmail.forEach(wge => {
        data.workgroupemaillist.push({
          email: wge,
          workgroup_id: data.workgroup_id
        });
      });

      // VALIDATE EMPTY GROUP NAME
      let flagUpdate = true;
      if (data.workgroupname == "") flagUpdate = false;

      // VALIDATE DUPLICATE GROUP NAME
      let filteredWorkgroups = this.workgroups.filter(
        wg => wg.id !== this.prevEditingRow.id
      );
      let idxIdentic = filteredWorkgroups
        .map(fwg => fwg.workgroupname)
        .indexOf(data.workgroupname);
      if (idxIdentic > -1) {
        flagUpdate = false;
      }

      if (flagUpdate) {
        this.UPDATE_WORKGROUP(data).then(() => {
          this.loadInitialData();
        });
      } else {
        this.loadInitialData();
      }

      this.UPDATE_WORKGROUP_EMAIL_LIST(data.workgroupemaillist).then(() => {
        this.workgroups.sort((a, b) => a.workgroupname > b.workgroupname);
      });
    },
    loadInitialData() {
      this.workgroups = [];
      this.GET_ALL_WORK_GROUPS().then(() => {
        this.getWorkGroupList.forEach(workgroup => {
          this.GET_EMAIL_LIST_BY_WORK_GROUP_ID(workgroup.workgroup_id)
            .then(() => {
              workgroup["workgroupemaillist"] = this.getWorkGroupEmailList.map(
                wge => wge.email
              );
              this.workgroups.push(workgroup);
            })
            .then(() => {
              this.warningsSort();
              new Promise(resolve => {
                this.rerenderTable = false;
                resolve(true);
              }).then(data => {
                this.rerenderTable = data;
              });
            });
        });
      });
    },
    warningsSort() {
      this.workgroups = this.workgroups.sort((a, b) => {
        if (a.workgroupname < b.workgroupname) return -1;
        if (a.workgroupname > b.workgroupname) return 1;
        return 0;
      });
    }
  },
  mounted() {
    this.loadInitialData();
  }
};
</script>

<style scoped>
::v-deep .p-datatable .p-datatable-thead > tr > th {
  color: #e2e2e2 !important;
  background: #003349 !important;
}

.group-header {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #002230;
  margin: 20px;
}
</style>
