<template>
  <div>
    <Button icon="pi pi-arrow-left" class="p-ml-4 p-mt-3" @click="actionBack()" />
    <div class="p-grid p-mt-2 p-mb-2 p-ml-4">
      <div class="p-col">
        <div class="abgange-name">{{selectedDevice.settingLabel}}</div>
        <div class="station">{{selectedStation.stationname}}</div>
        <div
          class="address"
        >{{selectedStation.stationaddress + ", "+selectedStation.stationlocation}}</div>
      </div>

      <div class="p-flex-shrink-0 p-mr-6">
        <span class="p-buttonset">
          <Button label="Details" :style="subMenuStyleComputed.details" @click="actionDetails()" />
          <Button label="Lastgang" :style="subMenuStyleComputed.chart" @click="actionChart()" />
          <Button label="Regeln" :style="subMenuStyleComputed.rulelist" @click="actionRuleList()" />
        </span>
      </div>
    </div>

    <div class="p-grid p-ml-2 p-mr-2" v-if="subMenuOpen.details">
      <div class="p-col">
        <DataTable
          :value="voltageArrayComputed"
          class="p-mt-2"
          style="border: black 1px solid;"
          responsiveLayout="scroll"
          stripedRows
          :lazy="true"
        >
          <Column header="Spannung" field="voltageName"></Column>
          <Column header="Wert" field="voltage"></Column>
        </DataTable>
      </div>

      <div class="p-col">
        <DataTable
          :lazy="true"
          :value="currentArrayComputed"
          class="p-mt-2"
          style="border: black 1px solid;"
          stripedRows
          responsiveLayout="scroll"
        >
          <div>
            <Column header="Strom" field="currentName"></Column>

            <Column header="Wert" field="current">
              <template #body="slotProps">
                <div class="p-grid p-jc-start p-ai-center" v-if="'direction' in slotProps.data">
                  <div class="p-col-12">{{slotProps.data.current}}</div>
                </div>
              </template>
            </Column>
            <Column header="Richtung" field>
              <template #body="slotProps">
                <DirectionArrow
                  class="p-ml-2"
                  v-if="slotProps.data.direction !== ''"
                  :direction="slotProps.data.direction"
                />
              </template>
            </Column>
          </div>
        </DataTable>
      </div>

      <div class="p-col p-ml-2 p-mr-3">
        <DataTable
          ref="tblPower"
          :lazy="true"
          :value="powerDataTableComputed"
          style="border: black 1px solid;"
          class="p-mt-2"
          stripedRows
          responsiveLayout="scroll"
        >
          <div>
            <Column header="Leistung" field="powerDataName"></Column>
            <Column header="Wert" field="powerData"></Column>
          </div>
        </DataTable>
      </div>
    </div>

    <div v-if="subMenuOpen.chart">
      <CMDChart
        :selectedDevice="selectedDevice"
        :bigBar="bigBar"
        :askRedraw="askRedraw"
        :showMonitoringSetting="showMonitoringSetting"
        @showMonitoringSettingEmit="showMonitoringSettingEmit"
      />
    </div>

    <div v-if="subMenuOpen.rulelist">
      <RuleListComponent :selectedDevice="selectedDevice" />
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";

import ResizingMixins from "../mixins/ResizingMixins";
import MenuMixins from "../mixins/MenuMixins";
import CMDChart from "./ConditionMonitorChart";
import RuleListComponent from "./RuleListComponent";
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";
import DirectionArrow from "./DirectionArrowComponent";

export default {
  name: "ConditionMonitorDetails",
  mixins: [ResizingMixins, DateTimeFormatorMixins, MenuMixins],
  components: {
    CMDChart,
    DirectionArrow,
    RuleListComponent
  },

  data() {
    return {
      showDetails: true,
      showChart: false,
      showRuleList: false,
      showMonitoringSetting: false,
      subMenuOpen: {
        details: true,
        chart: false,
        rulelist: false
      }
    };
  },
  watch: {},
  props: {
    askRedraw: {
      default: false,
      type: Boolean
    },
    selectedDevice: {
      type: Object,
      default: () => ({})
    },
    bigBar: {
      type: Boolean,
      default: false
    },
    selectedStation: {
      type: Object,
      default: () => ({
        station_id: -1,
        stationname: "",
        stationlocation: "",
        stationaddress: "",
        workgrouplist: []
      })
    }
  },
  computed: {
    voltageArrayComputed() {
      let voltageArray = [
        {
          voltage: "0,0 V",
          voltageName: "U1"
        },
        {
          voltage: "0,0 V",
          voltageName: "U2"
        },
        {
          voltage: "0,0 V",
          voltageName: "U3"
        }
      ];
      if ("L1" in this.selectedDevice) {
        voltageArray[0].voltage = this.selectedDevice.L1.voltage + " V";
      }

      if ("L2" in this.selectedDevice) {
        voltageArray[1].voltage = this.selectedDevice.L2.voltage + " V";
      }

      if ("L3" in this.selectedDevice) {
        voltageArray[2].voltage = this.selectedDevice.L3.voltage + " V";
      }
      return voltageArray;
    },
    apparentPowerComputed() {
      let apparentPowerArray = [
        {
          power: "0,0 kVa",
          powerName: "Scheinleistung"
        }
      ];
      if ("apparentpower" in this.selectedDevice) {
        apparentPowerArray[0].power =
          this.selectedDevice.apparentpower + " kVa";
      }
      return apparentPowerArray;
    },
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    },
    voltageArrayVerticalComputed() {
      return [
        {
          id: 1000,
          voltageName: "UI"
        },
        {
          id: 2000,
          voltageName: "U2"
        },
        {
          id: 3000,
          voltageName: "U3"
        }
      ];
    },
    currentArrayComputed() {
      let currentArray = [
        { current: "0,0 A", currentName: "I1", direction: "" },
        { current: "0,0 A", currentName: "I2", direction: "" },
        { current: "0,0 A", currentName: "I3", direction: "" },
        { current: "0,0 A", currentName: "In", direction: "" }
      ];

      // console.log("=====currentArrayComputed() this.selectedDevice: =====");
      // console.log(this.selectedDevice);

      if ("L1" in this.selectedDevice) {
        currentArray[0].current = this.selectedDevice.L1.current + " A";
        currentArray[0].direction = this.selectedDevice.L1.direction;
      }

      if ("L2" in this.selectedDevice) {
        currentArray[1].current = this.selectedDevice.L2.current + " A";
        currentArray[1].direction = this.selectedDevice.L2.direction;
      }

      if ("L3" in this.selectedDevice) {
        currentArray[2].current = this.selectedDevice.L3.current + " A";
        currentArray[2].direction = this.selectedDevice.L3.direction;
      }

      if ("Ln" in this.selectedDevice) {
        currentArray[3].current = this.selectedDevice.Ln.current + " A";
      }

      return currentArray;
    },
    powerDataTableComputed() {
      let powerDataTableArray = [
        {
          powerData: "0,0 kVA",
          powerDataName: "S"
        },
        {
          powerData: "0,0 kW",
          powerDataName: "P"
        },
        {
          powerData: "0,0 kvar",
          powerDataName: "Q"
        },
        {
          powerData: "0",
          powerDataName: "cosΦ"
        }
      ];

      if ("apparentpower" in this.selectedDevice) {
        powerDataTableArray[0].powerData =
          this.selectedDevice.apparentpower + " kVA";
      }

      if ("effectivepower" in this.selectedDevice) {
        powerDataTableArray[1].powerData =
          this.selectedDevice.effectivepower + " kW";
      }

      if ("reactivepower" in this.selectedDevice) {
        powerDataTableArray[2].powerData =
          this.selectedDevice.reactivepower + " kvar";
      }
      if ("totalpowerfactor" in this.selectedDevice) {
        powerDataTableArray[3].powerData = this.selectedDevice.totalpowerfactor;
      }
      return powerDataTableArray;
    },
    subMenuStyleComputed() {
      let subMenuStyle = {
        details: "background:white; color:black",
        chart: "background:white; color:black",
        rulelist: "background:white; color:black"
      };

      if (this.subMenuOpen.details)
        subMenuStyle.details = "background:#003349; color:white";
      if (this.subMenuOpen.chart)
        subMenuStyle.chart = "background:#003349; color:white";
      if (this.subMenuOpen.rulelist)
        subMenuStyle.rulelist = "background:#003349; color:white";

      return subMenuStyle;
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER"
    ]),
    actionBack() {
      this.menu.conditionmonitor = true;
      this.menu.station = false;
      this.$emit("menuEmit", this.menu);
    },
    showHideDialog() {
      this.$refs.chartDialog.maximize();
    },
    showMonitoringSettingEmit(val) {
      this.showMonitoringSetting = val;
    },
    directionIconGenerator(val) {
      if (val === "B") {
        return "pi pi-arrow-left";
      } else if (val === "A") {
        return "pi pi-arrow-right";
      }
    },
    actionDetails() {
      this.subMenuOpen.details = true;
      this.subMenuOpen.chart = false;
      this.subMenuOpen.rulelist = false;
    },
    actionChart() {
      this.subMenuOpen.details = false;
      this.subMenuOpen.chart = true;
      this.subMenuOpen.rulelist = false;
      new Promise(resolve => {
        this.$emit("askRedrawEmit", true);
        resolve(false);
      }).then(data => {
        this.$emit("askRedrawEmit", data);
      });
    },
    actionRuleList() {
      this.subMenuOpen.details = false;
      this.subMenuOpen.chart = false;
      this.subMenuOpen.rulelist = true;
    }
  },
  mounted() {
    this.GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER({
      projectnumber: this.selectedDevice.projectnumber,
      ordinalnumber: this.selectedDevice.ordinalnumber
    });
  }
};
</script>

<style scoped>
::v-deep .p-datatable .p-datatable-thead > tr > th {
  color: #e2e2e2 !important;
  background: #003349 !important;
}
.abgange-name {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
}

.station {
  font-style: normal;
  font-family: Barlow;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: grey;
}
.address {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
</style>