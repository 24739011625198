<template>
  <div>

    <div v-if="screenWidthComputed === 'xlg'" style="height: 160px; width: 460px;">
      <div class="p-grid">

        <div class="p-col-4">
          <label for="settingProject" style="width: 140px">Projektnummer</label>
          <div class="p-field p-grid p-mt-2">
            <div class="p-col">
              <InputText
                  :disabled="disableProjectNumber"
                  id="settingProject"
                  aria-describedby="settingProject-help"
                  type="text"
                  style="width: 100px; border-radius: 5px"
                  v-model="projectNumberComputed"
              />
            </div>
            <small
                id="settingProject-help"
                class="p-invalid"
                v-show="classList.projectNumberClass!==''"
            >Projektnummer darf nicht leer sein.</small>
          </div>
        </div>

        <div class="p-col-8">
          <label for="settingLabel" style="width: 140px">Bezeichnung</label>
          <div class="p-field p-grid p-mt-2">
            <div class="p-col">
              <InputText
                  id="settingLabel"
                  type="text"
                  style="width: 310px ; border-radius: 5px"
                  v-model="settingLabelComputed"
              />
            </div>
            <small
                id="settingStation-help"
                class="p-invalid"
                v-show="classList.settingLabelClass!==''"
            >{{settingLabelWarning}}</small>
          </div>
        </div>

        <div class="p-col-4">
          <label for="settingOrdinal" style="width: 140px">Ordinalzahl</label>
          <div class="p-field p-grid p-mt-2">
            <div class="p-col">
              <InputText
                  :disabled="disableOrdinalNumber"
                  id="settingOrdinal"
                  aria-describedby="settingOrdinal-help"
                  type="number"
                  min="1"
                  style="width: 100px ; border-radius: 5px"
                  v-model="ordinalNumberComputed"
              />
            </div>
            <small
                id="settingOrdinal-help"
                class="p-invalid"
                v-show="classList.ordinalNumberClass!==''"
            >Ordinalzahl darf nicht leer sein.</small>
            <div class="p-field p-grid">
              <div v-show="showProjectOrdinalMessage" style="color:red">Dieses Gerät ist bereits angelegt.</div>
            </div>
          </div>
        </div>



        <div class="p-col-8">
          <div class="element-padding" style="margin-top: 16px; float: right">
            <Button
                label="Abbrechen"
                class="p-button-secondary"
                id="button-remove"
                @click="closeSettingDialog"

            />
            <Button label="Speichern" id="button-save" @click="saveSetting"  />
          </div>
        </div>
      </div>
    </div>


    <div v-else style="height: 354px; width: 344px;">

    <div class="settingsBar" style="padding-bottom: 15px">Abgang bearbeiten</div>


    <div class="element-padding">
      <label for="settingProject" style="width: 140px">Projektnummer</label>
      <div class="p-field p-grid p-mt-2">
        <div class="p-col">
          <InputText
              :disabled="disableProjectNumber"
              id="settingProject"
              aria-describedby="settingProject-help"
              type="text"
              style="width: 345px ; border-radius: 5px"
              v-model="projectNumberComputed"
          />
        </div>
        <small
            id="settingProject-help"
            class="p-invalid"
            v-show="classList.projectNumberClass!==''"
        >Projektnummer darf nicht leer sein.</small>
      </div>
    </div>

    <div class="element-padding">
      <label for="settingOrdinal" style="width: 140px">Ordinalzahl</label>
      <div class="p-field p-grid p-mt-2">
        <div class="p-col">
          <InputText
              :disabled="disableOrdinalNumber"
              id="settingOrdinal"
              aria-describedby="settingOrdinal-help"
              type="number"
              style="width: 345px ; border-radius: 5px"
              v-model="ordinalNumberComputed"
          />
        </div>
        <small
            id="settingOrdinal-help"
            class="p-invalid"
            v-show="classList.ordinalNumberClass!==''"
        >Ordinalzahl darf nicht leer sein.</small>
        <div class="p-field p-grid">
          <div v-show="showProjectOrdinalMessage" style="color:red">Dieses Gerät ist bereits angelegt.</div>
        </div>
      </div>
    </div>


    <div class="element-padding">
      <label for="settingLabel" style="width: 140px">Bezeichnung</label>
      <div class="p-field p-grid p-mt-2">
        <div class="p-col">
          <InputText
              id="settingLabel"
              type="text"
              aria-describedby="settingLabel-help"
              style="width: 345px ; border-radius: 5px"
              v-model="settingLabelComputed"
          />
        </div>
        <small
            id="settingStation-help"
            class="p-invalid"
            v-show="classList.settingLabelClass!==''"
        >{{settingLabelWarning}}</small>
      </div>
    </div>

    <!--
    <div class="secondBox">
      <div class="p-field p-grid">

      </div>

      <div class="p-field p-grid">
        <label for="settingLocation" class="p-col-fixed" style="width: 140px">Ort</label>
        <div class="p-col">
          <InputText
            :disabled="disableSettingLocation"
            id="settingLocation"
            type="text"
            style="width: 240px ; border-radius: 5px"
            v-model="settingLocationComputed"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label for="settingAddress" class="p-col-fixed" style="width: 140px">Adresse</label>
        <div class="p-col">
          <InputText
            :disabled="disableSettingAddress"
            id="settingAddress"
            type="text"
            style="width: 240px ; border-radius: 5px"
            v-model="settingAddressComputed"
          />
        </div>
      </div>
    </div>
-->
    <div class="element-padding">
      <Button
          label="Abbrechen"
          class="p-button-secondary"
          id="button-remove"
          @click="closeSettingDialog"
          style="float: left"
      />
      <Button label="Speichern" id="button-save" @click="saveSetting" style="float:right;" />
    </div>
    </div>
  </div>



</template>


<script>
import ResizingMixins from "@/mixins/ResizingMixins";
import {mapGetters} from "vuex";

export default {
  mixins: [ResizingMixins],
  components: {},
  data() {
    return {
      classList: {
        projectNumberClass: "",
        ordinalNumberClass: "",
        settingLabelClass: ""
      },
      projectOrdinalNumberMessage: "",
      settingLabelWarning: ""
    };
  },
  props: {
    projectnumber: {
      default: "",
      type: String
    },
    disableProjectNumber: {
      default: false,
      type: Boolean
    },
    disableOrdinalNumber: {
      default: false,
      type: Boolean
    },
    ordinalnumber: {
      default: null,
      type: Number
    },
    settingMeasurement: {
      default: "",
      type: String
    },
    settingLabel: {
      default: "",
      type: String
    },
    settingAddress: {
      default: "",
      type: String
    },
    settingLocation: {
      default: "",
      type: String
    },
    disableSettingLocation: {
      default: false,
      type: Boolean
    },
    disableSettingAddress: {
      default: false,
      type: Boolean
    },
    showRemoveBtn: {
      default: true,
      type: Boolean
    },
    showProjectOrdinalMessage: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters("ConditionMonitorStore", ["getConditionMonitorSettingList"]),
    projectNumberComputed: {
      set(val) {
        this.$emit("projectnumberEmit", val);
      },
      get() {
        return this.projectnumber.toLowerCase();
      }
    },
    ordinalNumberComputed: {
      set(val) {
        this.$emit("ordinalnumberEmit", parseInt(val));
      },
      get() {
        return this.ordinalnumber;
      }
    },
    settingLabelComputed: {
      set(val) {
        this.$emit("settingLabelEmit", val);
      },
      get() {
        return this.settingLabel;
      }
    },
    settingAddressComputed: {
      set(val) {
        this.$emit("settingAddressEmit", val);
      },
      get() {
        return this.settingAddress;
      }
    },
    settingLocationComputed: {
      set(val) {
        this.$emit("settingLocationEmit", val);
      },
      get() {
        return this.settingLocation;
      }
    }
  },
  methods: {
    closeSettingDialog() {
      this.$emit("closeAddUpdateConditionMonitorEmit", false);
    },
    validateDeviceLabel(){
      let indexOfExist = -1;
      if (this.settingLabelComputed !== "") {
        let settingLabelList = this.getConditionMonitorSettingList.map(
            settingComponent => settingComponent.settingLabel
        );

        if (this.settingLabelComputed !== this.prevstationname)
          indexOfExist = settingLabelList.indexOf(this.settingLabelComputed);

        if (indexOfExist >= 0) {
          this.classList.settingLabelClass = "p-invalid";
          this.settingLabelWarning = "Bezeichnung existiert bereits.";
        } else {
          this.classList.settingLabelComputed = "";
        }
      }
      return !(this.settingLabelComputed === "") && indexOfExist === -1;
    },
    validateForm() {
      this.projectNumberComputed === ""
          ? (this.classList.projectNumberClass = "p-invalid")
          : (this.classList.projectNumberClass = "");
      this.ordinalNumberComputed === null
          ? (this.classList.ordinalNumberClass = "p-invalid")
          : (this.classList.ordinalNumberClass = "");

      if (this.settingLabelComputed === "") {
        this.classList.settingLabelClass = "p-invalid";
        this.settingLabelWarning = "Bezeichnung darf nicht leer sein.";
      } else {
        this.classList.settingLabelClass = "";
      }

      return !(
          this.projectNumberComputed === "" ||
          this.ordinalNumberComputed === null ||
          this.settingLabelComputed === ""

      );
    },
    saveSetting() {
      if (this.validateForm()) {
        if (this.validateDeviceLabel()){

          this.$emit("saveSettingEmit");
        }

      }
    }
  }
};
</script>


<style scoped>
#settingsContainer {
  width: 480px;
  border: 1px solid black;
}

.settingsBar {
  width: 250px;
  padding-top: 5px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}

.element-padding{
  padding-top: 10px;
}

#box-buttonx {
  width: 20%;
  padding-top: 10px;
  padding-left: 30px;
}

#buttonx {
  background-color: transparent;
  height: 50px;
  font-size: 30px;
  border: none;
  color: grey;
}

.secondBox {
  width: 480px;
  border-width: 0px 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-left: 20px;
  padding-top: 15px;
}
.secondBox span {
  background: blue;
}

.dropdownBar {
  width: 240px;
  height: 35px;
  border-radius: 5px;
}

.buttonBox {
  width: 480px;
  border-width: 0px 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

#button-danger {
  width: 120px;
  margin-right: 30px;
}

#button-remove {
  width: 120px;
  margin-right: 30px;
}

#button-save {
  width: 120px;
}
</style>