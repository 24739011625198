import Vue from 'vue'
import App from './App.vue'
import axios from "axios"
import PrimeVue from 'primevue/config';
import store from '@/store';
import VueLogger from 'vuejs-logger';
import VueNativeSock from 'vue-native-websocket'
import { BentoNet } from "bentonet"
import "@fontsource/barlow";

// import * as Keycloak from 'keycloak-js';

let websocketUrl = 'wss://' + location.host + '/data'
if (process.env.NODE_ENV === 'development') {
  websocketUrl = 'ws://localhost:4444/data'
}

// Vue.use(function (req, res, next) {
//   res.setHeader(
//     'Content-Security-Policy', "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'"
//   );
//   next();
// });

Vue.use(VueNativeSock, websocketUrl, {
  store: store,
  reconnection: true,
  reconnectionAttempts: 0,
  reconnectionDelay: 5000
})
Vue.config.productionTip = false

Vue.use(PrimeVue, {
  locale: {
    startsWith: 'Starts with',
    contains: 'Contains',
    notContains: 'Not contains',
    endsWith: 'Ends with',
    equals: 'Equals',
    notEquals: 'Not equals',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Löschen',
    apply: 'Übernehmen',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Regel hinzufügen',
    removeRule: 'Regel entfernen',
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Auswählen',
    upload: 'Upload',
    cancel: 'Abbrechen',
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: 'Heute',
    weekHeader: 'KW',
    firstDayOfWeek: 0,
    dateFormat: 'dd.mm.yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: 'No results found',
    emptyMessage: 'No available options'
  }

});
Vue.use(VueLogger);

import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"                           //icons
import 'primeflex/primeflex.css'
import "@/style/base.css"
import "@/style/theme.css"

import ConfirmationService from 'primevue/confirmationservice';
Vue.use(ConfirmationService);

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import Card from 'primevue/card';            //  PRIMEVUE
Vue.component('Card', Card);

import Toast from 'primevue/toast';
Vue.component('Toast', Toast);

import InputText from 'primevue/inputtext';
Vue.component('InputText', InputText);

import Textarea from 'primevue/textarea';
Vue.component('Textarea', Textarea);

import Button from 'primevue/button';
Vue.component('Button', Button);

import Badge from 'primevue/badge';
Vue.component('Badge', Badge);

import Dialog from 'primevue/dialog';
Vue.component('Dialog', Dialog);

import DataTable from 'primevue/datatable';
Vue.component('DataTable', DataTable)

// import TreeTable from 'primevue/treetable';
// Vue.component('TreeTable', TreeTable)

import Column from "primevue/column/Column";
Vue.component("Column", Column)

import Row from "primevue/row"
Vue.component("Row", Row)

import Checkbox from 'primevue/checkbox';
Vue.component("Checkbox", Checkbox)

import ColumnGroup from "primevue/columngroup";
Vue.component("ColumnGroup", ColumnGroup)

import Calendar from "primevue/calendar";
Vue.component("Calendar", Calendar)

import Chart from 'primevue/chart';
Vue.component("Chart", Chart)

import SpeedDial from 'primevue/speeddial';
Vue.component('SpeedDial', SpeedDial);

import ConfirmDialog from 'primevue/confirmdialog';
Vue.component('ConfirmDialog', ConfirmDialog);

import CascadeSelect from 'primevue/cascadeselect';
Vue.component('CascadeSelect', CascadeSelect);

import TabView from 'primevue/tabview';
Vue.component('TabView', TabView);

import TabPanel from 'primevue/tabpanel';
Vue.component('TabPanel', TabPanel);

import Slider from 'primevue/slider';
Vue.component('Slider', Slider);

import InputSwitch from 'primevue/inputswitch';
Vue.component('InputSwitch', InputSwitch);

import Dropdown from 'primevue/dropdown';
Vue.component('Dropdown', Dropdown);

// import "echarts";
import VChart from "vue-echarts";
Vue.component("VChart", VChart);

import Sidebar from 'primevue/sidebar';
Vue.component("Sidebar", Sidebar);

import ScrollPanel from 'primevue/scrollpanel';
Vue.component("ScrollPanel", ScrollPanel);

import DataView from 'primevue/dataview';
Vue.component('DataView', DataView);

import Listbox from 'primevue/listbox';
Vue.component('Listbox', Listbox);

import Chip from 'primevue/chip';
Vue.component('Chip', Chip);

import Chips from 'primevue/chips';
Vue.component('Chips', Chips);

import MultiSelect from 'primevue/multiselect';
Vue.component('MultiSelect', MultiSelect);

import Menu from 'primevue/menu';
Vue.component('Menu', Menu);

import AutoComplete from 'primevue/autocomplete';
Vue.component('AutoComplete', AutoComplete);

import RadioButton from 'primevue/radiobutton';
Vue.component('RadioButton', RadioButton);

import Divider from 'primevue/divider';
Vue.component('Divider', Divider);





axios.interceptors.request.use(
  config => {
    const token = window.sessionStorage.getItem("applicationToken")
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

new BentoNet(() => {

  new Vue({
    store,
    render: (h) => h(App),
  }).$mount("#app");

}, ['bentonet.de', location.host]);










// KEYCLOAK AUTHENTICATION
// let keycloakUrl;
// if (process.env.NODE_ENV === 'development') {
//   keycloakUrl = 'http://127.0.0.1:8080/auth'
// } else {
//   keycloakUrl = 'https://login.bentonet.de/auth'
// }
// let initOptions = {
//   url: keycloakUrl, realm: 'Bentonet', clientId: 'bentonet-fe', onLoad: 'login-required'
// }

// let keycloak = Keycloak(initOptions);
// keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
//   if (!auth) {
//     window.location.reload();
//   } else {
//     localStorage.setItem('token', keycloak.token);
//     localStorage.setItem('username', keycloak.tokenParsed.preferred_username);
//     localStorage.setItem('role', keycloak.tokenParsed.realm_access.roles);
//   }
//   //Token Refresh 
//   setInterval(() => {
//     keycloak.updateToken(50000).then((refreshed) => {
//       if (refreshed) {
//         // console.log('Token refreshed' + refreshed);
//       } else {
//         // console.log('Token not refreshed, valid for '
//         //   + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
//       }
//     }).catch(() => {
//       // console.log('Failed to refresh token');
//     });
//   }, 50000)
// }).then(() => {
//   new Vue({
//     render: h => h(App),
//     store
//   }).$mount('#app')
// })







