export default {
    methods: {
        unitGenerator(key) {
            if (key == "apparentpower") {
                return "kVA";
            } else if (key == "effectivepower") {
                return "kW"
            } else if (key == "reactivepower") {
                return "kvar"
            }
            else if (
                key == "L1.current" ||
                key == "L2.current" ||
                key == "L3.current" ||
                key == "Ln.current"
            ) {
                return "A";
            }
        }
    }

}


