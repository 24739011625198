<template>
  <Dialog :visible.sync="showExportData" :closable="false">
    <template #header>
      <span style="font-weight: bold ; font-size: 22px">Datenexport</span>
    </template>

    <div class="p-grid p-mb-3 p-mt-2" style="display: grid; grid-template-columns: 50% 50%;">
      <div>
        <InputText
          ref="startDateRef"
          type="text"
          class="p-inputtext-lg"
          style="width: 220px"
          placeholder="Startdatum"
          v-model="startDate"
          disabled="true"
        />
      </div>

      <div>
        <InputText
          ref="endDateRef"
          type="text"
          class="p-inputtext-lg"
          style="width: 220px"
          placeholder="Enddatum"
          v-model="endDate"
          disabled="true"
        />
      </div>
    </div>

    <Calendar v-model="startEndDate" :showWeek="true" :inline="true" selectionMode="range" />

    <div class="p-grid p-jc-end p-mt-3 p-mr-1">
      <Button label="Abbrechen" class="p-button-danger" @click="cancel()"></Button>
      <Button
        :disabled="exportButtonDisabledComputed"
        id="buttonExport"
        label="Exportieren"
        class="p-button-primary"
        @click="exportCSV()"
      />
    </div>

    <DataTable
      :paginator="true"
      paginatorPosition="top"
      :rows="5"
      :value="exportDataComputed"
      ref="dtExport"
      responsiveLayout="scroll"
      v-show="false"
      :exportFilename="exportFileNameComputed"
    >
      <Column field="timestamp" header="Timestamp"></Column>
      <Column field="projectnumber" header="Project Number"></Column>
      <Column field="ordinalnumber" header="Ordinal Number"></Column>
      <Column field="devicestatus" header="Device Status"></Column>
      <Column field="effectivepower" header="Effective Power"></Column>
      <Column field="reactivepower" header="Reactive Power"></Column>
      <Column field="apparentpower" header="Apparent Power"></Column>
      <Column field="totalpowerfactor" header="Total Power Factor"></Column>
      <Column field="L1.voltage" header="I1 Voltage"></Column>
      <Column field="L1.current" header="I1 Current"></Column>
      <Column field="L1.direction" header="I1 Direction"></Column>
      <Column field="L2.voltage" header="I2 Voltage"></Column>
      <Column field="L2.current" header="I2 Current"></Column>
      <Column field="L2.direction" header="I2 Direction"></Column>
      <Column field="L3.voltage" header="I3 Voltage"></Column>
      <Column field="L3.current" header="I3 Current"></Column>
      <Column field="L3.direction" header="I3 Direction"></Column>
      <Column field="Ln.current" header="In Current"></Column>
    </DataTable>
  </Dialog>
</template>


<script>
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";
export default {
  mixins: [DateTimeFormatorMixins],
  data() {
    return {
      startEndDate: [],
      startDate: "",
      endDate: "",
      calenderValue: ""
    };
  },
  props: {
    showExportData: {
      type: Boolean,
      default: false
    },
    historyList: {
      type: Array,
      default: () => []
    },
    fileName: {
      type: String,
      default: ""
    }
  },
  watch: {
    startEndDate(val) {
      if (val !== null) {
        this.startDate = this.dateFormator(val[0]);
        if (val[1] != null) this.endDate = this.dateFormator(val[1]);

        let startDate = new Date(Date.parse(this.startDate));
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        let endDate = new Date(Date.parse(this.endDate));
        endDate.setHours(24);
        endDate.setMinutes(0);
        endDate.setSeconds(0);

        let data = {
          startDate: this.dateTimeFormatorUTC(startDate),
          endDate: this.dateTimeFormatorUTC(endDate),
          timeZone: this.getGMTOffset(),
          projectnumber: "",
          ordinalnumber: -1,
          station_id: -1
        };
        if (this.endDate !== "") {
          this.$emit("exportParameterEmit", data);
        }
      }
    },
    startDate() {
      this.endDate = "";
    }
  },
  computed: {
    exportDataComputed() {
      this.historyList.forEach(e => {
        for (const key in e) {
          if (key == "timestamp")
            e.timestamp = this.timestampToLocale(e.timestamp);
          else {
            if (typeof e[key] == "object") {
              for (const key2 in e[key]) {
                if (e[key][key2] !== null)
                  e[key][key2] = e[key][key2].toString().replaceAll(".", ",");
              }
            } else e[key] = e[key].toString().replaceAll(".", ",");
          }
        }
      });
      return this.historyList;
    },
    exportFileNameComputed() {
      // return (
      //   "export-" +
      //   this.selectedData.projectnumber +
      //   "_" +
      //   this.selectedData.ordinalnumber +
      //   "_" +
      //   this.startDate +
      //   "-to-" +
      //   this.endDate
      // );
      return (
        "export-" + this.fileName + "_" + this.startDate + "-to-" + this.endDate
      );
    },
    exportButtonDisabledComputed() {
      return !(this.startDate !== "" && this.endDate !== "");
    }
  },
  methods: {
    cancel() {
      this.$emit("cancelEmit", false);
    },
    exportCSV() {
      this.$refs.dtExport.exportCSV();
      this.startDate = "";
      this.endDate = "";
      this.$emit("showExportDataEmit", false);
    }
  },
  mounted() {}
};
</script>

<style scoped>
.secondBox {
  width: 480px;
  border-width: 0px 1px 1px 1px;
  border-color: black;
  border-style: solid;
  text-align: center;
  padding: 20px;
}
.buttons {
  padding-top: 10px;
  padding-left: 100px;
}
#buttonExport {
  margin-left: 20px;
}
</style>
