export default {
    data() {
        return {
            menuTemp: {
                station: true,
                conditionmonitor: false,
                conditionmonitordetail: false,
                warning: false,
                grouping: false
            }
        }
    },
    props: {
        menu: {
            type: Object,
            default: () => ({
                station: true,
                conditionmonitor: false,
                conditionmonitordetail: false,
                warning: false,
                grouping: false
            })
        }
    },
    computed: {
        menuComputed: {
            set(val) {
                this.$emit("menuEmit", val)
            },
            get() {
                return this.menu
            }
        }
    }
}