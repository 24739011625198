import axios from "../../plugin/axios/axios";
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//state
const state = {
    operationMessage: "",
    stationList: [],
    historyExportList: []
};

// getters
const getters = {
    getStationList(state) {
        return state.stationList;
    },
    getOperationMessage(state) {
        return state.operationMessage;
    },
    getHistoryExportList(state) {
        return state.historyExportList
    }
};

// actions
const actions = {
    GET_ALL_STATIONS({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_STATION_URL)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = "ok"
                    data.statuscode = 1
                })
                commit("mutateStationList", response.data.data);
                return response;
            });
    },
    GET_STATION_BY_ID({ commit }, station_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/station/" + station_id)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateStationList", response.data.data);
                return response;
            });
    },
    ADD_STATION({ commit }, newStation) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_STATION_URL + "/add", newStation)
            .then((response) => {
                commit("addStation", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    UPDATE_STATION({ commit }, newStation) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_STATION_URL + "/update", newStation)
            .then((response) => {
                // let idxMatch = -1
                // state.stationList.forEach((station, idx) => {

                //     if (station.id === newStation.id) {
                //         idxMatch = idx
                //     }
                // })
                // state.stationList[idxMatch].stationname = newStation.stationname
                // state.stationList[idxMatch].stationaddress = newStation.stationaddress
                // state.stationList[idxMatch].stationlocation = newStation.stationlocation
                // state.stationList.splice(idxMatch, 1)
                // state.stationList.splice(idxMatch, 0, newStation);
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    DELETE_STATION_BY_ID({ commit }, station_id) {
        return axios
            .delete(process.env.VUE_APP_CONDITION_MONITOR_STATION_URL + "/delete/" + station_id)
            .then((response) => {
                commit("mutateStationList", state.stationList.filter(station => {
                    return station.station_id !== station_id
                }))
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    EXPORT_STATION_DATA_BY_RANGE({ commit }, data) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_STATION_URL + "/export/display", data)
            .then((response) => {
                commit("mutateHistoryExportList", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
};


// mutations
const mutations = {
    mutateStationList: (state, stationList) => {
        state.stationList = stationList;
    },
    addStation: (state, newStation) => {
        state.stationList.push(newStation);
    },
    mutateOperationMessage: (state, message) => {
        state.operationMessage = message
    },
    mutateHistoryExportList: (state, historyDataList) => {
        state.historyExportList = historyDataList
    },
    clear: (state) => {
        state.stationList = []
        state.operationMessage = ""
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};