<template>
  <div>
    <div v-if="bigBar" class="flex flex-column" style="position: absolute;">
      <div class="p-grid p-jc-center" style="background-color: #002230;">
        <div class="p-col-2 p-mt-2">
          <Button
            icon="pi pi-times"
            class="p-button-rounded p-button-text"
            style="color:white"
            @click="bigBarComputed = bigBar"
          />
        </div>
        <div class="p-col p-mt-2">
          <img src="@/assets/logo2.png" alt class="logo" style="height:40px" />
        </div>
      </div>
      <div class="p-grid p-jc-center" style="background-color: #002230;  height: 100vh;">
        <div class="p-row">
          <Button
            type="button"
            label="Stationen"
            icon="pi pi-home"
            :style="menu.conditionmonitor ? 'color:white;' : ''"
            :class="menuButtonClassComputed.station"
            @click="selectStation(true)"
          />
          <div class="p-row p-mt-2 p-ml-6">
            <i
              class="pi pi-minus p-text-secondary"
              :style="menuIconStyleComputed.conditionmonitor+'transform: rotate(90deg); margin-left:18px;'"
            ></i>
          </div>
          <div class="p-row p-mt-2">
            <Button
              type="button"
              label="Abgänge"
              icon="pi pi-sitemap"
              :class="menuButtonClassComputed.conditionmonitor"
            />
          </div>
          <div class="p-row p-mt-4">
            <Button
              type="button"
              label="Warnungen"
              icon="pi pi-exclamation-triangle"
              :class="menuButtonClassComputed.warning"
              :badge="badge.warning"
              badgeClass="p-badge-danger"
              @click="selectWarning(true)"
            />
          </div>

          <div class="p-row p-mt-4" style="position: absolute; bottom: 70px">
            <Button
              type="button"
              label="Gruppen"
              icon="pi pi-envelope"
              :class="menuButtonClassComputed.group"
              @click="selectGroup(true)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- ====================================== -->
    <div v-else style="position: absolute">
      <div class="p-grid p-jc-center" style="background-color: #002230; width: 75px;">
        <div class="p-row p-mt-1 p-ml-1">
          <Button
              icon="pi pi-bars"
              class="p-button-rounded p-button-text"
              style="color:white"
              @click="bigBarComputed = bigBar"
          />
        </div>

      </div>

      <div class="p-grid p-jc-center" style="background-color: #002230; height: 100vh;">
        <div class="p-row p-mt-1 p-ml-1">
          <Button
            type="button"
            icon="pi pi-home"
            :style="menu.conditionmonitor ? 'color:white;' : ''"
            :class="menuButtonClassComputed.station"
            @click="selectStation(true)"
          />
          <div class="p-row p-mt-2 p-ml-2">
            <i
              class="pi pi-minus p-text-secondary"
              :style="menuIconStyleComputed.conditionmonitor+'transform: rotate(90deg);'"
            ></i>
          </div>
          <div class="p-row p-mt-2">
            <Button
              disabled="true"
              type="button"
              icon="pi pi-sitemap"
              :class="menuButtonClassComputed.conditionmonitor"
            />
          </div>
          <div class="p-row p-mt-4">
            <Button
              type="button"
              icon="pi pi-exclamation-triangle"
              :class="menuButtonClassComputed.warning"
              @click="selectWarning(true)"
            />
          </div>
          <div class="p-row container-center p-mt-1">
            <Badge size="small" :value="badge.warning" severity="danger" />
          </div>

          <div class="p-row p-mt-4" style="position: absolute; bottom: 45px">
            <Button
              type="button"
              icon="pi pi-envelope"
              :class="menuButtonClassComputed.group"
              @click="selectGroup(true)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import MenuMixins from "../mixins/MenuMixins";

export default {
  mixins: [MenuMixins],
  components: {},
  data() {
    return {};
  },

  props: {
    bigBar: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Object,
      default: () => ({
        warning: "0",
        station: "0",
        conditionmonitor: "0"
      })
    }
  },
  computed: {
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    },
    menuButtonClassComputed() {
      let menuButtonClass = {
        station: "p-button-text p-button-lg",
        conditionmonitor: "p-button-text p-button-lg",
        warning: "p-button-text p-button-lg",
        group: "p-button-text p-button-lg"
      };

      this.menu.station
        ? (menuButtonClass.station = "p-button p-button-lg p-mt-4")
        : (menuButtonClass.station = "p-button-text p-button-lg p-mt-4");

      this.menu.conditionmonitor
        ? (menuButtonClass.conditionmonitor = "p-button p-button-lg")
        : (menuButtonClass.conditionmonitor = "p-button-text p-button-lg");

      this.menu.warning
        ? (menuButtonClass.warning = "p-button p-button-lg")
        : (menuButtonClass.warning = "p-button-text p-button-lg");

      this.menu.group
        ? (menuButtonClass.group = "p-button p-button-lg")
        : (menuButtonClass.group = "p-button-text p-button-lg");

      return menuButtonClass;
    },
    menuIconStyleComputed() {
      let menuButtonStyle = {
        station: "font-size: 1.5rem;",
        conditionmonitor: "font-size: 1.5rem;",
        warning: "font-size: 1.5rem;",
        group: "font-size: 1.5rem;"
      };

      this.menu.station
        ? (menuButtonStyle.station = "font-size: 1.5rem; color:white;")
        : (menuButtonStyle.station = "font-size: 1.5rem;");

      if (this.menu.conditionmonitor) {
        menuButtonStyle.conditionmonitor = "font-size: 1.5rem; color:white;";
        menuButtonStyle.station = "font-size: 1.5rem; color:white;";
      } else {
        menuButtonStyle.conditionmonitor = "font-size: 1.5rem;";
      }

      this.menu.warning
        ? (menuButtonStyle.warning = "font-size: 1.5rem; color:white;")
        : (menuButtonStyle.warning = "font-size: 1.5rem;");

      this.menu.group
        ? (menuButtonStyle.group = "font-size: 1.5rem; color:white;")
        : (menuButtonStyle.group = "font-size: 1.5rem;");

      return menuButtonStyle;
    }
  },
  watch: {},
  methods: {
    selectStation(val) {
      this.menuTemp.station = val;
      this.menuTemp.conditionmonitor = false;
      this.menuTemp.warning = false;
      this.menuTemp.group = false;
      this.menuComputed = this.menuTemp;
    },
    selectConditionmonitor(val) {
      this.menuTemp.conditionmonitor = val;
      this.menuTemp.station = false;
      this.menuTemp.warning = false;
      this.menuTemp.group = false;
      this.menuComputed = this.menuTemp;
    },
    selectWarning(val) {
      this.menuTemp.warning = val;
      this.menuTemp.station = false;
      this.menuTemp.conditionmonitor = false;
      this.menuTemp.group = false;
      this.menuComputed = this.menuTemp;
    },
    selectGroup(val) {
      this.menuTemp.group = val;
      this.menuTemp.station = false;
      this.menuTemp.conditionmonitor = false;
      this.menuTemp.warning = false;
      this.menuComputed = this.menuTemp;
    }
  }
};
</script>

<style scoped>
.container-center {
  display: flex;
  justify-content: center;
}
</style>