<template>
  <div>
    <div class="p-mt-2 p-mb-2 station-header">Stationen</div>
    <DataView :value="stationListComputed" layout="grid" :paginator="false" :rows="9">
      <template #header>
        <div class="grid grid-nogutter">
          <div class="col-12" style="text-align: left;">
            <DataViewLayoutOptions
              v-model="layout"
              :layout="layout"
              :bigBar="bigBar"
              componentname="Station"
              @addStationEmit="addStationEmit"
              @changeLayoutEmit="changeLayoutEmit"
              @filterEmit="filterEmit"
            />
          </div>
        </div>
      </template>

      <template #grid="slotProps" class="p-grid">
        <div class="p-col-12 p-md-4 p-lg-4">
          <div
            @click="actionSelectStation(slotProps.data)"
            style="background-color: #ffffff; margin: 15px;"
          >
            <div class="grid-item card">
              <div class="station-grid-item-top">
                <div class="station-name">{{slotProps.data.stationname}}</div>

                <div>
                  <span class="menu-icon">
                    <Button
                      icon="pi pi-ellipsis-v"
                      class="p-button-rounded p-button-text"
                      @click="toggleMenu($event, slotProps.data)"
                      aria-haspopup="true"
                      aria-controls="overlay_menu"
                      style="float:right"
                    />
                    <Menu id="overlay_menu" ref="menuStation" :model="menuItems" :popup="true" />
                  </span>
                </div>
              </div>

              <div class="station-grid-item-content">
                <div
                  class="station-description"
                >{{stationAddressGenerator(slotProps.data.stationaddress,slotProps.data.stationlocation)}}</div>
              </div>

              <div class="status">
                <div class="status-padding ok" v-if="slotProps.data.status === 'ok'">
                  <strong>{{slotProps.data.totalapparentpower}} kVA</strong>
                  <i class="pi pi-check" style="float: right"></i>
                </div>

                <div
                  class="status-padding unreachable"
                  v-else-if="slotProps.data.status === 'unreachable' || slotProps.data.status === ''"
                >
                  <strong>{{slotProps.data.totalapparentpower}} kVA</strong>
                  <i class="pi pi-exclamation-triangle" style="float: right"></i>
                </div>

                <div class="status-padding offline" v-else-if="slotProps.data.status === 'offline'">
                  <strong>{{slotProps.data.totalapparentpower}} kVA</strong>
                  <i class="pi pi-exclamation-triangle" style="float: right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>

    <Dialog
      header="Station bearbeiten"
      :visible="showUpdateStation"
      :modal="true"
      :closable="false"
      class="headerClass"
    >
      <UpdateStationComponent
        :stationId="selectedStation.station_id"
        :stationlocation="selectedStation.stationlocation"
        :stationname="selectedStation.stationname"
        :prevstationname="prevSelectedStation.stationname"
        :stationaddress="selectedStation.stationaddress"
        :workgrouplist="selectedStation.workgrouplist"
        @stationEmit="stationEmitUO"
        @locationEmit="locationEmitUO"
        @addressEmit="addressEmitUO"
        @workGroupListEmit="workGroupListEmitUO"
        @saveStationEmit="updateStationEmit"
        @closeAddUpdateStationEmit="closeAddUpdateStationEmit"
      />
    </Dialog>

    <Dialog
      header="Neue Station hinzufügen"
      :visible="showAddStation"
      :modal="true"
      :closable="false"
      class="headerClass"
    >
      <AddStationComponent
        :stationlocation="newStation.stationlocation"
        :stationname="newStation.stationname"
        :stationaddress="newStation.stationaddress"
        :workgrouplist="newStation.workgrouplist"
        @stationEmit="stationEmitCO"
        @locationEmit="locationEmitCO"
        @addressEmit="addressEmitCO"
        @workGroupListEmit="workGroupListEmitCO"
        @saveStationEmit="createStationEmit"
        @closeAddUpdateStationEmit="closeAddUpdateStationEmit"
      />
    </Dialog>

    <Dialog
      header="Löschen bestätigen"
      :visible="showConfirmDeleteStation"
      :style="{width: '50vw'}"
      :closable="false"
    >
      <p>Sind Sie sicher, dass Sie diese Station löschen wollen?</p>
      <p>Mit dieser Aktion werden alle verbundenen Geräte einschließlich der historischen Daten gelöscht.</p>
      <p>Bitte geben Sie das Wort "Löschen!" in das Textfeld ein, um zu bestätigen, dass Sie die Station und alle historischen Daten löschen wollen.</p>
      <InputText v-model="deleteConfirmationText" />
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          @click="showConfirmDeleteStation = false"
          class="p-button-text"
        />
        <Button
          :disabled="deleteDisableComputed"
          label="Ja"
          icon="pi pi-check"
          @click="actionDeleteStation"
          autofocus
        />
      </template>
    </Dialog>

    <ExportDataComponent
      :showExportData="showExportData"
      :historyList="getHistoryExportList"
      :fileName="selectedStation.stationname"
      @showExportDataEmit="showExportDataEmit"
      @exportParameterEmit="exportParameterEmit"
      @cancelEmit="cancelExportEmit"
    />
  </div>
</template>

<script>
import DataViewLayoutOptions from "./DataViewLayoutOptions";
import UpdateStationComponent from "../components/add_update/AddUpdateStationComponent";
import AddStationComponent from "../components/add_update/AddUpdateStationComponent";
import ExportDataComponent from "./ExportDataComponent";

import StationOperationMixins from "../mixins/StationOperationsMixins";

import "../style/stationgrid.css";
import ResizingMixins from "@/mixins/ResizingMixins";

export default {
  mixins: [StationOperationMixins, ResizingMixins],
  components: {
    ExportDataComponent,
    DataViewLayoutOptions,
    UpdateStationComponent,
    AddStationComponent
  },
  data() {
    return {};
  },
  props: {
    layout: {
      type: String,
      default: "grid"
    },
    bigBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>



<style scoped>
.station-header {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #002230;
  margin-left: 20px;
}
</style>
