<template>
  <div>
    <div class="p-grid p-mb-2">
      <div class="p-col" style="margin-left:20px;">
        <h1 class="p-col warning-header">Warnungen</h1>
      </div>

      <div class="p-flex-shrink-0 p-mr-4">
        <Button
          class="warning-delete-all"
          label="Alle Warnungen löschen"
          @click="actionUnknowledgeAllWarning()"
        />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <DataTable
          selectionMode="single"
          stripedRows
          :value="dataWarningComputed"
          class="datatable"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10,25,50]"
          :expandedRows.sync="warningExpandedRows"
        >
          <template #empty>Keine Warnung vorhanden.</template>
          <template #loading>Warnung werden geladen. Bitte warten...</template>

          <Column :expander="true" headerStyle="width: 3%" />
          <Column field="timestampstart" header="Zeitstempel" sortable headerStyle="width: 10%">
            <template
              #body="slotProps"
            >{{dateTimeFormator2NoSecond(new Date(slotProps.data.timestampstart))}}</template>
          </Column>
          <Column field="stationname" header="Station" sortable headerStyle="width: 10%"></Column>
          <Column field="settingLabel" header="Abgang" sortable headerStyle="width: 10%"></Column>
          <Column header="el. Größe" sortable headerStyle="width: 10%">
            <template #body="slotProps">{{nameTransform(slotProps.data.key)}}</template>
          </Column>
          <Column header="max. Wert" sortable headerStyle="width: 10%">
            <template
              #body="slotProps"
            >{{slotProps.data.maximalvalue}}{{" "+unitGenerator(slotProps.data.key)}}</template>
          </Column>
          <Column
            :headerStyle="{'min-width': '4rem', 'text-align': 'center'}"
            :bodyStyle="{'text-align': 'center', overflow: 'visible'}"
          >
            <template #body="slotProps">
              <Button
                type="button"
                class="p-button-text"
                icon="pi pi-trash"
                style="margin-left: 10px;"
                @click="actionAcknowledgeWarning(slotProps.data.warning_id)"
              ></Button>
            </template>
          </Column>

          <template #expansion="slotProps">
            <DataTable
              :showGridlines="false"
              v-if="screenWidthComputed !== 'xl'|| screenWidthComputed == 'xlg'"
              :value="[slotProps.data]"
              class="p-pl-0"
            >
              <Column
                field="projectnumber"
                header="Projektnummer"
                sortable
                headerStyle="width: 10%"
              ></Column>
              <Column field="ordinalnumber" header="Ordinalzahl" sortable headerStyle="width: 10%"></Column>
            </DataTable>
            <div>
              <WarningChart :selectedData="slotProps.data" />
            </div>
          </template>
        </DataTable>
      </div>
    </div>

    <Dialog
      header="Löschen bestätigen"
      :visible="showConfirmUnknowledgeWarning"
      :style="{width: '50vw'}"
      :closable="false"
    >
      <p>Sind Sie sicher, dass Sie diese Warnung löschen wollen?</p>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          @click="showConfirmUnknowledgeWarning = false"
          class="p-button-text"
        />
        <Button label="Ja" icon="pi pi-check" @click="confirmUnknowledgeWarning" autofocus />
      </template>
    </Dialog>

    <Dialog
      header="Löschen bestätigen"
      :visible="showConfirmUnknowledgeAllWarning"
      :style="{width: '50vw'}"
      :closable="false"
    >
      <p>Bitte geben Sie das Wort "Löschen!" in das Textfeld ein, um zu bestätigen, dass Sie alle Warnungen löschen wollen.</p>

      <InputText v-model="deleteConfirmationText" />
      <template #footer>
        <Button
          label="Abrechen"
          @click="showConfirmUnknowledgeAllWarning = false"
          class="p-button-text"
        />
        <Button
          :disabled="deleteDisableComputed"
          label="Löschen"
          class="p-button-danger"
          @click="confirmUnknowledgeAllWarning"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"; //MANDATORY TO IMPORT TO USE VUEX
import WarningChart from "./WarningChartComponent";
import NameTransformMixins from "../mixins/NameTransformMixins";
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";
import ResizingMixins from "../mixins/ResizingMixins";
import UnitGeneratorMixins from "../mixins/UnitGeneratorMixins";
export default {
  mixins: [
    NameTransformMixins,
    DateTimeFormatorMixins,
    ResizingMixins,
    UnitGeneratorMixins
  ],
  components: {
    WarningChart
  },
  data: () => ({
    tableLoading: false,
    warningList: [],
    warningExpandedRows: [],
    showConfirmUnknowledgeWarning: false,
    showConfirmUnknowledgeAllWarning: false,
    deleteConfirmationText: ""
  }),
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    reset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("ConditionMonitorStore", ["getConditionMonitorWarningList"]),
    dataWarningComputed() {
      this.$emit("warningAmountEmit", this.$store.getters.dataWarning.length);
      let dataWarning = this.$store.getters.dataWarning;
      // SORT DATA BY ALPHABET
      dataWarning.sort((a, b) => {
        if (a.timestampstart < b.timestampstart) {
          return 1;
        }
        if (a.timestampstart > b.timestampstart) {
          return -1;
        }
        return 0;
      });
      return dataWarning;
    },
    deleteDisableComputed() {
      return this.deleteConfirmationText == "Löschen!" ? false : true;
    }
  },
  watch: {
    showConfirmUnknowledgeAllWarning(val) {
      if (!val) this.deleteConfirmationText = "";
    },
    warningExpandedRows() {
      if (this.warningExpandedRows.length > 1) this.warningExpandedRows.shift();
    },
    reset(val) {
      if (val) this.warningExpandedRows = [];
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_UNACKNOWLEDGE_WARNING",
      "ACKNOWLEDGE_WARNING_BY_WARNING_ID",
      "ACKNOWLEDGE_ALL_WARNING"
    ]),
    actionUnknowledgeAllWarning() {
      this.showConfirmUnknowledgeAllWarning = true;
    },
    actionAcknowledgeWarning(warning_id) {
      this.selectedWarningId = warning_id;
      this.showConfirmUnknowledgeWarning = true;
    },
    confirmUnknowledgeWarning() {
      this.ACKNOWLEDGE_WARNING_BY_WARNING_ID(this.selectedWarningId).then(
        () => (this.showConfirmUnknowledgeWarning = false)
      );
    },
    confirmUnknowledgeAllWarning() {
      this.ACKNOWLEDGE_ALL_WARNING().then(
        () => (this.showConfirmUnknowledgeAllWarning = false)
      );
    }
  },
  mounted() {
    this.GET_UNACKNOWLEDGE_WARNING();
  }
};
</script>

<style scoped>
::v-deep .p-datatable .p-datatable-thead > tr > th {
  color: #e2e2e2 !important;
  background: #003349 !important;
}

.warning-delete-all {
  background: #003349 !important;
  margin: 40px;
}

.warning-header {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #002230;
  margin: 20px;
}

.datatable {
  margin-right: 10px;
  margin-left: 10px;
}
</style>