// import moment from "moment";

export default {
    methods: {
        getGMTOffset() {
            let date = new Date();
            let dateString = date.toTimeString()
            let dateOffset = dateString.slice(9, 17);
            return dateOffset
        },
        timestampToDateLocale(timestamp) {
            return new Date(timestamp.slice(0, 20))
        },
        dateToLocale(date) {
            let timeZoneOffset = new Date().getTimezoneOffset()
            date.setMinutes(date.getMinutes() - timeZoneOffset)
            return date
        },
        timestampToLocale(timestamp) {
            var date = new Date(timestamp.slice(0, 20))
            return this.dateTimeFormator(date)
        },
        timestampToDateTimeFormator(timestamp) {
            return this.dateTimeFormator(new Date(timestamp.slice(0, 19)))
        },
        timestampToDateTimeFormator2(timestamp) {
            return this.dateTimeFormatorGerman(new Date(timestamp.slice(0, 20)))
        },
        timestampToDateTimeFormator2NoSecond(timestamp) {
            return this.dateTimeFormator2NoSecond(new Date(timestamp.slice(0, 20)))
        },
        dateFormator(date) {
            let ddTemp = date.getDate();
            var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
            let mmTemp = date.getMonth() + 1;
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            var yyyy = date.getFullYear();
            return yyyy + "-" + mm + "-" + dd;
        },
        dateTimeFormatorUTC(date) {
            let ddTemp = date.getUTCDate();
            var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
            let mmTemp = date.getUTCMonth() + 1;
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            var yyyy = date.getFullYear();
            return yyyy + "-" + mm + "-" + dd + " " + this.timeFormatorUTC(date);
        },
        dateTimeFormator(date) {
            let ddTemp = date.getDate();
            var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
            let mmTemp = date.getMonth() + 1;
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            var yyyy = date.getFullYear();
            return yyyy + "-" + mm + "-" + dd + " " + this.timeFormator(date);
        },
        dateTimeFormatorGerman(date) {
            let ddTemp = date.getDate();
            var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
            let mmTemp = date.getMonth() + 1;
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            var yyyy = date.getFullYear();
            return dd + "." + mm + "." + yyyy + " " + this.timeFormator(date);
        },
        dateTimeFormator2NoSecond(date) {
            let ddTemp = date.getDate();
            var dd = ddTemp <= 9 ? "0" + ddTemp : ddTemp;
            let mmTemp = date.getMonth() + 1;
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            var yyyy = date.getFullYear();
            return dd + "." + mm + "." + yyyy + " " + this.timeFormatorNoSecond(date);
        },
        timeFormator(date) {
            // date.toLocaleTimeString()
            let hhTemp = date.getHours();
            var hh = hhTemp <= 9 ? "0" + hhTemp : hhTemp;
            let mmTemp = date.getMinutes();
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            let ssTemp = date.getSeconds();
            var ss = ssTemp <= 9 ? "0" + ssTemp : ssTemp;
            return hh + ":" + mm + ":" + ss;
        },
        timeFormatorUTC(date) {
            // date.toLocaleTimeString()
            let hhTemp = date.getUTCHours();
            var hh = hhTemp <= 9 ? "0" + hhTemp : hhTemp;
            let mmTemp = date.getUTCMinutes();
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            let ssTemp = date.getUTCSeconds();
            var ss = ssTemp <= 9 ? "0" + ssTemp : ssTemp;
            return hh + ":" + mm + ":" + ss;
        },
        timeFormatorNoSecond(date) {
            // date.toLocaleTimeString()
            let hhTemp = date.getHours();
            var hh = hhTemp <= 9 ? "0" + hhTemp : hhTemp;
            let mmTemp = date.getMinutes();
            var mm = mmTemp <= 9 ? "0" + mmTemp : mmTemp;
            return hh + ":" + mm;
        },
        monthConverterByNumber(val) {
            let months = {
                1: "January",
                2: "February",
                3: "March",
                4: "April",
                5: "May",
                6: "June",
                7: "July",
                8: "August",
                9: "September",
                10: "October",
                11: "November",
                12: "December"
            };
            return months[val];
        }
    }
}

