import axios from "../../plugin/axios/axios";
import index from "../index"
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//state
const state = {
    conditionMonitor: {},
    operationMessage: "",
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false,
    },
    conditionMonitorDataHistories: [],
    conditionMonitorDataHistoriesLive: [],
    conditionMonitorData: {},
    conditionMonitorSettingList: [],
    selectedConditionMonitorSetting: {},
    selectedWarning: {},
    warningRuleList: [],
    warningEmail: {
        email: "",
        sendemail: false
    },
    historyExportList: [],
    ongoingWarningList: []
};

// getters
const getters = {
    getOngoingWarningList(state) {
        return state.ongoingWarningList
    },
    getHistoryExportList(state) {
        return state.historyExportList;
    },
    getWarningEmail(state) {
        return state.warningEmail;
    },
    getConditionMonitorSettingList(state) {
        return state.conditionMonitorSettingList;
    },
    getOperationMessage(state) {
        return state.operationMessage;
    },
    getConditionMonitorDataHistories(state) {
        return state.conditionMonitorDataHistories;
    },
    getConditionMonitorDataHistoriesLive(state) {
        return state.conditionMonitorDataHistoriesLive;
    },
    getConditionMonitorWarningList(state) {
        return state.conditionMonitorWarningList;
    },
    getSelectedConditionMonitorSetting(state) {
        return state.selectedConditionMonitorSetting
    },
    getConditionMonitorData(state) {
        return state.conditionMonitorData
    },
    getWarningRuleList(state) {
        return state.warningRuleList;
    },
    getSelectedWarning(state) {
        return state.selectedWarning;
    }
};

// actions
const actions = {
    GET_RULES_BY_PROJECT_AND_ORDINAL_NUMBER({ commit }, data) {
        let dataParameter = {
            projectnumber: data.projectnumber,
            ordinalnumber: data.ordinalnumber
        }
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WARNING_RULE_URL + "/rules-by-project-and-ordinal", dataParameter)
            .then((response) => {
                commit("mutateRuleList", response.data.data);
                return response;
            });
    },
    GET_ALL_CONDITION_MONITOR_SETTINGS({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateConditionMonitorSettingList", response.data.data);
                return response;
            });
    },
    GET_CONDITION_MONITOR_SETTINGS_BY_STATION_ID({ commit }, station_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/station/" + station_id)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateConditionMonitorSettingList", response.data.data);
                return response;
            });
    },
    GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER({ commit }, dataParam) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL, dataParam)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateSelectedConditionMonitorSetting", response.data.data);
                return response;
            });
    },
    GET_CONDITION_MONITOR_DATA_BY_START_END_DATE({ commit }, dataParameter) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_URL + "/histories", dataParameter)
            .then((response) => {
                commit("mutateConditionMonitorDataHistories", response.data.data);
                commit("mutateConditionMonitorDataHistoriesLive", response.data.data);
                return response;
            });
    },
    GET_CONDITION_MONITOR_DATA_BY_DATE({ commit }, dataParameter) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_URL + "/", dataParameter)
            .then((response) => {
                commit("mutateConditionMonitorData", response.data.data);
                return response;
            });
    },
    GET_UNACKNOWLEDGE_WARNING({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warnings/unacknowledge")
            .then((response) => {
                commit("mutateUnacknowledgeWarningList", response.data.data);
                return response;
            });
    },
    GET_WARNING_BY_ID({ commit }, warning_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warning/" + warning_id)
            .then((response) => {
                commit("mutateSelectedWarning", response.data.data);
                return response;
            });
    },
    GET_ONGOING_WARNING({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warning/ongoing/")
            .then((response) => {
                commit("mutateOngoingWarningList", response.data.data);
                return response;
            });
    },
    GET_ONGOING_WARNING_BY_STATION_ID({ commit }, station_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warning/ongoing/" + station_id)
            .then((response) => {
                commit("mutateOngoingWarningList", response.data.data);
                return response;
            });
    },
    GET_CONDITION_MONITOR_INIT_DATA({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/init")
            .then((response) => {
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    GET_TOTAL_APPARENT_POWER_INIT_DATA({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/init/totalapparentpower")
            .then((response) => {
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    ACKNOWLEDGE_WARNING_BY_WARNING_ID({ commit }, warning_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warning/acknowledge/" + warning_id)
            .then((response) => {
                index.state.dataWarning = index.state.dataWarning.filter(warning => warning.warning_id !== warning_id)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    ACKNOWLEDGE_ALL_WARNING({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/warning/acknowledge/all")
            .then((response) => {
                index.state.dataWarning = []
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    EXPORT_CONDITION_MONITOR_DATA_BY_RANGE({ commit }, data) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_URL + "/export/display", data)
            .then((response) => {
                commit("mutateHistoryExportList", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    UPDATE_CONDITION_MONITOR({ commit }, newConditionMonitorSetting) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/update", newConditionMonitorSetting)
            .then((response) => {
                let idxMatch = -1
                state.conditionMonitorSettingList.forEach((conditionMonitorSetting, idx) => {
                    if (conditionMonitorSetting.projectnumber === newConditionMonitorSetting.projectnumber && conditionMonitorSetting.ordinalnumber === newConditionMonitorSetting.ordinalnumber) {
                        idxMatch = idx
                    }
                })
                state.conditionMonitorSettingList.splice(idxMatch, 1)
                state.conditionMonitorSettingList.splice(idxMatch, 0, newConditionMonitorSetting);
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    ADD_CONDITION_MONITOR({ commit }, newConditionMonitor) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/add", newConditionMonitor)
            .then((response) => {
                commit("addConditionMonitor", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    DELETE_CONDITION_MONITOR_BY_ID({ commit }, id) {
        return axios
            .delete(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/delete/" + id)
            .then((response) => {
                commit("mutateConditionMonitorSettingList", state.conditionMonitorSettingList.filter(conditionMonitorSetting => {
                    return conditionMonitorSetting.id !== id
                }))
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    UPDATE_POWER_MONITORING_SETTINGS({ commit }, powerMonitoringSettings) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/update/monitoring/power", powerMonitoringSettings)
            .then((response) => {
                commit("mutateOperationMessage", response.data.message)
                return response;
            })
    },
    UPDATE_MONITORING_MODE({ commit }, monitoringSettings) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/update/monitoring/", monitoringSettings)
            .then((response) => {
                commit("mutateMonitoringMode", monitoringSettings)
                commit("mutateOperationMessage", response.data.message)
                return response;
            })
    },
    UPDATE_CURRENT_MONITORING_SETTINGS({ commit }, currentMonitoringSettings) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_SETTING_URL + "/update/monitoring/current", currentMonitoringSettings)
            .then((response) => {
                commit("mutateOperationMessage", response.data.message)
                return response;
            })
    },
    SELECT_CONDITION_MONITOR({ commit }, selectedConditionMonitorSetting) {
        commit("mutateSelectedConditionMonitorSetting", selectedConditionMonitorSetting)
    },
    clearConditionMonitorStore({ commit }) {
        commit("clear")
    },
    ADD_NEW_RULE({ commit }, newRule) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WARNING_RULE_URL + "/add-rule", newRule)
            .then((response) => {
                commit("addWarningRule", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },

    UPDATE_RULE({ commit }, newRule) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WARNING_RULE_URL + "/update-rule", newRule)
            .then((response) => {
                let idxMatch = -1
                state.warningRuleList.forEach((warningRule, idx) => {
                    if (warningRule.id === newRule.id) {
                        idxMatch = idx
                    }
                })
                state.warningRuleList.splice(idxMatch, 1)
                state.warningRuleList.splice(idxMatch, 0, newRule);
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },

    DELETE_RULE_BY_ID({ commit }, id) {
        return axios
            .delete(process.env.VUE_APP_CONDITION_MONITOR_WARNING_RULE_URL + "/delete/" + id)
            .then((response) => {
                commit("mutateRuleList", state.warningRuleList.filter(rule => {
                    return rule.id !== id
                }))
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
};


// mutations
const mutations = {
    SOCKET_ONOPEN(state, event) {
        Vue.prototype.$socket = event.currentTarget
        state.socket.isConnected = true
        console.log("Socket connected ", event)
    },
    SOCKET_ONCLOSE(state, event) {
        state.socket.isConnected = false
        console.log("Socket closed ", event)
    },
    SOCKET_ONERROR(state, event) {
        console.error(state, event)
    },
    SOCKET_ONMESSAGE(state, message) {
        state.socket.message = message
        let dataParse = JSON.parse(message.data)
        // console.log("====DATA STREAM=====")
        if (dataParse.type === "data") {
            state.data = dataParse.value
        }
    },
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
    mutateHistoryExportList: (state, historyDataList) => {
        state.historyExportList = historyDataList
    },
    mutateWarningEmail: (state, warningEmail) => {
        state.warningEmail = warningEmail;
    },
    mutateConditionMonitorSettingList: (state, conditionMonitorSettingList) => {
        state.conditionMonitorSettingList = conditionMonitorSettingList;
    },
    mutateSelectedConditionMonitorSetting: (state, selectedConditionMonitorSetting) => {
        state.selectedConditionMonitorSetting = selectedConditionMonitorSetting[0];
    },
    mutateConditionMonitorWarningList: (state, conditionMonitorWarningList) => {
        conditionMonitorWarningList.forEach(warning => {
            index.state.dataWarning.push(warning)
        })
    },
    mutateUnacknowledgeWarningList: (state, unacknowledgeWarningList) => {
        index.state.dataWarning = unacknowledgeWarningList;
    },
    mutateSelectedWarning: (state, selectedWarning) => {
        state.selectedWarning = selectedWarning
    },
    addConditionMonitor: (state, conditionMonitorSetting) => {
        state.conditionMonitorSettingList.push(conditionMonitorSetting);
    },
    mutateMonitoringMode: (state, monitoringMode) => {
        state.conditionMonitorSettingList.forEach(setting => {
            if (setting.projectnumber === monitoringMode.projectnumber && setting.ordinalnumber === monitoringMode.ordinalnumber) {
                setting.settingmonitoringmode = monitoringMode.settingmonitoringmode
            }
        })
    },
    mutateOngoingWarningList: (state, ongoingWarningList) => {
        state.ongoingWarningList = ongoingWarningList
    },
    mutateOperationMessage: (state, message) => {
        state.operationMessage = message
    },
    mutateConditionMonitorDataHistories: (state, conditionMonitorDataHistories) => {
        state.conditionMonitorDataHistories = conditionMonitorDataHistories
        state.conditionMonitorDataHistoriesLive = conditionMonitorDataHistories
    },
    mutateConditionMonitorDataHistoriesLive: (state, conditionMonitorDataHistoriesLive) => {
        state.conditionMonitorDataHistoriesLive = [...conditionMonitorDataHistoriesLive]
    },
    mutateConditionMonitorData: (state, conditionMonitorData) => {
        state.conditionMonitorData = conditionMonitorData[0]
    },
    addWarningRule: (state, newRule) => {
        state.warningRuleList.push(newRule);
    },

    mutateRuleList: (state, warningRuleList) => {
        state.warningRuleList = [];
        state.warningRuleList = warningRuleList;
    },

    clear: (state) => {
        state.conditionMonitorList = []
        state.conditionMonitorData = {}
        state.selectedConditionMonitorSetting = {}
        state.operationMessage = ""
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};