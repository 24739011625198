<template>
  <div>
    <div class="p-grid p-ai-center vertical-container p-ml-2">
      <div>Grenzwertanzeige</div>
      <div class="p-ml-2">
        <InputSwitch v-model="monitoringModeComputed" />
      </div>
    </div>

    <div v-if="screenWidthComputed !== 'xl'">
      <TabView class="tabview-custom">
        <!-- L1 -->
        <TabPanel >
          <template #header>
            <span>I1 (A)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l1CurrentMaxLimit"
                type="number"
                min="1"
                v-model="local.l1CurrentMaxLimit"
              />

              <label for="l1CurrentMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l1CurrentMaxTolerance"
                type="number"
                min="1"
                v-model="local.l1CurrentMaxTolerance"
              />

              <label for="l1CurrentMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
        <!-- L2 -->
        <TabPanel >
          <template #header>
            <span>I2 (A)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l2CurrentMaxLimit"
                type="number"
                min="1"
                v-model="local.l2CurrentMaxLimit"
              />

              <label for="l2CurrentMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l2CurrentMaxTolerance"
                type="number"
                min="1"
                v-model="local.l2CurrentMaxTolerance"
              />

              <label for="l2CurrentMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
        <!-- L3 -->
        <TabPanel >
          <template #header>
            <span>I3 (A)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l3CurrentMaxLimit"
                type="number"
                min="1"
                v-model="local.l3CurrentMaxLimit"
              />

              <label for="l3CurrentMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="l3CurrentMaxTolerance"
                type="number"
                min="1"
                v-model="local.l3CurrentMaxTolerance"
              />

              <label for="l3CurrentMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
        <!-- Ln -->
        <TabPanel >
          <template #header>
            <span>In (A)</span>
          </template>
          <div class="p-ml-3">
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="lnCurrentMaxLimit"
                type="number"
                min="1"
                v-model="local.lnCurrentMaxLimit"
              />

              <label for="lnCurrentMaxLimit">Oberer Grenzwert</label>
            </span>
            <span class="p-float-label p-mt-4">
              <InputText
                style="width:100%"
                
                id="lnCurrentMaxTolerance"
                type="number"
                min="1"
                v-model="local.lnCurrentMaxTolerance"
              />
              <label for="lnCurrentMaxTolerance">Schwellwert</label>
            </span>
          </div>
        </TabPanel>
      </TabView>
    </div>

    <div class="p-grid" v-if="screenWidthComputed == 'xl'">
      <div class="p-col p-ml-3">
        <h3>I1 (A)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l1CurrentMaxLimit"
            type="number"
            min="1"
            v-model="local.l1CurrentMaxLimit"
          />

          <label for="l1CurrentMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l1CurrentMaxTolerance"
            type="number"
            min="1"
            v-model="local.l1CurrentMaxTolerance"
          />

          <label for="l1CurrentMaxTolerance">Schwellwert</label>
        </span>
      </div>
      <div class="p-col p-ml-3">
        <h3>I2 (A)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l2CurrentMaxLimit"
            type="number"
            min="1"
            v-model="local.l2CurrentMaxLimit"
          />

          <label for="l2CurrentMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l2CurrentMaxTolerance"
            type="number"
            min="1"
            v-model="local.l2CurrentMaxTolerance"
          />

          <label for="l2CurrentMaxTolerance">Schwellwert</label>
        </span>
      </div>
      <div class="p-col p-ml-3">
        <h3>I3 (A)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l3CurrentMaxLimit"
            type="number"
            min="1"
            v-model="local.l3CurrentMaxLimit"
          />

          <label for="l3CurrentMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="l3CurrentMaxTolerance"
            type="number"
            min="1"
            v-model="local.l3CurrentMaxTolerance"
          />

          <label for="l3CurrentMaxTolerance">Schwellwert</label>
        </span>
      </div>
      <div class="p-col p-ml-3">
        <h3>In (A)</h3>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="lnCurrentMaxLimit"
            type="number"
            min="1"
            v-model="local.lnCurrentMaxLimit"
          />

          <label for="lnCurrentMaxLimit">Oberer Grenzwert</label>
        </span>
        <span class="p-float-label p-mt-4">
          <InputText
            style="width:100%"
            
            id="lnCurrentMaxTolerance"
            type="number"
            min="1"
            v-model="local.lnCurrentMaxTolerance"
          />
          <label for="lnCurrentMaxTolerance">Schwellwert</label>
        </span>
      </div>
    </div>
    <div class="p-grid p-flex p-mt-1 p-ml-2 p-mr-2">
      <Button label="Anwenden" @click="actionApply()" style="width:100%" />
      <Button
        label="Abbrechen"
        @click="applyToLocal()"
        style="width:100%"
        class="p-button-outlined p-mt-1"
      />
    </div>
  </div>
</template>

<script>
import ResizingMixins from "../../mixins/ResizingMixins";
export default {
  mixins: [ResizingMixins],
  data: () => ({
    local: {
      l1CurrentMaxLimit: 0,
      l1CurrentMaxTolerance: 0,
      l2CurrentMaxLimit: 0,
      l2CurrentMaxTolerance: 0,
      l3CurrentMaxLimit: 0,
      l3CurrentMaxTolerance: 0,
      lnCurrentMaxLimit: 0,
      lnCurrentMaxTolerance: 0
    }
  }),
  props: {
    monitoringMode: {
      type: Boolean,
      default: true
    },
    l1CurrentMaxLimit: {
      type: Number,
      default: 300
    },
    l1CurrentMaxTolerance: {
      type: Number,
      default: 1
    },
    l2CurrentMaxLimit: {
      type: Number,
      default: 300
    },
    l2CurrentMaxTolerance: {
      type: Number,
      default: 1
    },
    l3CurrentMaxLimit: {
      type: Number,
      default: 300
    },
    l3CurrentMaxTolerance: {
      type: Number,
      default: 1
    },
    lnCurrentMaxLimit: {
      type: Number,
      default: 300
    },
    lnCurrentMaxTolerance: {
      type: Number,
      default: 1
    }
  },
  computed: {
    monitoringModeComputed: {
      set(val) {
        this.$emit("monitoringModeEmit", val);
      },
      get() {
        return this.monitoringMode;
      }
    }
  },
  methods: {
    actionApply() {
      this.$emit("actionApplyCurrentEmit", this.local);
    },
    applyToLocal() {
      this.local.l1CurrentMaxLimit = this.l1CurrentMaxLimit;
      this.local.l1CurrentMaxTolerance = this.l1CurrentMaxTolerance;
      this.local.l2CurrentMaxLimit = this.l2CurrentMaxLimit;
      this.local.l2CurrentMaxTolerance = this.l2CurrentMaxTolerance;
      this.local.l3CurrentMaxLimit = this.l3CurrentMaxLimit;
      this.local.l3CurrentMaxTolerance = this.l3CurrentMaxTolerance;
      this.local.lnCurrentMaxLimit = this.lnCurrentMaxLimit;
      this.local.lnCurrentMaxTolerance = this.lnCurrentMaxTolerance;
    }
  },
  mounted() {
    this.applyToLocal();
  }
};
</script>