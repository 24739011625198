import { mapActions, mapGetters } from "vuex"; //MANDATORY TO IMPORT TO USE VUEX
import MenuMixins from "./MenuMixins";

export default {
    mixins: [MenuMixins],
    data() {
        return {
            showAddStation: false,
            showUpdateStation: false,
            showConfirmDeleteStation: false,
            showExportData: false,
            deleteConfirmationText: "",
            prevSelectedStation: {},
            selectedStation: {
                station_id: -1,
                stationname: "",
                stationlocation: "",
                stationaddress: "",
                workgrouplist: []
            },
            newStation: {
                station_id: -1,
                stationname: "",
                stationlocation: "",
                stationaddress: "",
                workgrouplist: []
            },
            menuItems: [
                {
                    items: [
                        {
                            label: "Daten Exportieren",
                            icon: "pi pi-download",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.showExportData = true;
                            }
                        },
                        {
                            label: "Bearbeiten",
                            icon: "pi pi-pencil",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.GET_WORK_GROUPS_BY_STATION_ID(
                                    this.selectedStation.station_id
                                ).then(() => {
                                    this.selectedStation.workgrouplist = this.getSelectedStationWorkGroupList
                                });
                                this.showUpdateStation = true;
                            }
                        },
                        {
                            label: "Löschen",
                            icon: "pi pi-trash",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.showConfirmDeleteStation = true;
                            }
                        }
                    ]
                }
            ]
        };
    },
    watch: {
        showConfirmDeleteDevice(val) {
            if (val == false)
                this.deleteConfirmationText = ""
        }
    },
    computed: {
        ...mapGetters("StationStore", ["getStationList", "getHistoryExportList"]),
        ...mapGetters("ConditionMonitorStore", ["getConditionMonitorSettingList", "getOngoingWarningList"]),
        ...mapGetters("WorkGroupStore", ["getSelectedStationWorkGroupList"]),
        stationListComputed() {
            let dataStream = this.$store.getters.dataStream

            dataStream.forEach(stationStream => {
                let tempStation = dataStream.filter(s => {
                    return stationStream.station_id == s.station_id
                })
                // let tempStatus = tempStation.map(tstation => {
                //     return tstation.devicestatus;
                // })
                let tempApparentPower = tempStation.map(tstation => {
                    return tstation.apparentpower;
                })

                // let tempStatus = ['offline', 'offline', 'offline']

                // let status = ""
                // let statuscode;
                let totalApparentPower = tempApparentPower.reduce((accumulator, value) => {
                    return accumulator + parseFloat(value);
                }, 0);

                // if (tempStatus.includes("unreachable") || tempStatus.includes("offline")) {
                //     status = "unreachable"
                //     statuscode = 0
                // } else {
                //     status = "ok"
                //     statuscode = 1
                // }

                // const isOffline = (currentValue) => currentValue == 'offline';
                // if (tempStatus.every(isOffline)) {
                //     status = "offline"
                //     statuscode = 0
                // }

                this.getStationList.forEach(station => {
                    if (stationStream.station_id == station.station_id) {
                        station.status = "ok"
                        station.statuscode = 1
                        station["totalapparentpower"] = totalApparentPower.toFixed(2)
                    }
                })
            })

            // OUTLET COLOR BY WARNING
            this.getOngoingWarningList.forEach(owarning => {
                this.getConditionMonitorSettingList.forEach(cms => {
                    if ((owarning.projectnumber == cms.projectnumber) && (owarning.ordinalnumber == cms.ordinalnumber)) {
                        this.getStationList.forEach(station => {
                            if (station.station_id == cms.station_id) {
                                station.statuscode = 0
                                station.status = "offline"
                            }
                        })
                    }
                })
            })

            // SEARCH FUNCTION
            let filteredData = this.getStationList.filter(d => {
                if (this.filter !== undefined)
                    return d.stationname.includes(this.filter);
                else return d;
            });


            // // // SORT DATA BY STATUS
            filteredData.sort((a, b) => {
                if (a.statuscode < b.statuscode) {
                    return -1;
                }
                if (a.statuscode > b.statuscode) {
                    return 1;
                }
                return 0;
            })

            return filteredData;
        },
        deleteDisableComputed() {
            return this.deleteConfirmationText == "Löschen!" ? false : true
        }
    },
    methods: {
        ...mapActions("StationStore", [
            "ADD_STATION",
            "UPDATE_STATION",
            "DELETE_STATION_BY_ID",
            "GET_ALL_STATIONS",
            "EXPORT_STATION_DATA_BY_RANGE"
        ]),
        ...mapActions("ConditionMonitorStore", ["GET_CONDITION_MONITOR_INIT_DATA", "GET_ONGOING_WARNING", "GET_ALL_CONDITION_MONITOR_SETTINGS"]),
        ...mapActions("WorkGroupStore", ["GET_WORK_GROUPS_BY_STATION_ID"]),
        toggleMenu(event, data) {
            this.$refs.menuStation.toggle(event);
            this.selectedStation = { ...data };
            this.prevSelectedStation = { ...data };
            event.stopPropagation();
        },
        filterEmit(val) {
            this.filter = val
        },
        addStationEmit(val) {
            this.showAddStation = val;
        },
        changeLayoutEmit(val) {
            this.$emit("changeLayoutEmit", val);
        },
        //UPDATE STATION
        locationEmitUO(val) {
            this.selectedStation.stationlocation = val;
        },
        stationEmitUO(val) {
            this.selectedStation.stationname = val;
        },
        addressEmitUO(val) {
            this.selectedStation.stationaddress = val;
        },
        workGroupListEmitUO(val) {
            this.selectedStation.workgrouplist = val;
        },
        //CREATE STATION
        locationEmitCO(val) {
            this.newStation.stationlocation = val;
        },
        stationEmitCO(val) {
            this.newStation.stationname = val;
        },
        addressEmitCO(val) {
            this.newStation.stationaddress = val;
        },
        workGroupListEmitCO(val) {
            this.newStation.workgrouplist = val;
        },
        updateStationEmit() {
            delete this.selectedStation["status"];
            delete this.selectedStation["totalapparentpower"]
            delete this.selectedStation["statuscode"]
            this.selectedStation.workgrouplist.forEach(wg => {
                delete wg["status"];
            })

            this.UPDATE_STATION(this.selectedStation).then(() => {
                this.showUpdateStation = false;
                this.$toast.add({
                    severity: "success",
                    summary: "Station bearbeiten",
                    detail: "Die Konfiguration des Station wurde erfolgreich geändert.",
                    group: "br",
                    life: 3000
                });
                this.initialData()
            });
        },
        createStationEmit() {
            delete this.newStation["status"];
            this.newStation.workgrouplist.forEach(wg => {
                delete wg["status"];
            })

            this.ADD_STATION(this.newStation).then(() => {
                this.showAddStation = false;
                this.$toast.add({
                    severity: "success",
                    summary: "Station hinzufügen",
                    detail: "Die Konfiguration des Station wurde erfolgreich geändert.",
                    group: "br",
                    life: 3000
                });
            });
        },
        closeAddUpdateStationEmit(val) {
            this.selectedStation = {
                projectnumber: "",
                station: "",
                location: "",
                address: "",
                child: [],
                devicestatus: ""
            };
            this.showUpdateStation = val;
            this.showAddStation = val;
        },
        confirmDeleteStation(val) {
            this.selectedStation = val;
            this.showConfirmDeleteStation = true;
        },
        emptySelectedStation() {
            this.selectedStation = {
                projectnumber: "",
                settingStation: "",
                settingLocation: "",
                settingAddress: ""
            };
        },
        actionDeleteStation() {
            this.DELETE_STATION_BY_ID(this.selectedStation.station_id);
            this.showConfirmDeleteStation = false;
        },
        showExportDataEmit(val) {
            this.showExportData = val;
        },
        cancelExportEmit() {
            this.showExportData = false;
        },
        exportParameterEmit(data) {
            data["station_id"] = this.selectedStation.station_id;
            this.EXPORT_STATION_DATA_BY_RANGE(data);
        },
        actionSelectStation(val) {
            this.$emit("selectedStationEmit", val);
            this.menu.conditionmonitor = true;
            this.menu.station = false;
            this.$emit("menuEmit", this.menu);
        },
        getTotalApparentPower(station_id) {
            let temp = this.$store.getters.dataTotalApparentPower.filter(tap => {
                return tap.station_id === station_id.toString();
            });
            if (temp.length !== 0) return temp[0].totalapparentpower;
            else return 0;
        },
        stationAddressGenerator(address, location) {
            if (address == "") return location;
            else if (location == "") return address;
            else if (address == "" && location == "") return "";
            else return address + ", " + location;
        },
        initialData() {
            this.GET_ALL_STATIONS().then(() => {
                this.GET_ONGOING_WARNING()
                this.GET_ALL_CONDITION_MONITOR_SETTINGS()
                this.GET_CONDITION_MONITOR_INIT_DATA()
            });
        }
    },
    mounted() {
        this.initialData()
    }
}