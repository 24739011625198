import { mapActions, mapGetters } from "vuex"; //MANDATORY TO IMPORT TO USE VUEX
import SettingsComponent from "../components/add_update/AddUpdateSettingsComponent";
import ExportDataComponent from "../components/ExportDataComponent";
import DataViewLayoutOptions from "../components/DataViewLayoutOptions";
import MenuMixins from "./MenuMixins";

export default {
    mixins: [MenuMixins],
    components: { SettingsComponent, ExportDataComponent, DataViewLayoutOptions },
    data() {
        return {
            showAddConditionMonitor: false,
            showUpdateConditionMonitor: false,
            showProjectOrdinalMessage: false,
            showConfirmDeleteDevice: false,
            showExportData: false,
            deleteConfirmationText: "",
            filter: "",
            layout: "grid",
            filteredConditionMonitorSettingList: [],
            selectedDevice: {
                projectnumber: "",
                ordinalnumber: "",
                settingLabel: "",
                settingLocation: "",
                settingAddress: "",
                settingStation: ""
            },
            newDevice: {
                projectnumber: "",
                ordinalnumber: null,
                settingLabel: "",
                settingLocation: "",
                settingAddress: "",
                settingStation: ""
            },
            autofillDisable: {
                station: false,
                projectnumber: false,
                location: false,
                address: false
            },
            menuItems: [
                {
                    items: [
                        {
                            label: "Daten Exportieren",
                            icon: "pi pi-download",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.showExportData = true;
                            }
                        },
                        {
                            label: "Bearbeiten",
                            icon: "pi pi-pencil",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.showUpdateConditionMonitor = true;
                            }
                        },
                        {
                            label: "Löschen",
                            icon: "pi pi-trash",
                            command: event => {
                                event.originalEvent.stopPropagation();
                                this.showConfirmDeleteDevice = true;
                            }
                        }
                    ]
                }
            ]

        };
    },
    watch: {
        showConfirmDeleteDevice(val) {
            if (val === false)
                this.deleteConfirmationText = ""
        }
    },
    props: {
        selectedStation: {
            type: Object,
            default: () => ({
                station_id: -1,
                stationname: "",
                stationlocation: "",
                stationaddress: "",
                workgrouplist: []
            })
        }
    },
    computed: {
        //MAP GETTERS ALSO MANDATORY TO RETRIEVE THE DATA
        ...mapGetters("ConditionMonitorStore", [
            "getConditionMonitorSettingList",
            "getHistoryExportList",
            "getOngoingWarningList"
        ]),
        deviceListComputed() {
            let dataStream = this.$store.getters.dataStream
            this.filteredConditionMonitorSettingList.forEach(deviceStream => {
                let tempDevice = []
                if (dataStream == []) {
                    tempDevice = this.getConditionMonitorSettingList.filter(d => {
                        return (deviceStream.projectnumber == d.projectnumber && parseInt(deviceStream.ordinalnumber) == d.ordinalnumber)
                    })
                } else {
                    tempDevice = dataStream.filter(d => {
                        return (deviceStream.projectnumber == d.projectnumber && parseInt(deviceStream.ordinalnumber) == d.ordinalnumber)
                    })
                }

                let tempStatus = tempDevice.map(dstatus => {
                    return dstatus.devicestatus;
                })

                let status = ""
                let statusCode;
                if (tempStatus.includes("unreachable") || tempStatus.includes("offline")) {
                    status = "offline"
                    statusCode = 0
                }
                else {
                    status = "ok"
                    statusCode = 1
                }

                const isOffline = (currentValue) => currentValue == 'offline';
                if (tempStatus.every(isOffline)) {
                    status = "offline"
                    statusCode = 0
                }



                this.filteredConditionMonitorSettingList.forEach(device => {
                    if (deviceStream.projectnumber === device.projectnumber && deviceStream.ordinalnumber === device.ordinalnumber) {
                        device.devicestatus = status
                        device.statusCode = statusCode
                        if (tempDevice.length !== 0) {
                            device["apparentpower"] = tempDevice[0].apparentpower
                            device["reactivepower"] = tempDevice[0].reactivepower
                            device["effectivepower"] = tempDevice[0].effectivepower
                        }
                    }
                })
            })

            // OUTLET COLOR BY WARNING
            this.getOngoingWarningList.forEach(owarning => {
                this.filteredConditionMonitorSettingList.forEach(cms => {
                    if (owarning.projectnumber == cms.projectnumber && owarning.ordinalnumber == cms.ordinalnumber) {
                        cms.statusCode = 0
                        cms.status = "unreachable"
                    }
                })
            })

            // SEARCH FUNCTION
            let filteredData = this.filteredConditionMonitorSettingList.filter(d => {
                if (this.filter !== undefined)
                    return d.settingLabel.includes(this.filter);
                else return d;
            });

            // SORT DATA BY ALPHABET
            filteredData.sort((a, b) => {
                if (a.settingLabel < b.settingLabel) {
                    return -1;
                }
                if (a.settingLabel > b.settingLabel) {
                    return 1;
                }
                return 0;
            })

            // // SORT DATA BY STATUS
            filteredData.sort((a, b) => {
                if (a.statusCode < b.statusCode) {
                    return -1;
                }
                if (a.statusCode > b.statusCode) {
                    return 1;
                }
                return 0;
            })

            return filteredData
        },
        deleteDisableComputed() {
            return this.deleteConfirmationText == "Löschen!" ? false : true
        }
    },
    methods: {
        ...mapActions("ConditionMonitorStore", [
            "UPDATE_CONDITION_MONITOR",
            "ADD_CONDITION_MONITOR",
            "DELETE_CONDITION_MONITOR_BY_ID",
            "GET_TOTAL_APPARENT_POWER_INIT_DATA",
            "GET_CONDITION_MONITOR_INIT_DATA",
            "EXPORT_CONDITION_MONITOR_DATA_BY_RANGE",
            "GET_CONDITION_MONITOR_SETTINGS_BY_STATION_ID",
            "GET_ONGOING_WARNING_BY_STATION_ID",
            "GET_ALL_CONDITION_MONITOR_SETTINGS"
        ]),
        ...mapActions("ConditionMonitorStore", ["GET_CONDITION_MONITOR_INIT_DATA"]),
        actionBack() {
            this.menu.conditionmonitor = false;
            this.menu.station = true;
            this.$emit("menuEmit", this.menu);
        },
        filterEmit(val) {
            this.filter = val
        },
        toggleMenu(event, data) {
            console.log(this.$refs)
            this.$refs.menuDevice.toggle(event);
            this.selectedDevice = { ...data };
            event.stopPropagation();
        },
        changeLayoutEmit(val) {
            this.$emit("changeLayoutEmit", val);
        },
        actionSelectDevice(val) {
            // console.log("====SELECTING OUTLET====")
            this.$emit("selectedDeviceEmit", val);
            this.menu.conditionmonitordetail = true;
            this.menu.conditionmonitor = false;
            this.menu.station = false
            this.$emit("menuEmit", this.menu);
        },
        addDeviceEmit(val) {
            this.showAddConditionMonitor = val;
            this.newDevice.settingStation = this.selectedStation.station_id
        },
        // UPDATE OPERATIONS
        projectNumberEmitUOp(val) {
            let groupedCM = this.availableConditionMonitorListComputed.filter(gcm => {
                return gcm.projectnumber == val;
            });
            if (groupedCM.length > 0) {
                this.selectedDevice.settingStation = groupedCM[0].station;
                this.selectedDevice.settingLocation = groupedCM[0].location;
                this.selectedDevice.settingAddress = groupedCM[0].address;
                this.autofillDisable.station = true;
                this.autofillDisable.location = true;
                this.autofillDisable.address = true;
            } else {
                this.autofillDisable.station = false;
                this.autofillDisable.location = false;
                this.autofillDisable.address = false;
            }
            this.selectedDevice.projectnumber = val;
        },
        ordinalNumberEmitUOp(val) {
            this.selectedDevice.ordinalnumber = val;
        },
        settingLabelEmitUOp(val) {
            this.selectedDevice.settingLabel = val;
        },
        settingLocationEmitUOp(val) {
            this.selectedDevice.settingLocation = val;
        },
        settingAddressEmitUOp(val) {
            this.selectedDevice.settingAddress = val;
        },
        settingStationEmitUOp(val) {
            let groupedCM = this.availableConditionMonitorListComputed.filter(gcm => {
                return gcm.projectnumber == val;
            });
            if (groupedCM.length > 0) {
                this.selectedDevice.settingStation = groupedCM[0].station;
                this.selectedDevice.settingLocation = groupedCM[0].location;
                this.selectedDevice.settingAddress = groupedCM[0].address;
                this.autofillDisable.station = true;
                this.autofillDisable.location = true;
                this.autofillDisable.address = true;
            } else {
                this.autofillDisable.station = false;
                this.autofillDisable.location = false;
                this.autofillDisable.address = false;
            }
            this.selectedDevice.settingStation = val;
        },
        // ADD OPERATIONS
        projectNumberEmitCOp(val) {
            this.newDevice.projectnumber = val;
        },
        ordinalNumberEmitCOp(val) {
            this.newDevice.ordinalnumber = val;
        },
        settingLabelEmitCOp(val) {
            this.newDevice.settingLabel = val;
        },
        settingAddressEmitCOp(val) {
            this.newDevice.settingAddress = val;
        },
        settingLocationEmitCOp(val) {
            this.newDevice.settingLocation = val;
        },
        settingStationEmitCOp(val) {
            let groupedCM = this.availableConditionMonitorListComputed.filter(gcm => {
                return gcm.station == val;
            });
            if (groupedCM.length > 0) {
                this.newDevice.projectnumber = groupedCM[0].projectnumber;
                this.newDevice.settingLocation = groupedCM[0].location;
                this.newDevice.settingAddress = groupedCM[0].address;
                this.autofillDisable.projectnumber = true;
                this.autofillDisable.location = true;
                this.autofillDisable.address = true;
            } else {
                this.autofillDisable.projectnumber = false;
                this.autofillDisable.location = false;
                this.autofillDisable.address = false;
            }
            this.newDevice.settingStation = val;
        },
        createDeviceEmit() {
            if (this.validateAddConditionMonitor()) {
                this.newDevice.station_id = this.selectedStation.station_id
                this.ADD_CONDITION_MONITOR(this.newDevice)
                    .then(() => {
                        this.showAddConditionMonitor = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Gerät hinzufügen",
                            detail:
                                "Das neue Gerät wurde erfolgreich zur Konfiguration hinzugefügt.",
                            group: "br",
                            life: 3000
                        })
                    })
                    .then(() => {
                        this.$emit("askRerenderEmit")
                        this.showProjectOrdinalMessage = false;
                        this.newDevice = this.emptyConditionMonitor();
                    });
            } else {
                this.showProjectOrdinalMessage = true;
            }
        },
        updateDeviceEmit() {
            if (this.validateUpdateConditionMonitor()) {
                let newCM = {
                    id: this.selectedDevice.id,
                    ordinalnumber: this.selectedDevice.ordinalnumber,
                    projectnumber: this.selectedDevice.projectnumber,
                    settingAddress: this.selectedDevice.settingAddress,
                    settingLabel: this.selectedDevice.settingLabel,
                    settingLocation: this.selectedDevice.settingLocation,
                    settingStation: this.selectedDevice.settingStation
                };
                this.UPDATE_CONDITION_MONITOR(newCM).then(() => {
                    this.showUpdateConditionMonitor = false;
                    this.$toast.add({
                        severity: "success",
                        summary: "Gerät bearbeiten",
                        detail: "Die Konfiguration des Gerätes wurde erfolgreich geändert.",
                        group: "br",
                        life: 3000
                    });
                    this.showProjectOrdinalMessage = false;
                    this.selectedDevice = this.emptyConditionMonitor();
                }).then(() => {
                    this.$emit("askRerenderEmit")
                })

            } else {
                this.showProjectOrdinalMessage = true;
            }
        },
        closeAddConditionMonitorEmit() {
            this.newDevice = this.emptyConditionMonitor();
            this.showAddConditionMonitor = false;
            this.showProjectOrdinalMessage = false;

            this.autofillDisable.projectnumber = false;
            this.autofillDisable.station = false;
            this.autofillDisable.false = false;
            this.autofillDisable.address = false;
            this.autofillDisable.location = false;
        },
        closeUpdateConditionMonitorEmit() {
            this.selectedDevice = this.emptyConditionMonitor();
            this.showUpdateConditionMonitor = false;
            this.showProjectOrdinalMessage = false;

            this.autofillDisable.projectnumber = false;
            this.autofillDisable.station = false;
            this.autofillDisable.false = false;
            this.autofillDisable.address = false;
            this.autofillDisable.location = false;
        },
        emptyConditionMonitor() {
            return {
                projectnumber: "",
                ordinalnumber: null,
                settingLabel: "",
                settingLocation: "",
                settingAddress: "",
                settingStation: ""
            };
        },
        deleteEmit() {
            if (this.deleteConfirmationText == "Löschen!") {
                this.DELETE_CONDITION_MONITOR_BY_ID(this.selectedDevice.id)
                    .then(() => {
                        this.$emit("askRerenderEmit")
                        this.showSettingDialog = false;
                        this.showConfirmDeleteDevice = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Gerät entfernen",
                            detail: "Das Gerät wurde erfolgreich aus der Konfiguration entfernt.",
                            group: "br",
                            life: 3000
                        });
                    })

            }

        },
        actionUpdateConditionMonitor() {
            this.showUpdateConditionMonitor = true;
            this.autofillDisable.station = true;
            this.autofillDisable.false = true;
            this.autofillDisable.address = true;
            this.autofillDisable.location = true;
        },

        // VALIDATION
        validateAddConditionMonitor() {
            let filtered = this.filteredConditionMonitorSettingList.filter(cms => {
                return (
                    cms.projectnumber === this.newDevice.projectnumber &&
                    cms.ordinalnumber === this.newDevice.ordinalnumber
                );
            });
            return filtered.length <= 0;
        },
        validateUpdateConditionMonitor() {
            let currentIdx = this.filteredConditionMonitorSettingList
                .map(cms => {
                    return cms.id;
                })
                .indexOf(this.selectedDevice.id);

            let filtered = this.filteredConditionMonitorSettingList.filter((cms, idx) => {
                return (
                    cms.projectnumber === this.selectedDevice.projectnumber &&
                    cms.ordinalnumber === this.selectedDevice.ordinalnumber &&
                    currentIdx !== idx
                );
            });
            if (filtered.length > 0) return false;
            else return true;
        },
        exportEmit() {
            this.showExportData = true;
        },
        cancelExportEmit() {
            this.showExportData = false;
        },
        showExportDataEmit(val) {
            this.showExportData = val;
        },
        emptySelectedConditionMonitor() {
            this.selectedDevice = {
                projectnumber: "",
                ordinalnumber: "",
                settingLabel: "",
                settingLocation: "",
                settingAddress: "",
                settingStation: ""
            };
        },
        exportParameterEmit(data) {
            data["projectnumber"] = this.selectedDevice.projectnumber;
            data["ordinalnumber"] = this.selectedDevice.ordinalnumber;
            this.EXPORT_CONDITION_MONITOR_DATA_BY_RANGE(data);
        }
    },
    mounted() {
        if (Object.keys(this.selectedStation).length !== 0) {
            this.newDevice.station_id = this.selectedStation.station_id
            this.GET_CONDITION_MONITOR_SETTINGS_BY_STATION_ID(
                this.selectedStation.station_id
            ).then(() => {
                this.filteredConditionMonitorSettingList = this.getConditionMonitorSettingList.filter(cmsl => { return cmsl.station_id == this.selectedStation.station_id })
                this.GET_ONGOING_WARNING_BY_STATION_ID(this.selectedStation.station_id)
                this.GET_CONDITION_MONITOR_INIT_DATA(this.selectedStation.station_id);
                this.GET_TOTAL_APPARENT_POWER_INIT_DATA().then(
                    () => (this.tableLoading = false)
                );
            });
        }
    }
}

