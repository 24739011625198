<template>
  <div :class="screenWidthComputed === 'xl' ? 'p-grid' : ''">
    <div :class="screenWidthComputed === 'xl' ? 'p-col-4' : ''">
      <DataTable
        :value="warningDataComputed"
        class="p-mt-2"
        style="border: black 1px solid; width:100%;"
        responsiveLayout="scroll"
        :lazy="true"
      >
        <Column field="name"></Column>
        <Column field="data"></Column>
      </DataTable>
    </div>
    <div :class="screenWidthComputed === 'xl' ? 'p-col-8' : ''">
      <v-chart
        v-if="!chartRedraw"
        ref="echartWarningRef"
        class="chart"
        :option="warningChartComputed"
        style="height:500px;"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChartColor from "../style/ChartColor";
import "echarts";
import VChart from "vue-echarts";
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";
import NameTransformMixins from "../mixins/NameTransformMixins";
import UnitGeneratorMixins from "../mixins/UnitGeneratorMixins";
import ResizingMixins from "../mixins/ResizingMixins";
export default {
  mixins: [
    DateTimeFormatorMixins,
    NameTransformMixins,
    UnitGeneratorMixins,
    ResizingMixins
  ],
  components: { VChart },
  data: () => ({
    chartRedraw: false
  }),
  props: {
    selectedData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    width() {
      // REDRAW THE CHART IF WIDTH CHANGES
      new Promise(resolve => {
        this.chartRedraw = true;
        resolve(false);
      }).then(data => {
        this.chartRedraw = data;
      });
    }
  },
  computed: {
    ...mapGetters("ConditionMonitorStore", [
      "getConditionMonitorDataHistories",
      "getSelectedConditionMonitorSetting",
      "getConditionMonitorData"
    ]),
    warningDataComputed() {
      let warningData = [
        {
          data: "",
          name: "Dauer"
        },
        {
          data: "",
          name: "Maximalwert"
        },
        {
          data: "",
          name: "Grenzwert"
        },
        {
          data: "",
          name: "Schwellwert"
        },
        {
          data: "-",
          name: "Richtung"
        }
      ];

      if (
        this.selectedData.duration === 0 ||
        this.selectedData.duration == null ||
        this.selectedData.duration == undefined
      ) {
        warningData[0].data = "0 min";
      } else {
        warningData[0].data = this.selectedData.duration / 60 + " min";
      }

      warningData[1].data =
        this.selectedData.maximalvalue +
        " " +
        this.unitGenerator(this.selectedData.key);
      warningData[2].data =
        this.selectedData.maxlimit * (this.selectedData.value < 0 ? -1 : 1) +
        " " +
        this.unitGenerator(this.selectedData.key);
      warningData[3].data =
        this.selectedData.maxtolerancelimit *
          (this.selectedData.value < 0 ? -1 : 1) +
        " " +
        this.unitGenerator(this.selectedData.key);

      console.log(this.selectedData);

      // if (Object.keys(this.getSelectedWarning).length !== 0) {
      //   let keyArray = this.getSelectedWarning.key.split(".");
      //   console.log("Key: " + keyArray[0]);
      //   console.log(
      //     "Direction: " + this.getConditionMonitorData[keyArray[0].direction]
      //   );
      //   warningData[4].data = this.getConditionMonitorData[
      //     keyArray[0].direction
      //   ];
      // }

      warningData[4].data = this.selectedData.direction;

      return warningData;
    },
    warningChartComputed() {
      let warningDataChart = [];
      let label = [];
      let maxLimit = [];

      let keyArray = [];
      if (Object.keys(this.selectedData).length !== 0)
        keyArray = this.selectedData.key.split(".");

      let parentKey = keyArray[0];
      let childKey = keyArray[1];

      let startCooldownIdx = -1;
      let maximalValueIdx = -1;
      let endCooldownIdx = -1;

      this.getConditionMonitorDataHistories.forEach((history, idx) => {
        let fullDateTime = this.dateTimeFormator2NoSecond(
          new Date(Date.parse(history.timestamp))
        );

        if (idx === 0) {
          label.push(fullDateTime.slice(0, 16));
        } else {
          label.push(fullDateTime.slice(10, 16));
        }
        maxLimit.push(
          this.selectedData.limit * (this.selectedData.value < 0 ? -1 : 1)
        );

        if (keyArray.length > 1)
          warningDataChart.push(history[parentKey][childKey]);
        else warningDataChart.push(history[parentKey]);

        if (
          warningDataChart[idx] === this.selectedData.value &&
          this.timestampToDateTimeFormator2(history.timestamp).slice(0, 16) ===
            this.dateTimeFormatorGerman(
              new Date(this.selectedData.timestampstart)
            ).slice(0, 16) &&
          startCooldownIdx === -1
        ) {
          startCooldownIdx = idx;
        }

        if (
          this.timestampToDateTimeFormator2(history.timestamp).slice(0, 16) ===
            this.dateTimeFormatorGerman(
              new Date(this.selectedData.timestampend)
            ).slice(0, 16) &&
          endCooldownIdx === -1
        ) {
          endCooldownIdx = idx;
        }

        if (
          warningDataChart[idx] === this.selectedData.maximalvalue &&
          idx >= startCooldownIdx
        ) {
          maximalValueIdx = idx;
        }
      });

      // console.log("FIRST endCooldownIdx: " + endCooldownIdx);
      // IF END OF COOLDOWN HASN'T REACH

      if (endCooldownIdx == -1 || this.selectedData.warning_status !== "done") {
        endCooldownIdx = this.getConditionMonitorDataHistories.length - 1;
      }

      // IF WARNING JUST STARTED
      if (startCooldownIdx == endCooldownIdx) {
        endCooldownIdx = this.getConditionMonitorDataHistories.length;
      }

      let data = {
        height: "400",
        color: [ChartColor.chartColor[parentKey].color],
        tooltip: {
          trigger: "axis"
        },
        grid: {
          left: 70,
          right: 50,
          height: "75%"
        },
        xAxis: {
          data: label,
          name: "Zeit"
        },
        yAxis: {
          type: "value",
          name: this.unitGenerator(this.selectedData.key),
          axisLabel: {
            formatter: "{value} " + this.unitGenerator(this.selectedData.key)
          }
        },
        legend: {
          data: [
            {
              name: this.nameTransform(this.selectedData.key),
              icon: "rect"
            }
          ]
        },
        visualMap: [
          {
            seriesIndex: 0,
            show: false,
            pieces: [
              {
                gt: 0,
                lte: 0,
                color: ChartColor.chartColor[parentKey].color
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          }
        ],
        series: [
          {
            name: this.nameTransform(this.selectedData.key),
            type: "line",
            data: warningDataChart,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis:
                    this.selectedData.maxlimit *
                    (this.selectedData.value < 0 ? -1 : 1)
                },
                {
                  yAxis:
                    this.selectedData.maxtolerancelimit *
                    (this.selectedData.value < 0 ? -1 : 1)
                }
              ]
            },
            markPoint: {
              data: [
                {
                  value: this.selectedData.value,
                  xAxis: startCooldownIdx,
                  yAxis: this.selectedData.value
                }
              ]
            }
          }
        ]
      };

      if (this.selectedData.value < 0) {
        data.visualMap[0].pieces[0].gt =
          this.selectedData.maxtolerancelimit * -1;
        data.visualMap[0].pieces[0].lte = 1000000000;
      } else {
        data.visualMap[0].pieces[0].gt = -1000000000;
        data.visualMap[0].pieces[0].lte = this.selectedData.maxtolerancelimit;
      }

      var dataReturn = JSON.parse(JSON.stringify(data));

      dataReturn = this.settingMaxValueBalloon(
        dataReturn,
        this.selectedData,
        maximalValueIdx
      );

      if (this.selectedData.warning_status == "done" && endCooldownIdx > -1) {
        if (
          this.getConditionMonitorDataHistories[endCooldownIdx] !== undefined
        ) {
          dataReturn = this.settingLastValueBalloon(
            dataReturn,
            this.selectedData,
            this.getConditionMonitorDataHistories[endCooldownIdx],
            endCooldownIdx
          );
        }
      }

      // console.log("startCooldownIdx: " + startCooldownIdx);
      // console.log("endCooldownIdx: " + endCooldownIdx);
      // console.log("maximalValueIdx: " + maximalValueIdx);
      // console.log("==========================================");

      if (
        startCooldownIdx !== -1 &&
        endCooldownIdx !== -1 &&
        startCooldownIdx !== endCooldownIdx &&
        this.selectedData.duration !== 60
      ) {
        dataReturn = this.settingLineAndHighlightColor(
          dataReturn,
          this.selectedData,
          startCooldownIdx,
          endCooldownIdx
        );
      }
      return dataReturn;
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_CONDITION_MONITOR_DATA_BY_START_END_DATE",
      "GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER",
      "GET_CONDITION_MONITOR_DATA_BY_DATE",
      "GET_WARNING_BY_ID"
    ]),
    settingMaxValueBalloon(dataReturn, selectedData, maximalValueIdx) {
      if (maximalValueIdx !== -1) {
        dataReturn.series[0].markPoint.data.push({
          itemStyle: {
            color: "red"
          },
          value: this.selectedData.maximalvalue,
          xAxis: maximalValueIdx,
          yAxis: this.selectedData.maximalvalue
        });
      }
      return dataReturn;
    },
    settingLastValueBalloon(
      dataReturn,
      selectedData,
      endWarningData,
      endValueIdx
    ) {
      if (endValueIdx !== -1 && endValueIdx !== 0) {
        let keyArray = selectedData.key.split(".");
        let parentKey = keyArray[0];
        let childKey = keyArray[1];
        let endValue;
        if (childKey !== undefined) {
          endValue = endWarningData[parentKey][childKey];
        } else {
          endValue = endWarningData[parentKey];
        }
        dataReturn.series[0].markPoint.data.push({
          itemStyle: {
            color: ChartColor.chartColor[parentKey].color
          },
          value: endValue,
          xAxis: endValueIdx,
          yAxis: endValue
        });
      }
      return dataReturn;
    },
    settingLineAndHighlightColor(
      dataReturn,
      selectedData,
      startCooldownIdx,
      endCooldownIdx
    ) {
      let key = selectedData.key.split(".")[0];
      let temp = {
        itemStyle: {
          color: ChartColor.chartColor[key].tranparent
        },
        data: [
          [
            {
              name: "Abklingzeit",
              xAxis: startCooldownIdx
            },
            {
              xAxis: endCooldownIdx
            }
          ]
        ]
      };
      dataReturn.series[0]["markArea"] = temp;
      return dataReturn;
    },
    getInitialData() {
      let addMinusMinutes = 5;
      let dateTimeMinus5 = new Date(this.selectedData.timestampstart);
      let dateTimePlus5 = new Date(this.selectedData.timestampend);

      dateTimeMinus5.setTime(
        dateTimeMinus5.getTime() - addMinusMinutes * 60 * 1000
      );
      if (this.selectedData.warning_status == "done") {
        dateTimePlus5.setTime(
          dateTimePlus5.getTime() + addMinusMinutes * 60 * 1000
        );
      }

      let dataRequestRange = {
        startDate: this.dateTimeFormatorUTC(dateTimeMinus5),
        endDate: this.dateTimeFormatorUTC(dateTimePlus5),
        projectnumber: this.selectedData.projectnumber,
        ordinalnumber: this.selectedData.ordinalnumber
      };

      this.GET_WARNING_BY_ID(this.selectedData.warning_id);

      this.GET_CONDITION_MONITOR_DATA_BY_START_END_DATE(dataRequestRange).then(
        () => {
          let data = {
            projectnumber: this.selectedData.projectnumber,
            ordinalnumber: this.selectedData.ordinalnumber
          };
          this.GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER(data);
        }
      );

      // this.GET_CONDITION_MONITOR_DATA_BY_DATE({
      //   startDate: this.dateTimeFormatorUTC(
      //     new Date(this.selectedData.timestampstart)
      //   ),
      //   endDate: this.dateTimeFormatorUTC(
      //     new Date(this.selectedData.timestampstart)
      //   ),
      //   projectnumber: this.selectedData.projectnumber,
      //   ordinalnumber: this.selectedData.ordinalnumber
      // });
    }
  },
  mounted() {
    this.getInitialData();
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
.ui-datatable tr {
  border-left: none;
}

.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover {
}
</style>