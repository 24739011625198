<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="rule-card" style="margin: 10px;">
          <DataTable
            :value="warnings"
            editMode="row"
            data-key="id"
            :editingRows.sync="editingRowsComputed"
            @row-edit-save="onRowEditSave"
            @row-edit-cancel="onRowEditCancel"
            responsiveLayout="scroll"
            stripedRows
            style="margin: 20px;"
          >
            <Column field="ruleDataPoint" header="el. Größe" :styles="{width:'1%;'}">
              <template #body="slotProps">{{slotProps.data[slotProps.column.field]}}</template>
            </Column>

            <Column field="maxlimit" :styles="{width:'5%;'}" header="Grenzwert">
              <template #editor="slotProps">
                <InputText
                  type="number"
                  v-model="slotProps.data[slotProps.column.field]"
                  autofocus
                  class="p-inputtext-sm"
                  style="width: 80px;"
                />
              </template>
              <template #body="slotProps">
                <span
                  v-if="slotProps.data.maxlimit !== 0 "
                >{{slotProps.data[slotProps.column.field]+" " + getUnit(slotProps.data.ruleDataPoint)}}</span>
              </template>
            </Column>
            <Column :styles="{width:'5%;'}" field="maxtolerancelimit" header="Schwellwert">
              <template #editor="slotProps">
                <InputText
                  type="number"
                  v-model="slotProps.data[slotProps.column.field]"
                  autofocus
                  class="p-inputtext-sm"
                  style="width: 80px;"
                />
              </template>
              <template #body="slotProps">
                <span
                  v-if="slotProps.data.maxtolerancelimit !== 0"
                >{{slotProps.data[slotProps.column.field]+" " + getUnit(slotProps.data.ruleDataPoint)}}</span>
              </template>
            </Column>

            <Column :styles="{width:'1%;'}" field="ruleWarningType" header="Überwachung">
              <template #editor="{ data, field }">
                <Dropdown
                  v-model="data[field]"
                  :options="variants"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select a Status"
                >
                  <template #option="slotProps">{{slotProps.option.label}}</template>
                </Dropdown>
              </template>
              <template #body="{ data, field }">
                <span>{{data[field]}}</span>
              </template>
            </Column>

            <Column
              :styles="{width:'1%;', 'min-width':'4rem'}"
              field="ruleDuration"
              header="Mindestdauer"
            >
              <template #editor="slotProps">
                <span v-if="slotProps.data.ruleWarningType === 'Überschreitungsdauer'">
                  <InputText
                    type="number"
                    min="1"
                    :disabled="slotProps.data.ruleWarningType === 'Überschreitungsdauer' ? false : true "
                    v-model="slotProps.data[slotProps.column.field]"
                    autofocus
                  />
                </span>
              </template>
              <template #body="slotProps">
                <span
                  v-if="slotProps.data.ruleDuration !== '' && slotProps.data.ruleWarningType === 'Überschreitungsdauer'"
                >{{slotProps.data[slotProps.column.field]+" Min"}}</span>
              </template>
            </Column>

            <Column :styles="{width:'1%;'}" field="active" header="Aktiv">
              <template #editor="slotProps">
                <InputSwitch
                  :disabled="slotProps.data.ruleWarningType === ''"
                  v-model="slotProps.data[slotProps.column.field]"
                />
              </template>
              <template #body="slotProps">
                <InputSwitch disabled v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>

            <Column :styles="{width:'1%;'}" field="notify" header="Benachrichtigung">
              <template #editor="slotProps">
                <InputSwitch
                  :disabled="slotProps.data.ruleWarningType === ''"
                  v-model="slotProps.data[slotProps.column.field]"
                />
              </template>
              <template #body="slotProps">
                <InputSwitch disabled v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </Column>

            <Column :rowEditor="true" :styles="{width:'1%;'}" :bodyStyle="{'text-align':'center'}"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      displayOnlyOneRule: false,
      tableLoading: false,
      showAddRule: false,
      showUpdateRule: false,
      newRule: {
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber,
        ruleDataPoint: "",
        ruleWarningType: "",
        ruleDuration: "60"
      },
      selectedRule: {
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber,
        ruleDataPoint: "",
        ruleWarningType: "",
        ruleDuration: "60"
      },
      copySelectedRule: {},
      editingRows: [],
      prevEditingRow: {},
      editingIdx: 0,
      variants: [
        { label: "Keine", value: "Keine" },
        { label: "Schwellwertverletzung", value: "Schwellwertverletzung" },
        { label: "Überschreitungsdauer", value: "Überschreitungsdauer" }
      ],
      warnings: [
        {
          id: -1,
          ruleDataPoint: "S",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -2,
          ruleDataPoint: "P",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -3,
          ruleDataPoint: "Q",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },

        {
          id: -4,
          ruleDataPoint: "I1",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -5,
          ruleDataPoint: "I2",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -6,
          ruleDataPoint: "I3",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -7,
          ruleDataPoint: "IN",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        },
        {
          id: -8,
          ruleDataPoint: "",
          ruleWarningType: "",
          ruleDuration: "",
          maxlimit: "",
          maxtolerancelimit: "",
          notify: false,
          active: false
        }
      ]
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    selectedDevice: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    warnings: {
      deep: true,
      handler(val) {
        if (val[this.editingIdx].ruleWarningType == "Schwellwertverletzung")
          val[this.editingIdx].ruleDuration = 60;
      }
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_RULES_BY_PROJECT_AND_ORDINAL_NUMBER",
      "ADD_NEW_RULE",
      "UPDATE_RULE",
      "DELETE_RULE_BY_ID",
      "UPDATE_POWER_MONITORING_SETTINGS",
      "UPDATE_CURRENT_MONITORING_SETTINGS",
      "GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER"
    ]),
    getUnit(ruleDataPoint) {
      switch (ruleDataPoint) {
        case "S":
          return "kVA";
        case "P":
          return "kW";
        case "Q":
          return "kvar";
        case "I1":
          return "A";
        case "I2":
          return "A";
        case "I3":
          return "A";
        case "IN":
          return "A";
      }
    },
    cancel() {
      this.selectedRule = this.emptyRule();
      this.$emit("closeRuleListEmit");
    },
    addNewRule() {
      this.showAddRule = true;
    },
    updateRule() {
      this.copySelectedRule = Object.assign({}, this.selectedRule);
      this.showUpdateRule = true;
    },
    closeAddRule() {
      this.showAddRule = false;
      this.newRule = this.emptyRule();
    },
    closeUpdateRule() {
      this.projectnumberEmitUOp(this.copySelectedRule.projectnumber);
      this.ordinalnumberEmitUOp(this.copySelectedRule.ordinalnumber);
      this.ruleDataPointEmitUOp(this.copySelectedRule.ruleDataPoint);
      this.ruleWarningTypeEmitUOp(this.copySelectedRule.ruleWarningType);
      this.ruleDurationEmitUOp(this.copySelectedRule.ruleDuration);

      this.showUpdateRule = false;

      this.selectedRule = this.emptyRule();
      this.copySelectedRule = {};
    },

    onRowEditCancel() {
      this.loadInitialData();
    },

    onRowEditSave(event) {
      let { newData, index } = event;
      this.warnings[index] = newData;
      let data = { ...this.warnings[index] };

      if (
        data.ruleDataPoint == "S" ||
        data.ruleDataPoint == "P" ||
        data.ruleDataPoint == "Q"
      ) {
        let dataParameter = {
          projectnumber: this.getSelectedConditionMonitorSetting.projectnumber,
          ordinalnumber: this.getSelectedConditionMonitorSetting.ordinalnumber,
          settingapparentpowermaxlimit: this.getSelectedConditionMonitorSetting
            .settingapparentpowermaxlimit,
          settingapparentpowermaxtolerancelimit: this
            .getSelectedConditionMonitorSetting
            .settingapparentpowermaxtolerancelimit,
          settingreactivepowermaxlimit: this.getSelectedConditionMonitorSetting
            .settingreactivepowermaxlimit,
          settingreactivepowermaxtolerancelimit: this
            .getSelectedConditionMonitorSetting
            .settingreactivepowermaxtolerancelimit,
          settingeffectivepowermaxlimit: this.getSelectedConditionMonitorSetting
            .settingeffectivepowermaxlimit,
          settingeffectivepowermaxtolerancelimit: this
            .getSelectedConditionMonitorSetting
            .settingeffectivepowermaxtolerancelimit
        };

        switch (data.ruleDataPoint) {
          case "S":
            dataParameter.settingapparentpowermaxlimit = parseInt(
              data.maxlimit
            );
            dataParameter.settingapparentpowermaxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
          case "P":
            dataParameter.settingeffectivepowermaxlimit = parseInt(
              data.maxlimit
            );
            dataParameter.settingeffectivepowermaxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
          case "Q":
            dataParameter.settingreactivepowermaxlimit = parseInt(
              data.maxlimit
            );
            dataParameter.settingreactivepowermaxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
        }
        this.UPDATE_POWER_MONITORING_SETTINGS(dataParameter);
      } else {
        let dataParameter = {
          projectnumber: this.getSelectedConditionMonitorSetting.projectnumber,
          ordinalnumber: this.getSelectedConditionMonitorSetting.ordinalnumber,
          settingl1maxlimit: this.getSelectedConditionMonitorSetting
            .settingl1maxlimit,
          settingl1maxtolerancelimit: this.getSelectedConditionMonitorSetting
            .settingl1maxtolerancelimit,
          settingl2maxlimit: this.getSelectedConditionMonitorSetting
            .settingl2maxlimit,
          settingl2maxtolerancelimit: this.getSelectedConditionMonitorSetting
            .settingl2maxtolerancelimit,
          settingl3maxlimit: this.getSelectedConditionMonitorSetting
            .settingl3maxlimit,
          settingl3maxtolerancelimit: this.getSelectedConditionMonitorSetting
            .settingl3maxtolerancelimit,
          settinglnmaxlimit: this.getSelectedConditionMonitorSetting
            .settinglnmaxlimit,
          settinglnmaxtolerancelimit: this.getSelectedConditionMonitorSetting
            .settinglnmaxtolerancelimit
        };
        switch (data.ruleDataPoint) {
          case "I1":
            data.ruleDataPoint = "L1";
            dataParameter.settingl1maxlimit = parseInt(data.maxlimit);
            dataParameter.settingl1maxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
          case "I2":
            data.ruleDataPoint = "L2";
            dataParameter.settingl2maxlimit = parseInt(data.maxlimit);
            dataParameter.settingl2maxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
          case "I3":
            data.ruleDataPoint = "L3";
            dataParameter.settingl3maxlimit = parseInt(data.maxlimit);
            dataParameter.settingl3maxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
          case "IN":
            data.ruleDataPoint = "LN";
            dataParameter.settinglnmaxlimit = parseInt(data.maxlimit);
            dataParameter.settinglnmaxtolerancelimit = parseInt(
              data.maxtolerancelimit
            );
            break;
        }
        this.UPDATE_CURRENT_MONITORING_SETTINGS(dataParameter);
        data.ruleDataPoint = data.ruleDataPoint.toLowerCase();
      }

      delete data.variants;
      delete data.maxlimit;
      delete data.maxtolerancelimit;
      data[
        "projectnumber"
      ] = this.getSelectedConditionMonitorSetting.projectnumber;
      data[
        "ordinalnumber"
      ] = this.getSelectedConditionMonitorSetting.ordinalnumber;
      if (data.id > 0) {
        this.UPDATE_RULE(data).then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Update Rule",
            detail: "Das Regel wurde erfolgreich aktuälisiert.",
            group: "br",
            life: 3000
          });
        });
      } else {
        if (data.ruleWarningType !== "") {
          data.active = true;
          this.warnings[index].active = true;
          if (data.ruleDuration == "") {
            data.ruleDuration = "1";
            this.warnings[index].ruleDuration = "1";
          }

          this.ADD_NEW_RULE(data).then(response => {
            this.warnings[index].id = response.data.data.id;
            this.$toast.add({
              severity: "success",
              summary: "Update Rule",
              detail: "Das Regel wurde erfolgreich hinzufügen.",
              group: "br",
              life: 3000
            });
          });
        }
      }

      this.GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER({
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber
      });
    },

    deleteRuleEmit() {
      this.$confirm.require({
        message: "Sind Sie sicher, dass diese Regel gelösch werden soll?",
        header: "Remove rule",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.DELETE_RULE_BY_ID(this.selectedRule.id);
          this.showAddRule = false;
          this.$toast.add({
            severity: "success",
            summary: "Remove rule",
            detail: "Das Regel wurde erfolgreich gelöscht.",
            group: "br",
            life: 3000
          });
          this.selectedRule = this.emptyRule();
        },
        reject: () => {}
      });
    },

    loadInitialData() {
      this.GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER({
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber
      });

      this.GET_RULES_BY_PROJECT_AND_ORDINAL_NUMBER(
        this.getSelectedConditionMonitorSetting
      ).then(() => {
        this.tableLoading = false;
        this.warnings.forEach((warning, idx) => {
          if (idx == 0) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingapparentpowermaxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingapparentpowermaxtolerancelimit;
          } else if (idx == 1) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingeffectivepowermaxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingeffectivepowermaxtolerancelimit;
          } else if (idx == 2) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingreactivepowermaxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingreactivepowermaxtolerancelimit;
          } else if (idx == 3) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingl1maxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingl1maxtolerancelimit;
          } else if (idx == 4) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingl2maxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingl2maxtolerancelimit;
          } else if (idx == 5) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settingl3maxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settingl3maxtolerancelimit;
          } else if (idx == 6) {
            warning.maxlimit = this.getSelectedConditionMonitorSetting.settinglnmaxlimit;
            warning.maxtolerancelimit = this.getSelectedConditionMonitorSetting.settinglnmaxtolerancelimit;
          }

          warning.ruleWarningType = "";
          warning.active = false;
          warning.notify = false;

          this.getWarningRuleList.forEach(warningRule => {
            switch (warningRule.ruleDataPoint) {
              case "l1":
                warningRule.ruleDataPoint = "I1";
                break;
              case "l2":
                warningRule.ruleDataPoint = "I2";
                break;
              case "l3":
                warningRule.ruleDataPoint = "I3";
                break;
              case "ln":
                warningRule.ruleDataPoint = "IN";
                break;
            }
            if (
              warningRule.ruleDataPoint.toLowerCase() ==
                warning.ruleDataPoint.toLowerCase() ||
              warningRule.ruleDataPoint == warning.ruleDataPoint
            ) {
              warning.id = warningRule.id;
              warning.ruleWarningType = warningRule.ruleWarningType;
              warning.ruleDuration = warningRule.ruleDuration;
              warning.active = warningRule.active;
              warning.notify = warningRule.notify;
            }
          });
        });
      });
    }
  },
  computed: {
    //MAP GETTERS ALSO MANDATORY TO RETRIEVE THE DATA
    ...mapGetters("ConditionMonitorStore", [
      "getWarningRuleList",
      "getSelectedConditionMonitorSetting"
    ]),
    editingRowsComputed: {
      set(val) {
        if (val.length > 0) {
          this.warnings
            .map(warning => warning.id)
            .forEach((id, idx) => {
              if (id == val[0].id) this.editingIdx = idx;
            });
        } else {
          this.editingIdx = 0;
        }
        if (val.length > 1) {
          this.loadInitialData();
          val.shift();
        }
        this.editingRows = val;
        this.prevEditingRow = { ...val[0] };
      },
      get() {
        return this.editingRows;
      }
    },
    ruleListComputed() {
      let data = [];

      this.getWarningRuleList.forEach(warningRule => {
        data.push(warningRule);
      });

      return data;
    },
    enableButtons() {
      return this.selectedRule.ruleDataPoint === "";
    }
  },
  mounted() {
    this.tableLoading = true;
    this.loadInitialData();
  }
};
</script>

<style scoped>
.addNewRule-float {
  position: fixed;
  bottom: 100px;
  right: 80px;
  box-shadow: 2px 2px 3px #999;
}

.rule-card {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

/* ::v-deep .p-datatable .p-datatable-tbody > tr {
  height: 100px;
  margin-top: 2rem;
} */

::v-deep .p-datatable .p-datatable-tbody > tr:nth-child(n + 8) {
  height: 110px;
}

::v-deep .p-datatable .p-datatable-tbody > tr:nth-child(n + 8) > td {
  display: none;
}
</style>