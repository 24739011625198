export default {
    methods: {
        nameTransform(name) {
            let transformedName = ""
            switch (name) {
                case "apparentpower":
                    transformedName = "Scheinleistung"
                    break;
                case "reactivepower":
                    transformedName = "Blindleistung"
                    break;
                case "effectivepower":
                    transformedName = "Wirkleistung"
                    break;
                case "L1.current":
                    transformedName = "I1"
                    break;
                case "L2.current":
                    transformedName = "I2"
                    break;
                case "L3.current":
                    transformedName = "I3"
                    break;
                case "Ln.current":
                    transformedName = "In"
                    break;
                default:
                    transformedName = ""
            }
            return transformedName
        }
    }
}