<template>
  <div id="app" style="background: #ffffff;">
    <div class="p-grid">
      <div
        class="p-pt-3 p-pr-3 p-pl-3"
        :style="bigBar ? 'width: 250px; height: 100vh;' : 'width: 80px; height: 100vh;'"
      >
        <MenuBar
          :menu="menuTemp"
          :bigBar="bigBar"
          :badge="badge"
          @bigBarEmit="bigBarEmit"
          @menuEmit="menuEmit"
        />
      </div>
      <div class="p-col" style="width: 1px;">
        <LoadManagementFlow
          :menu="menuTemp"
          :badge="badge"
          :bigBar="bigBar"
          :askRedraw="askRedraw"
          @askRedrawEmit="askRedrawEmit"
          @badgeEmit="badgeEmit"
          @menuEmit="menuEmit"
        />
      </div>
    </div>
  </div>
</template>

 <script>
import LoadManagementFlow from "./views/LoadManagementFlow";
import MenuBar from "./components/MenuBarComponent";
import ResizingMixins from "./mixins/ResizingMixins";
import MenuMixins from "./mixins/MenuMixins";

export default {
  mixins: [ResizingMixins, MenuMixins],
  name: "app",
  components: {
    LoadManagementFlow,
    MenuBar
  },
  data() {
    return {
      bigBar: false,
      askRedraw: false,
      badge: {
        warning: "0",
        station: "",
        conditionmonitor: ""
      }
    };
  },
  watch: {
    width() {
      this.bigBar = false;
    },
    height() {
      this.bigBar = false;
    }
  },
  methods: {
    bigBarEmit(val) {
      this.bigBar = val;
      new Promise(resolve => {
        this.askRedraw = true;
        resolve(false);
      }).then(data => {
        this.askRedraw = data;
      });
    },
    askRedrawEmit(val) {
      this.askRedraw = val;
    },
    badgeEmit(val) {
      this.badge = val;
    },
    menuEmit(val) {
      this.menuTemp = val;
    }
  },
  mounted() {}
};
</script>


 <style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /*  font-family: "Poppins", sans-serif; */
}

.container {
  width: 1500px;
  border: 1px solid steelblue;
  padding: 30px;
}
</style>

