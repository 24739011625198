import axios from "../../plugin/axios/axios";
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//state
const state = {
    operationMessage: "",
    workGroupList: [],
    workGroupEmailList: [],
    selectedStationWorkGroupList: [],
    selectedWorkGroup: {}
};

// getters
const getters = {
    getWorkGroupList(state) {
        return state.workGroupList;
    },
    getWorkGroupEmailList(state) {
        return state.workGroupEmailList
    },
    getSelectedWorkGroup(state) {
        return state.selectedWorkGroup;
    },
    getSelectedStationWorkGroupList(state) {
        return state.selectedStationWorkGroupList
    }
};

// actions
const actions = {
    GET_ALL_WORK_GROUPS({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL)
            .then((response) => {
                commit("mutateWorkGroupList", response.data.data);
                return response;
            });
    },
    GET_EMAIL_LIST_BY_WORK_GROUP_ID({ commit }, workgroup_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL + "/email/" + workgroup_id)
            .then((response) => {
                commit("mutateWorkGroupEmailList", response.data.data);
                return response;
            });
    },
    GET_WORK_GROUPS_BY_STATION_ID({ commit }, station_id) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL + "/station/" + station_id)
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateSelectedStationWorkGroupList", response.data.data);
                return response;
            });
    },
    ADD_WORKGROUP({ commit }, newStation) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL + "/add", newStation)
            .then((response) => {
                commit("addWorkGroup", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    UPDATE_WORKGROUP({ commit }, newWorkGroup) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL + "/update", newWorkGroup)
            .then((response) => {
                // commit("addStation", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    UPDATE_WORKGROUP_EMAIL_LIST({ commit }, newWorkGroup) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_WORKGROUP_URL + "/update/email", newWorkGroup)
            .then((response) => {
                // commit("addStation", response.data.data)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    SELECT_WORKGROUP({ commit }, selectedWorkGroup) {
        commit("mutateSelectedWorkGroup", selectedWorkGroup)
    },
    clearWorkGroupStore({ commit }) {
        commit("clear")
    }
};


// mutations
const mutations = {
    mutateWorkGroupList: (state, workGroupList) => {
        state.workGroupList = workGroupList
    },
    mutateWorkGroupEmailList: (state, workGroupEmailList) => {
        state.workGroupEmailList = workGroupEmailList
    },
    mutateSelectedStationWorkGroupList: (state, workGroupList) => {
        state.selectedStationWorkGroupList = workGroupList
    },
    addWorkGroup: (state, newWorkGroup) => {
        state.workGroupList.push(newWorkGroup);
    },
    mutateOperationMessage: (state, message) => {
        state.operationMessage = message
    },
    clear: (state) => {
        state.workGroupList = []
        state.selectedWorkGroup = {}
        state.operationMessage = ""
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};