<template>
  <div>
    <div class="p-mt-2 p-mb-2 station-header">Stationen</div>
    <DataTable
      :value="stationListComputed"
      responsiveLayout="scroll"
      selectionMode="single"
      @row-select="actionRowSelect"
      class="datatable"
      stripedRows
    >
      <template #header>
        <DataViewLayoutOptions
          v-model="layout"
          :layout="layout"
          :bigBar="bigBar"
          componentname="Station"
          @addStationEmit="addStationEmit"
          @changeLayoutEmit="changeLayoutEmit"
          @filterEmit="filterEmit"
        />
      </template>
      <Column field="devicestatus" header="Status">
        <template #body="slotProps">
          <div v-if="slotProps.data.status === 'ok'">
            <i class="pi pi-check circle-icon ok"></i>
          </div>

          <div v-else-if="slotProps.data.status === 'unreachable' || slotProps.data.status === ''">
            <i class="pi pi-exclamation-triangle circle-icon unreachable"></i>
          </div>

          <div v-else-if="slotProps.data.status === 'offline'">
            <i class="pi pi-exclamation-triangle circle-icon offline"></i>
          </div>
        </template>
      </Column>
      <Column field="stationname" header="Station"></Column>
      <Column field="stationaddress" header="Adresse"></Column>
      <Column field="stationlocation" header="Ort"></Column>
      <Column header="Scheinleistung">
        <template
          #body="slotProps"
        >{{parseFloat(getTotalApparentPower(slotProps.data.station_id)).toFixed(2)}} kVA</template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-text"
            @click="toggleMenu($event, slotProps.data)"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          />
          <Menu id="overlay_menu" ref="menuStation" :model="menuItems" :popup="true" />
        </template>
      </Column>
    </DataTable>

    <Dialog
      header="Station bearbeiten"
      :visible="showUpdateStation"
      :modal="true"
      :closable="false"
      class="headerClass"
      :containerStyle="{heigh: '1000px'}"
    >
      <UpdateStationComponent
        :stationId="selectedStation.station_id"
        :stationlocation="selectedStation.stationlocation"
        :stationname="selectedStation.stationname"
        :prevstationname="prevSelectedStation.stationname"
        :stationaddress="selectedStation.stationaddress"
        :workgrouplist="selectedStation.workgrouplist"
        @stationEmit="stationEmitUO"
        @locationEmit="locationEmitUO"
        @addressEmit="addressEmitUO"
        @workGroupListEmit="workGroupListEmitUO"
        @saveStationEmit="updateStationEmit"
        @closeAddUpdateStationEmit="closeAddUpdateStationEmit"
      />
    </Dialog>

    <Dialog
      header="Neue Station hinzufügen"
      :visible="showAddStation"
      :modal="true"
      :closable="false"
      class="headerClass"
    >
      <AddStationComponent
        :stationlocation="newStation.stationlocation"
        :stationname="newStation.stationname"
        :stationaddress="newStation.stationaddress"
        :workgrouplist="newStation.workgrouplist"
        @stationEmit="stationEmitCO"
        @locationEmit="locationEmitCO"
        @addressEmit="addressEmitCO"
        @workGroupListEmit="workGroupListEmitCO"
        @saveStationEmit="createStationEmit"
        @closeAddUpdateStationEmit="closeAddUpdateStationEmit"
      />
    </Dialog>

    <Dialog
      header="Löschen bestätigen"
      :visible="showConfirmDeleteStation"
      :style="{width: '50vw'}"
      :closable="false"
    >
      <p>Sind Sie sicher, dass Sie diese Station löschen wollen?</p>
      <p>Mit dieser Aktion werden alle verbundenen Geräte einschließlich der historischen Daten gelöscht.</p>
      <p>Bitte geben Sie das Wort "Löschen!" in das Textfeld ein, um zu bestätigen, dass Sie die Station und alle historischen Daten löschen wollen.</p>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          @click="showConfirmDeleteStation = false"
          class="p-button-text"
        />
        <Button label="Ja" icon="pi pi-check" @click="actionDeleteStation" autofocus />
      </template>
    </Dialog>

    <ExportDataComponent
      :showExportData="showExportData"
      :historyList="getHistoryExportList"
      :fileName="selectedStation.stationname"
      @showExportDataEmit="showExportDataEmit"
      @exportParameterEmit="exportParameterEmit"
      @cancelEmit="cancelExportEmit"
    />
  </div>
</template>

<script>
import StationOperationMixins from "../mixins/StationOperationsMixins";
import DataViewLayoutOptions from "./DataViewLayoutOptions";
import UpdateStationComponent from "../components/add_update/AddUpdateStationComponent";
import AddStationComponent from "../components/add_update/AddUpdateStationComponent";
import ExportDataComponent from "./ExportDataComponent";
export default {
  mixins: [StationOperationMixins],
  components: {
    DataViewLayoutOptions,
    UpdateStationComponent,
    AddStationComponent,
    ExportDataComponent
  },
  data() {
    return {
      layout: "list"
    };
  },
  props: {
    bigBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    }
  },
  methods: {
    actionRowSelect(data) {
      this.$emit("selectedStationEmit", data.data);
      this.menu.conditionmonitor = true;
      this.menu.station = false;
      this.$emit("menuEmit", this.menu);
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped>
.circle-icon {
  padding: 10px;
  border-radius: 50%;
  color: #ffffff;
}

.station-header {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #002230;
  margin: 20px;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  color: #e2e2e2 !important;
  background: #003349 !important;
}
.datatable {
  margin-right: 10px;
  margin-left: 10px;
}
</style>