<template>
  <div>
    <div v-if="screenWidthComputed === 'xlg'" style="height: 250px; width: 650px;">
      <div class="p-grid">
        <div class="p-col-6">
          <label for="settingStation" style="width: 140px">Stationsname</label>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText
                id="settingStation"
                aria-describedby="settingStation-help"
                type="text"
                style="width: 300px ; border-radius: 5px"
                v-model="stationNameComputed"
              />
            </div>
            <small
              id="settingStation-help"
              class="p-invalid"
              v-show="classList.stationClass!==''"
            >{{stationNameWarning}}</small>
          </div>
        </div>

        <div class="p-col-6">
          <label for="settingEmail" style="width: 140px">Email Benachrichtigungen</label>
          <div class="p-field p-grid">
            <div class="p-col">
              <span class="p-fluid" style="width: 300px;border-radius: 5px;">
                <AutoComplete
                  :multiple="true"
                  scrollHeight="100px"
                  forceSelection
                  v-model="workGroupListComputed"
                  :suggestions="filteredWorkGroup"
                  @complete="searchWorkGroup($event)"
                  field="workgroupname"
                />
              </span>
            </div>
          </div>
        </div>

        <div class="p-col-6">
          <label for="settingAddress" style="width: 140px">Adresse</label>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText
                id="settingAddress"
                type="text"
                style="width: 300px ; border-radius: 5px"
                v-model="stationAddressComputed"
              />
            </div>
          </div>
        </div>

        <div class="p-col-6"></div>
        <div class="p-col-6">
          <label for="settingLocation" style="width: 140px">Ort</label>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText
                id="settingLocation"
                type="text"
                style="width: 300px ; border-radius: 5px"
                v-model="stationLocationComputed"
              />
            </div>
          </div>
        </div>

        <div class="p-col-6">
          <div class="p-field p-grid" style="float: right">
            <div class="p-col" style="margin-top:20px;">
              <Button
                label="Abbrechen"
                id="button-remove"
                @click="closeStationDialog"
                style="float:left; color:#000000; background:white;"
              />
              <Button
                label="Speichern"
                id="button-save"
                @click="saveSetting"
                style=" color:#ffffff; background:#003349;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="height: 350px;">
      <div>
        <label for="settingStation" style="width: 140px">Stationsname</label>
        <div class="p-field p-grid p-mt-2">
          <div class="p-col">
            <InputText
              id="settingStation"
              aria-describedby="settingStation-help"
              type="text"
              style="width: 300px ; border-radius: 5px"
              v-model="stationNameComputed"
            />
          </div>
          <small
            id="settingStation-help"
            class="p-invalid"
            v-show="classList.stationClass!==''"
          >{{stationNameWarning}}</small>
        </div>
      </div>

      <div>
        <label for="settingAddress" style="width: 140px">Adresse</label>
        <div class="p-field p-grid p-mt-2">
          <div class="p-col">
            <InputText
              id="settingAddress"
              type="text"
              style="width: 300px ; border-radius: 5px"
              v-model="stationAddressComputed"
            />
          </div>
        </div>
      </div>

      <div>
        <label for="settingLocation" style="width: 140px">Ort</label>
        <div class="p-field p-grid p-mt-2">
          <div class="p-col">
            <InputText
              id="settingLocation"
              type="text"
              style="width: 300px ; border-radius: 5px"
              v-model="stationLocationComputed"
            />
          </div>
        </div>
      </div>

      <div>
        <label for="settingEmail" style="width: 140px">Email Benachrichtigungen</label>
        <div class="p-field p-grid p-mt-2">
          <div class="p-col">
            <span class="p-fluid" style="width: 300px; border-radius: 5px;">
              <AutoComplete
                :multiple="true"
                scrollHeight="100px"
                forceSelection
                v-model="workGroupListComputed"
                :suggestions="filteredWorkGroup"
                @complete="searchWorkGroup($event)"
                field="workgroupname"
              />
            </span>
          </div>
        </div>
      </div>

      <Divider class="p-mt-2" />

      <div class="p-flex p-mt-2">
        <Button
          label="Abbrechen"
          id="button-remove"
          @click="closeStationDialog"
          style="float:left; color:#000000; background:white;"
        />
        <Button
          label="Speichern"
          id="button-save"
          @click="saveSetting"
          style="float: right; color:#ffffff; background:#003349;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResizingMixins from "@/mixins/ResizingMixins"; //MANDATORY TO IMPORT TO USE VUEX
export default {
  mixins: [ResizingMixins],
  components: {},
  data() {
    return {
      filteredWorkGroup: null,
      classList: {
        stationClass: ""
      },
      stationNameWarning: "",
      workGroupOptions: []
    };
  },
  props: {
    stationaddress: {
      default: "",
      type: String
    },
    stationname: {
      default: "",
      type: String
    },
    prevstationname: {
      default: "",
      type: String
    },
    workgrouplist: {
      default: () => [],
      type: Array
    },
    stationlocation: {
      default: "",
      type: String
    }
  },
  watch: {},
  computed: {
    ...mapGetters("WorkGroupStore", ["getWorkGroupList"]),
    ...mapGetters("StationStore", ["getStationList"]),
    workGroupListComputed: {
      set(val) {
        this.$emit("workGroupListEmit", val);
      },
      get() {
        return this.workgrouplist;
      }
    },
    stationAddressComputed: {
      set(val) {
        this.$emit("addressEmit", val);
      },
      get() {
        return this.stationaddress;
      }
    },
    stationLocationComputed: {
      set(val) {
        this.$emit("locationEmit", val);
      },
      get() {
        return this.stationlocation;
      }
    },
    stationNameComputed: {
      set(val) {
        this.$emit("stationEmit", val);
      },
      get() {
        return this.stationname;
      }
    }
  },
  methods: {
    ...mapActions("WorkGroupStore", ["GET_ALL_WORK_GROUPS"]),
    ...mapActions("StationStore", ["GET_ALL_STATIONS"]),
    searchWorkGroup(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredWorkGroup = [...this.getWorkGroupList];
        } else {
          this.filteredWorkGroup = this.getWorkGroupList.filter(workgroup => {
            return workgroup.workgroupname
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    closeStationDialog() {
      this.$emit("closeAddUpdateStationEmit", false);
      this.resetForm();
    },
    validateForm() {
      if (this.stationNameComputed === "") {
        this.classList.stationClass = "p-invalid";
        this.stationNameWarning = "Stationsname darf nicht leer sein.";
      } else {
        this.classList.stationClass = "";
      }

      let indexOfExist = -1;
      if (this.stationNameComputed !== "") {
        let stationNameList = this.getStationList.map(
          station => station.stationname
        );

        if (this.stationNameComputed !== this.prevstationname)
          indexOfExist = stationNameList.indexOf(this.stationNameComputed);

        if (indexOfExist >= 0) {
          this.classList.stationClass = "p-invalid";
          this.stationNameWarning = "Stationsname existiert bereits.";
        } else {
          this.classList.stationClass = "";
        }
      }

      return !(this.stationNameComputed === "") && indexOfExist == -1;
    },
    saveSetting() {
      if (this.validateForm()) {
        this.$emit("saveStationEmit");
        this.resetForm();
      }
    },
    resetForm() {
      this.workGroupListComputed = [];
      this.stationAddressComputed = "";
      this.stationLocationComputed = "";
      this.stationNameComputed = "";
    }
  },
  mounted() {
    this.GET_ALL_WORK_GROUPS();
    this.GET_ALL_STATIONS();
  }
};
</script>


<style scoped>
#settingsContainer {
  width: 480px;
  border: 1px solid black;
}

.settingsBar {
  width: 80px;
  padding: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
}

#box-buttonx {
  width: 20%;
  padding-top: 10px;
  padding-left: 30px;
}

#buttonx {
  background-color: transparent;
  height: 50px;
  font-size: 30px;
  border: none;
  color: grey;
}

.secondBox {
  width: 480px;
  border-width: 0px 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-left: 20px;
  padding-top: 15px;
}

.secondBox span {
  background: blue;
}

.dropdownBar {
  width: 240px;
  height: 35px;
  border-radius: 5px;
}

.buttonBox {
  width: 480px;
  border-width: 0px 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

#button-danger {
  width: 120px;
  margin-right: 30px;
}

#button-remove {
  width: 120px;
  margin-right: 30px;
}

#button-save {
  width: 120px;
}
</style>