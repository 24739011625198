export default {
    data: () => ({
        width: window.innerWidth,
        height: window.innerHeight
    }),
    computed: {
        screenWidthComputed() {
            // console.log("width: " + this.width)
            // console.log("height: " + this.height)
            // console.log("======")
            if (this.width <= 640) {
                return "sm";
            } else if (this.width > 640 && this.width <= 720) {
                return "md";
            } else if (this.width > 720 && this.width <= 960) {
                return "lg";
            } else if (this.width > 960 && this.width <= 1230) {
                return "xlg"
            }
            else {
                return "xl";
            }
        }
    },
    methods: {
        onResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    }

}


