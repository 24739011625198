<template>
  <div>
    <!-- TOP   -->
    <!-- <div v-if="screenWidthComputed === 'xl'" class="p-col-3">
        <div class="p-grid p-jc-start p-ai-center">
          <h1 class="p-ml-2">Lastgang</h1>
          <Button
            icon="pi pi-cog"
            @click="showMonitoringSettingComputed = !showMonitoringSettingComputed"
            class="p-button-rounded p-button-text p-mt-2 p-ml-2"
          />
        </div>
    </div>-->
    <!-- START END DATE -->
    <div class="p-grid">
      <div class="p-col-12">
        <div class="data-card" style="margin: 10px;">
          <div class="p-col p-mt-3 p-mr-2">
            <div class="p-grid p-jc-end p-fluid">
              <div class="p-col" style="margin-left: 10px;">
                <div class="field-radiobutton">
                  <RadioButton inputId="power" name="Leistungen" value="power" v-model="chartMode" />
                  <label class="p-ml-5" for="power">Leistungen</label>
                </div>

                <div class="field-radiobutton p-mt-4">
                  <RadioButton inputId="current" name="Ströme" value="current" v-model="chartMode" />
                  <label class="p-ml-5" for="current">Ströme</label>
                </div>

                <div class="p-grid p-mt-4">
                  <InputSwitch v-model="monitoringMode" />
                  <div class="p-ml-2 p-as-center">Grenzwertanzeige</div>
                </div>
              </div>

              <Divider layout="vertical" />

              <div class="p-col-9">
                <div class="p-grid" v-if="chartMode==='power'">
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.power.Scheinleistung" />
                      <div class="p-ml-2 p-as-center">Scheinleistung</div>
                    </div>
                  </div>
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.power.Blindleistung" />
                      <div class="p-ml-2 p-as-center">Blindleistung</div>
                    </div>
                  </div>
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.power.Wirkleistung" />
                      <div class="p-ml-2 p-as-center">Wirkleistung</div>
                    </div>
                  </div>
                </div>

                <div class="p-grid" v-if="chartMode==='current'">
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.current.I1" />
                      <div class="p-ml-2 p-as-center">I1</div>
                    </div>
                  </div>
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.current.I2" />
                      <div class="p-ml-2 p-as-center">I2</div>
                    </div>
                  </div>
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.current.I3" />
                      <div class="p-ml-2 p-as-center">I3</div>
                    </div>
                  </div>
                  <div class="p-col">
                    <div class="p-grid p-mt-2">
                      <InputSwitch v-model="prevSelectedLegend.current.In" />
                      <div class="p-ml-2 p-as-center">In</div>
                    </div>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-field p-col">
                    <label class="p-mr-2" for="startDate">Start</label>
                    <Calendar
                      id="startDate"
                      style="height:55px;width:100%;"
                      :showIcon="screenWidthComputed!=='sm'"
                      v-model="chartStartDate"
                      :manualInput="false"
                      :showButtonBar="true"
                      :yearNavigator="true"
                      placeholder="Start"
                      :showTime="true"
                      :disabled="liveDataStatus"
                    />
                  </div>
                  <div class="p-field p-col">
                    <label class="p-mr-2" for="endDate">Ende</label>
                    <Calendar
                      id="endDate"
                      style="height:55px"
                      :showIcon="screenWidthComputed!=='sm'"
                      v-model="chartEndDate"
                      placeholder="Ende"
                      :manualInput="false"
                      :showButtonBar="true"
                      :yearNavigator="true"
                      :showTime="true"
                      :disabled="liveDataStatus"
                    />
                  </div>
                  <div class="p-grid p-as-center p-mr-3 p-ml-2 p-mt-2">
                    <InputSwitch v-model="liveDataStatus" />
                    <div class="p-ml-2 p-as-center">Live</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PowerMonitoringSettingComponent
      class="p-mb-2"
      v-if="showMonitoringSettingComputed && activeTab === 0"
      :monitoringMode="monitoringMode"
      :apparentPowerMaxLimit="getSelectedConditionMonitorSetting.settingapparentpowermaxlimit"
      :apparentPowerMaxTolerance="getSelectedConditionMonitorSetting.settingapparentpowermaxtolerancelimit"
      :reactivePowerMaxLimit="getSelectedConditionMonitorSetting.settingreactivepowermaxlimit"
      :reactivePowerMaxTolerance="getSelectedConditionMonitorSetting.settingreactivepowermaxtolerancelimit"
      :effectivePowerMaxLimit="getSelectedConditionMonitorSetting.settingeffectivepowermaxlimit"
      :effectivePowerMaxTolerance="getSelectedConditionMonitorSetting.settingeffectivepowermaxtolerancelimit"
      @monitoringModeEmit="monitoringModeEmit"
      @actionApplyPowerEmit="actionApplyPowerEmit"
    />

    <CurrentMonitoringSettingComponent
      class="p-mb-2"
      v-if="showMonitoringSettingComputed && activeTab === 1"
      :monitoringMode="monitoringMode"
      :l1CurrentMaxLimit="getSelectedConditionMonitorSetting.settingl1maxlimit"
      :l1CurrentMaxTolerance="getSelectedConditionMonitorSetting.settingl1maxtolerancelimit"
      :l2CurrentMaxLimit="getSelectedConditionMonitorSetting.settingl2maxlimit"
      :l2CurrentMaxTolerance="getSelectedConditionMonitorSetting.settingl2maxtolerancelimit"
      :l3CurrentMaxLimit="getSelectedConditionMonitorSetting.settingl3maxlimit"
      :l3CurrentMaxTolerance="getSelectedConditionMonitorSetting.settingl3maxtolerancelimit"
      :lnCurrentMaxLimit="getSelectedConditionMonitorSetting.settinglnmaxlimit"
      :lnCurrentMaxTolerance="getSelectedConditionMonitorSetting.settinglnmaxtolerancelimit"
      @monitoringModeEmit="monitoringModeEmit"
      @actionApplyCurrentEmit="actionApplyCurrentEmit"
    />

    <div class="p-grid">
      <div class="p-col">
        <div class="chart-card" style="margin: 10px;">
          <div v-if="chartMode === 'power'">
            <div v-if="!chartRedraw">
              <VChart
                ref="echartPowerRef"
                :option="powerChartFilteredComputed"
                style="margin: 5px;height:500px;width: auto;"
                @zr:click="actionPowerZrClick"
              />
            </div>
          </div>

          <div v-if="chartMode === 'current'">
            <div v-if="!chartRedraw">
              <VChart
                ref="echartCurrentRef"
                :option="currentChartFilteredComputed"
                style="margin: 5px;height:500px;width: auto;"
                @zr:click="actionCurrentZrClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "echarts";
import PowerMonitoringSettingComponent from "./monitoring/PowerMonitoringSettingComponent";
import CurrentMonitoringSettingComponent from "./monitoring/CurrentMonitoringSettingComponent";
import ResizingMixins from "../mixins/ResizingMixins";
import DateTimeFormatorMixins from "../mixins/DateTimeFormatorMixins";

export default {
  mixins: [ResizingMixins, DateTimeFormatorMixins],
  components: {
    PowerMonitoringSettingComponent,
    CurrentMonitoringSettingComponent
  },
  data: () => ({
    chartMode: "power",
    monitoringMode: false,
    chartRedraw: false,
    legendPower: {
      apparentpower: false,
      reactivepower: false,
      effectivepower: false
    },
    powerMonitoringSetting: {
      apparentPower: {
        maximalLimit: 0,
        maxtolerance: 0
      },
      reactivePower: {
        maximalLimit: 0,
        maxtolerance: 0
      },
      effectivePower: {
        maximalLimit: 0,
        maxtolerance: 0
      }
    },
    currentMonitoringSetting: {
      L1: {
        maximalLimit: 0,
        maxtolerance: 0
      },
      L2: {
        maximalLimit: 0,
        maxtolerance: 0
      },
      L3: {
        maximalLimit: 0,
        maxtolerance: 0
      },
      Ln: {
        maximalLimit: 0,
        maxtolerance: 0
      }
    },
    activeTab: 0,
    showMonitorSetting: false,
    liveDataStatus: false,
    chartStartDate: new Date(),
    chartEndDate: new Date(),
    classBtnLiveData: "p-button-rounded p-button-outlined p-ml-2",
    classBtnWarnings: "p-button-rounded p-button-outlined p-ml-2",
    maxDateEndDate: new Date(),
    prevStartEndDate: {
      startDate: new Date(),
      endDate: new Date()
    },
    prevSelectedLegend: {
      current: {
        I1: true,
        I2: false,
        I3: false,
        In: false
      },
      power: {
        Scheinleistung: true,
        Blindleistung: false,
        Wirkleistung: false
      }
    },
    myPowerEChart: null
  }),
  watch: {
    askRedraw(val) {
      if (val) {
        this.actionRedrawChart();
      }
    },
    chartMode() {
      this.actionRedrawChart();
    },
    width() {
      this.actionRedrawChart();
    },
    chartStartDate() {
      this.GET_CONDITION_MONITOR_DATA_BY_START_END_DATE({
        startDate: this.dateTimeFormatorUTC(this.chartStartDate),
        endDate: this.dateTimeFormatorUTC(this.chartEndDate),
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber
      });
    },
    chartEndDate() {
      if (this.chartEndDate.getDate() < this.chartStartDate.getDate()) {
        this.chartStartDate = this.chartEndDate;
      }
      this.GET_CONDITION_MONITOR_DATA_BY_START_END_DATE({
        startDate: this.dateTimeFormatorUTC(this.chartStartDate),
        endDate: this.dateTimeFormatorUTC(this.chartEndDate),
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber
      });
    },
    selectedDevice: {
      handler() {
        if (this.liveDataStatus) {
          this.classBtnLiveData = "p-button-rounded p-button-danger p-ml-2";
          this.delay(500).then(() => {
            this.classBtnLiveData = "p-button-rounded p-button-warning p-ml-2";
          });
        } else {
          this.classBtnLiveData = "p-button-rounded p-button-outlined p-ml-2";
        }
      },
      deep: true
    },
    liveDataStatus(val) {
      if (val) {
        this.classBtnLiveData = "p-button-rounded p-button-warning p-ml-2";
        this.prevStartEndDate.startDate = this.chartStartDate;
        this.prevStartEndDate.endDate = this.chartEndDate;

        let now = new Date();
        const nowMs = now.getTime();
        const twoHoursMs = 60 * 60 * 1000 * 2;
        let lastTwoHours = new Date(nowMs - twoHoursMs);
        this.chartEndDate = new Date();

        this.chartStartDate = lastTwoHours;
      } else {
        this.classBtnLiveData = "p-button-rounded p-button-outlined p-ml-2";
        this.chartStartDate = this.prevStartEndDate.startDate;
        this.chartEndDate = this.prevStartEndDate.endDate;
      }
    },
    monitoringMode() {
      let monitoringSetting = {
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber,
        settingmonitoringmode: this.monitoringMode
      };
      if (!this.monitoringMode) this.chartRedraw = true;
      this.UPDATE_MONITORING_MODE(monitoringSetting).then(() => {
        this.chartRedraw = false;
      });
    }
  },
  props: {
    selectedDevice: {
      type: Object,
      default: () => ({})
    },
    showMonitoringSetting: {
      type: Boolean,
      default: false
    },
    bigBar: {
      type: Boolean,
      default: false
    },
    askRedraw: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("ConditionMonitorStore", [
      "getConditionMonitorDataHistories",
      "getConditionMonitorDataHistoriesLive",
      "getSelectedConditionMonitorSetting"
    ]),
    showMonitoringSettingComputed: {
      set(val) {
        this.$emit("showMonitoringSettingEmit", val);
      },
      get() {
        return this.showMonitoringSetting;
      }
    },
    bigBarComputed: {
      set(val) {
        this.$emit("bigBarEmit", !val);
      },
      get() {
        return this.bigBar;
      }
    },
    currentChartComputed() {
      let dataCurrentL1A = [];
      let dataCurrentL1B = [];

      let dataCurrentL2A = [];
      let dataCurrentL2B = [];

      let dataCurrentL3A = [];
      let dataCurrentL3B = [];

      let dataCurrentLn = [];

      let label = [];

      let conditionMonitorArray = this.liveDataStatus
        ? this.getConditionMonitorDataHistoriesLive
        : this.getConditionMonitorDataHistories;

      conditionMonitorArray.forEach(history => {
        let date = new Date(history.timestamp.slice(0, 20));
        let fullDate =
          date.getDate() +
          "." +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
        let fullTime = date.toLocaleTimeString("de", {
          timeStyle: "short",
          hour12: false,
          timeZone: "CET"
        });

        label.push(fullDate + " " + fullTime);

        // console.log(history.L1.direction + ":" + history.L1.current);
        if (history.L1.direction === "A") {
          dataCurrentL1A.push(parseInt(history.L1.current));
          dataCurrentL1B.push(null);
        } else if (history.L1.direction === "B") {
          dataCurrentL1B.push(parseInt(history.L1.current));
          dataCurrentL1A.push(null);
        } else {
          dataCurrentL1A.push(null);
          dataCurrentL1B.push(null);
        }

        if (history.L2.direction === "A") {
          dataCurrentL2A.push(parseInt(history.L2.current));
          dataCurrentL2B.push(null);
        } else if (history.L2.direction === "B") {
          dataCurrentL2B.push(parseInt(history.L2.current));
          dataCurrentL2A.push(null);
        } else {
          dataCurrentL2A.push(null);
          dataCurrentL2B.push(null);
        }

        if (history.L3.direction === "A") {
          dataCurrentL3A.push(parseInt(history.L3.current));
          dataCurrentL3B.push(null);
        } else if (history.L3.direction === "B") {
          dataCurrentL3B.push(parseInt(history.L3.current));
          dataCurrentL3A.push(null);
        } else {
          dataCurrentL3A.push(null);
          dataCurrentL3B.push(null);
        }

        dataCurrentLn.push(parseInt(history.Ln.current));
      });

      let dataReturn = {
        height: "600",
        tooltip: {
          trigger: "axis"
        },
        color: ["#A52A2A", "#000000", "#8B8784", "#0000FF"],
        legend: {
          data: [
            {
              name: "I1",
              icon: "rect"
            },
            {
              name: "I2",
              icon: "rect"
            },
            {
              name: "I3",
              icon: "rect"
            },
            {
              name: "In",
              icon: "rect"
            }
          ],
          selected: this.prevSelectedLegend.current
        },
        visualMap: [
          {
            seriesIndex: 0,
            show: false,
            pieces: [
              {
                gt: -1000000000,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl1maxtolerancelimit,
                color: "#A52A2A"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 1,
            show: false,
            pieces: [
              {
                gt: -1000000000,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl2maxtolerancelimit,
                color: "#000000"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 2,
            show: false,
            pieces: [
              {
                gt: -1000000000,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl3maxtolerancelimit,
                color: "#8B8784"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 3,
            show: false,
            pieces: [
              {
                gt: -1000000000,
                lte: this.getSelectedConditionMonitorSetting
                  .settinglnmaxtolerancelimit,
                color: "#0000FF"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 4,
            show: false,
            pieces: [
              {
                gt: 0,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl1maxtolerancelimit,
                color: "#A52A2A"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 5,
            show: false,
            pieces: [
              {
                gt: 0,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl2maxtolerancelimit,
                color: "#000000"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 6,
            show: false,
            pieces: [
              {
                gt: 0,
                lte: this.getSelectedConditionMonitorSetting
                  .settingl3maxtolerancelimit,
                color: "#8B8784"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 7,
            show: false,
            pieces: [
              {
                gt: 0,
                lte: this.getSelectedConditionMonitorSetting
                  .settinglnmaxtolerancelimit,
                color: "#0000FF"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          }
        ],
        grid: [
          {
            left: 60,
            right: 50,
            height: "35%"
          },
          {
            left: 60,
            right: 50,
            top: "58%",
            height: "35%"
          }
        ],
        xAxis: [
          {
            boundaryGap: false,
            axisLine: { onZero: true },
            data: label,
            name: "Zeit"
          },
          {
            gridIndex: 1,
            boundaryGap: false,
            axisLine: { onZero: true },
            data: label,
            name: "Zeit",
            position: "top"
          }
        ],
        yAxis: [
          {
            name: "A Richtung",
            type: "value",
            axisLabel: {
              formatter: "{value} A"
            }
          },
          {
            gridIndex: 1,
            name: "B Richtung",
            type: "value",
            inverse: true,
            axisLabel: {
              formatter: "{value} A"
            }
          }
        ],
        series: [
          {
            name: "I1",
            type: "line",
            data: dataCurrentL1A,
            xAxisIndex: 0,
            yAxisIndex: 0,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl1maxtolerancelimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl1maxlimit
                }
              ]
            }
          },
          {
            name: "I2",
            type: "line",
            data: dataCurrentL2A,
            xAxisIndex: 0,
            yAxisIndex: 0,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl2maxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl2maxtolerancelimit
                }
              ]
            }
          },
          {
            name: "I3",
            type: "line",
            data: dataCurrentL3A,
            xAxisIndex: 0,
            yAxisIndex: 0,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl3maxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl3maxtolerancelimit
                }
              ]
            }
          },
          {
            name: "In",
            type: "line",
            data: dataCurrentLn,
            xAxisIndex: 0,
            yAxisIndex: 0,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settinglnmaxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settinglnmaxtolerancelimit
                }
              ]
            }
          },
          {
            name: "I1",
            type: "line",
            data: dataCurrentL1B,
            xAxisIndex: 1,
            yAxisIndex: 1,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl1maxtolerancelimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl1maxlimit
                }
              ]
            }
          },
          {
            name: "I2",
            type: "line",
            data: dataCurrentL2B,
            xAxisIndex: 1,
            yAxisIndex: 1,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl2maxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl2maxtolerancelimit
                }
              ]
            }
          },
          {
            name: "I3",
            type: "line",
            data: dataCurrentL3B,
            xAxisIndex: 1,
            yAxisIndex: 1,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl3maxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingl3maxtolerancelimit
                }
              ]
            }
          }
        ]
      };

      for (let h = 1; h <= 3; h++) {
        if (
          this.getSelectedConditionMonitorSetting[
            "settingl" + h + "maxtolerancelimit"
          ] == 0 ||
          this.getSelectedConditionMonitorSetting[
            "settingl" + h + "maxlimit"
          ] == 0
        ) {
          delete dataReturn.series[h - 1].markLine;
          delete dataReturn.visualMap[h - 1];
        }
      }

      if (
        this.getSelectedConditionMonitorSetting["settinglnmaxtolerancelimit"] ==
          0 ||
        this.getSelectedConditionMonitorSetting["settinglnmaxlimit"] == 0
      ) {
        delete dataReturn.series[3].markLine;
        delete dataReturn.visualMap[3];
      }

      for (let i = 1; i <= 3; i++) {
        if (
          this.getSelectedConditionMonitorSetting[
            "settingl" + i + "maxtolerancelimit"
          ] == 0 ||
          this.getSelectedConditionMonitorSetting[
            "settingl" + i + "maxlimit"
          ] == 0
        ) {
          delete dataReturn.series[i + 3].markLine;
          delete dataReturn.visualMap[i + 3];
        }
      }
      return dataReturn;
    },
    powerChartComputed() {
      let apparentPower = [];
      let reactivePower = [];
      let effectivePower = [];
      let label = [];

      let conditionMonitorArray = this.liveDataStatus
        ? this.getConditionMonitorDataHistoriesLive
        : this.getConditionMonitorDataHistories;

      conditionMonitorArray.forEach(history => {
        let date = new Date(history.timestamp.slice(0, 20));
        let fullDate =
          date.getDate() +
          "." +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
        let fullTime = date.toLocaleTimeString("de", {
          timeStyle: "short",
          hour12: false,
          timeZone: "CET"
        });

        label.push(fullDate + " " + fullTime);

        apparentPower.push(parseInt(history.apparentpower));
        reactivePower.push(parseInt(history.reactivepower));
        effectivePower.push(parseInt(history.effectivepower));
      });

      let data = {
        height: "400",
        tooltip: {
          trigger: "axis"
        },
        color: ["#30D5C8", "#71C562", "#0000FF"],
        grid: {
          left: 70,
          right: 50,
          height: "75%"
        },
        xAxis: {
          boundaryGap: false,
          axisLine: { onZero: true },
          data: label,
          name: "Zeit"
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} kW"
          }
        },
        legend: {
          data: [
            {
              name: "Scheinleistung",
              icon: "rect"
            },
            {
              name: "Blindleistung",
              icon: "circle"
            },
            {
              name: "Wirkleistung",
              icon: "pin"
            }
          ],
          selected: this.prevSelectedLegend.power
        },
        visualMap: [
          {
            seriesIndex: 0,
            show: false,
            pieces: [
              {
                gt: -this.getSelectedConditionMonitorSetting
                  .settingapparentpowermaxtolerancelimit,
                lte: this.getSelectedConditionMonitorSetting
                  .settingapparentpowermaxtolerancelimit,
                color: "#30D5C8"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 1,
            show: false,
            pieces: [
              {
                gt: -this.getSelectedConditionMonitorSetting
                  .settingreactivepowermaxtolerancelimit,
                lte: this.getSelectedConditionMonitorSetting
                  .settingreactivepowermaxtolerancelimit,
                color: "#71C562"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          },
          {
            seriesIndex: 2,
            show: false,
            pieces: [
              {
                gt: -this.getSelectedConditionMonitorSetting
                  .settingeffectivepowermaxtolerancelimit,
                lte: this.getSelectedConditionMonitorSetting
                  .settingeffectivepowermaxtolerancelimit,
                color: "#0000FF"
              }
            ],
            outOfRange: {
              color: "#FF0000"
            }
          }
        ],
        series: [
          {
            name: "Scheinleistung",
            type: "line",
            data: apparentPower,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingapparentpowermaxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingapparentpowermaxtolerancelimit
                },
                {
                  yAxis: -this.getSelectedConditionMonitorSetting
                    .settingapparentpowermaxtolerancelimit
                }
              ]
            }
          },
          {
            name: "Blindleistung",
            type: "line",
            data: reactivePower,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingreactivepowermaxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingreactivepowermaxtolerancelimit
                },
                {
                  yAxis: -this.getSelectedConditionMonitorSetting
                    .settingreactivepowermaxtolerancelimit
                }
              ]
            }
          },
          {
            name: "Wirkleistung",
            type: "line",
            data: effectivePower,
            markLine: {
              silent: true,
              data: [
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingeffectivepowermaxlimit
                },
                {
                  yAxis: this.getSelectedConditionMonitorSetting
                    .settingeffectivepowermaxtolerancelimit
                },
                {
                  yAxis: -this.getSelectedConditionMonitorSetting
                    .settingeffectivepowermaxtolerancelimit
                }
              ]
            }
          }
        ]
      };

      if (
        this.getSelectedConditionMonitorSetting[
          "settingapparentpowermaxtolerancelimit"
        ] == 0 ||
        this.getSelectedConditionMonitorSetting[
          "settingapparentpowermaxlimit"
        ] == 0
      ) {
        delete data.series[0].markLine;
        delete data.visualMap[0];
      }

      if (
        this.getSelectedConditionMonitorSetting[
          "settingreactivepowermaxtolerancelimit"
        ] == 0 ||
        this.getSelectedConditionMonitorSetting[
          "settingreactivepowermaxlimit"
        ] == 0
      ) {
        delete data.series[1].markLine;
        delete data.visualMap[1];
      }

      if (
        this.getSelectedConditionMonitorSetting[
          "settingeffectivepowermaxtolerancelimit"
        ] == 0 ||
        this.getSelectedConditionMonitorSetting[
          "settingeffectivepowermaxlimit"
        ] == 0
      ) {
        delete data.series[2].markLine;
        delete data.visualMap[2];
      }

      return data;
    },
    currentChartFilteredComputed() {
      const temp = JSON.parse(JSON.stringify(this.currentChartComputed));
      if (this.monitoringMode) {
        return temp;
      } else {
        temp["visualMap"] = [];
        temp["series"][0]["markLine"] = {};
        temp["series"][1]["markLine"] = {};
        temp["series"][2]["markLine"] = {};
        temp["series"][3]["markLine"] = {};
        temp["series"][4]["markLine"] = {};
        temp["series"][5]["markLine"] = {};
        temp["series"][6]["markLine"] = {};
        return temp;
      }
    },
    powerChartFilteredComputed() {
      const temp = JSON.parse(JSON.stringify(this.powerChartComputed));
      if (this.monitoringMode) {
        return temp;
      } else {
        temp["visualMap"] = [];
        temp["series"][0]["markLine"] = {};
        temp["series"][1]["markLine"] = {};
        temp["series"][2]["markLine"] = {};
        return temp;
      }
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_CONDITION_MONITOR_DATA_BY_START_END_DATE",
      "UPDATE_POWER_MONITORING_SETTINGS",
      "UPDATE_CURRENT_MONITORING_SETTINGS",
      "UPDATE_MONITORING_MODE",
      "GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER"
    ]),
    actionRedrawChart() {
      new Promise(resolve => {
        this.chartRedraw = true;
        resolve(false);
      }).then(data => {
        this.chartRedraw = data;
      });
    },
    actionApplyPowerEmit(val) {
      this.getSelectedConditionMonitorSetting.settingapparentpowermaxlimit = parseInt(
        val.apparentPowerMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingapparentpowermaxtolerancelimit = parseInt(
        val.apparentPowerMaxTolerance
      );

      this.getSelectedConditionMonitorSetting.settingreactivepowermaxlimit = parseInt(
        val.reactivePowerMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingreactivepowermaxtolerancelimit = parseInt(
        val.reactivePowerMaxTolerance
      );

      this.getSelectedConditionMonitorSetting.settingeffectivepowermaxlimit = parseInt(
        val.effectivePowerMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingeffectivepowermaxtolerancelimit = parseInt(
        val.effectivePowerMaxTolerance
      );

      let data = {
        projectnumber: this.getSelectedConditionMonitorSetting.projectnumber,
        ordinalnumber: this.getSelectedConditionMonitorSetting.ordinalnumber,
        settingapparentpowermaxlimit: parseInt(val.apparentPowerMaxLimit),
        settingapparentpowermaxtolerancelimit: parseInt(
          val.apparentPowerMaxTolerance
        ),
        settingreactivepowermaxlimit: parseInt(val.reactivePowerMaxLimit),
        settingreactivepowermaxtolerancelimit: parseInt(
          val.reactivePowerMaxTolerance
        ),
        settingeffectivepowermaxlimit: parseInt(val.effectivePowerMaxLimit),
        settingeffectivepowermaxtolerancelimit: parseInt(
          val.effectivePowerMaxTolerance
        )
      };
      this.UPDATE_POWER_MONITORING_SETTINGS(data);
    },
    actionApplyCurrentEmit(val) {
      this.getSelectedConditionMonitorSetting.settingl1maxlimit = parseInt(
        val.l1CurrentMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingl1maxtolerancelimit = parseInt(
        val.l1CurrentMaxTolerance
      );

      this.getSelectedConditionMonitorSetting.settingl2maxlimit = parseInt(
        val.l2CurrentMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingl2maxtolerancelimit = parseInt(
        val.l2CurrentMaxTolerance
      );

      this.getSelectedConditionMonitorSetting.settingl3maxlimit = parseInt(
        val.l3CurrentMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settingl3maxtolerancelimit = parseInt(
        val.l3CurrentMaxTolerance
      );

      this.getSelectedConditionMonitorSetting.settinglnmaxlimit = parseInt(
        val.lnCurrentMaxLimit
      );
      this.getSelectedConditionMonitorSetting.settinglnmaxtolerancelimit = parseInt(
        val.lnCurrentMaxTolerance
      );

      let data = {
        projectnumber: this.getSelectedConditionMonitorSetting.projectnumber,
        ordinalnumber: this.getSelectedConditionMonitorSetting.ordinalnumber,
        settingl1maxlimit: parseInt(val.l1CurrentMaxLimit),
        settingl1maxtolerancelimit: parseInt(val.l1CurrentMaxTolerance),
        settingl2maxlimit: parseInt(val.l2CurrentMaxLimit),
        settingl2maxtolerancelimit: parseInt(val.l2CurrentMaxTolerance),
        settingl3maxlimit: parseInt(val.l3CurrentMaxLimit),
        settingl3maxtolerancelimit: parseInt(val.l3CurrentMaxTolerance),
        settinglnmaxlimit: parseInt(val.lnCurrentMaxLimit),
        settinglnmaxtolerancelimit: parseInt(val.lnCurrentMaxTolerance)
      };
      this.UPDATE_CURRENT_MONITORING_SETTINGS(data);
    },
    toggleLiveData() {
      if (!this.liveDataStatus) {
        this.chartStartDate = this.prevStartEndDate.startDate;
        this.chartEndDate = this.prevStartEndDate.endDate;
      } else {
        this.prevStartEndDate.startDate = this.chartStartDate;
        this.prevStartEndDate.endDate = this.chartEndDate;

        let now = new Date();
        const nowMs = now.getTime();
        const twoHoursMs = 60 * 60 * 1000 * 2;
        let lastTwoHours = new Date(nowMs - twoHoursMs);
        this.chartEndDate = new Date();

        this.chartStartDate = lastTwoHours;
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    actionPowerZrClick() {
      this.prevSelectedLegend.power = this.$refs.echartPowerRef.getOption().legend[0].selected;
    },
    actionCurrentZrClick() {
      this.prevSelectedLegend.current = this.$refs.echartCurrentRef.getOption().legend[0].selected;
    },
    getDataCardClass() {
      if (this.bigBar && this.screenWidthComputed === "xl") {
        return "data-card-xl-small";
      }
      if (!this.bigBar && this.screenWidthComputed === "xl") {
        return "data-card-xl";
      }

      if (this.bigBar && this.screenWidthComputed === "xlg") {
        return "data-card-xlg-small";
      }

      if (!this.bigBar && this.screenWidthComputed === "xlg") {
        return "data-card-xlg";
      }
    },
    getChartCardClass() {
      if (this.bigBar && this.screenWidthComputed === "xl") {
        return "chart-card-xl-small";
      }
      if (!this.bigBar && this.screenWidthComputed === "xl") {
        return "chart-card-xl";
      }

      if (this.bigBar && this.screenWidthComputed === "xlg") {
        return "chart-card-xlg-small";
      }

      if (!this.bigBar && this.screenWidthComputed === "xlg") {
        return "chart-card-xlg";
      }
    },
    getChartClass() {
      if (this.bigBar && this.screenWidthComputed === "xl") {
        return "chart-xl-small";
      }
      if (!this.bigBar && this.screenWidthComputed === "xl") {
        return "chart-xl";
      }

      if (this.bigBar && this.screenWidthComputed === "xlg") {
        return "chart-xlg-small";
      }

      if (!this.bigBar && this.screenWidthComputed === "xlg") {
        return "chart-xlg";
      }
    }
  },
  mounted() {
    new Promise(resolve => {
      this.chartRedraw = true;
      resolve(false);
    }).then(data => {
      this.chartRedraw = data;
    });

    let today00 = new Date();
    today00.setHours(0);
    today00.setMinutes(0);
    today00.setSeconds(0);
    this.chartStartDate = today00;

    let parameter = {
      projectnumber: this.selectedDevice.projectnumber,
      ordinalnumber: this.selectedDevice.ordinalnumber
    };

    this.GET_CONDITION_MONITOR_SETTINGS_BY_PROJECT_ORDINAL_NUMBER(
      parameter
    ).then(() => {
      this.monitoringMode = this.getSelectedConditionMonitorSetting.settingmonitoringmode;
      let parameter2 = {
        startDate: this.dateTimeFormatorUTC(this.chartStartDate),
        endDate: this.dateTimeFormatorUTC(new Date()),
        projectnumber: this.selectedDevice.projectnumber,
        ordinalnumber: this.selectedDevice.ordinalnumber
      };
      this.GET_CONDITION_MONITOR_DATA_BY_START_END_DATE(
        parameter2
      ).then(() => {});
    });
  }
};
</script>

<style scoped>
::v-deep .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #bcc51e;
}

::v-deep .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #bcc51e;
  background: #bcc51e;
}

.data-card {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.chart-card {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.data-card-xl {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 210px;
  width: 1660px;
  display: flex;
  flex-direction: column;
}
.data-card-xlg {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 210px;
  width: 1060px;
  display: flex;
  flex-direction: column;
}

.data-card-xl-small {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 210px;
  width: 1500px;
  display: flex;
  flex-direction: column;
}

.data-card-xlg-small {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 210px;
  width: 900px;
  display: flex;
  flex-direction: column;
}

.chart-card-xl {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 500px;
  width: 1660px;
  display: flex;
  flex-direction: column;
}

.chart-card-xlg {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 500px;
  width: 1060px;
  display: flex;
  flex-direction: column;
}

.chart-card-xl-small {
  background: #ffffff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 20px;
  height: 500px;
  width: 1500px;
  display: flex;
  flex-direction: column;
}

.chart-xl {
  margin: 5px;
  height: 500px;
  width: 1650px;
}
.chart-xlg {
  margin: 5px;
  height: 500px;
  width: 1550px;
}

.chart-xl-small {
  height: 500px;
  width: 1500px;
}
</style>
